/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicup = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 31 37" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.79 7.86v26.8a2.3 2.3 0 01-4.61 0V7.86l-9.25 9.25a2.3 2.3 0 11-3.26-3.25L13.77.76a2.3 2.3 0 013.44.01l13.09 13.1a2.3 2.3 0 11-3.26 3.24l-9.25-9.25z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicup
