/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicfrequentRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...props}>
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M17.424 17.581c-2.105.697-6.387.442-10.061-1.73-.801-.474-1.7-1.106-2.537-.7-.557.27-1.114.928-1.303 2.43-.093.746.102 1.271.438 1.623 1.361 1.423 4.095-.222 4.198-2.188.096-1.862-.484-4.35-.446-5.894"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M21.855 8.262c-.106-.14-7.178-4.97-9.427-4.173-2.25.796-8.903 3.142-8.857 4.173.046 1.03 8.157 4.847 9.796 4.59 1.24-.212 5.403-3.232 5.908-1.991.526 1.264.484 5.971-1.85 6.72"
      />
    </svg>
  )
}
export default SvgSymbolicfrequentRebrand
