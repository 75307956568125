/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicback = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 22" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.318 12.209h18.123a1.56 1.56 0 000-3.118H5.32l6.253-6.253A1.556 1.556 0 109.37.636L.507 9.5A1.554 1.554 0 000 10.65c0 .467.204.885.527 1.17l8.85 8.85a1.556 1.556 0 002.201 0 1.556 1.556 0 00-.005-2.203L5.318 12.21z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicback
