/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicuserStar = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" {...props}>
      <g clipPath="url(#symbolic_user-star_svg__symbolic_user-star_svg__clip0_2328_14299)">
        <path
          fill="currentcolor"
          d="M13.753 17.578c.685-.086 1.247.482 1.247 1.172s-.563 1.24-1.244 1.354a7.5 7.5 0 00-6.152 6.152c-.115.68-.664 1.244-1.354 1.244-.69 0-1.258-.562-1.172-1.247a10 10 0 018.675-8.675zM15 16.25a7.498 7.498 0 01-7.5-7.5c0-4.144 3.356-7.5 7.5-7.5s7.5 3.356 7.5 7.5-3.356 7.5-7.5 7.5zm0-2.5a5 5 0 005-5c0-2.763-2.238-5-5-5s-5 2.237-5 5a5 5 0 005 5zm7.5 13.125l-3.674 1.931.701-4.09-2.97-2.897 4.107-.598L22.5 17.5l1.837 3.721 4.107.598-2.971 2.897.7 4.09-3.673-1.931z"
        />
      </g>
      <defs>
        <clipPath id="symbolic_user-star_svg__symbolic_user-star_svg__clip0_2328_14299">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicuserStar
