/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccomputer = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 37" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M15.582 27.28H3c0 2.85 1.325 4.35 4.038 4.35h21.267c4.507 0 4.695-4.55 4.695-4.55h-3.052V9.23c0-1.7-1.314-3.1-2.91-3.1h-20c-1.597 0-2.911 1.4-2.911 3.1v5.4c0 4.4 4.507 7.1 8.028 4.85.047-.05.094-.05.188-.1 5.587-3.75-.376-5.75-1.972-4s-.376 6.9 6.76 7.65"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M4.125 9.23v5.4c0 4.4 4.507 7.1 8.028 4.85.047-.05.094-.05.188-.1 5.587-3.75-.376-5.75-1.972-4s-.375 6.9 6.76 7.65"
      />
      <path stroke="currentcolor" strokeLinecap="round" strokeWidth={1.8} d="M15.75 9.88h3.75" />
    </svg>
  )
}
export default SvgSymboliccomputer
