/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicarticles = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 37 36" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M29.139 8.493h1.657c.849 0 1.538.686 1.538 1.532v17.316c0 1.66-1.35 3.007-3.018 3.007H6.476a3.137 3.137 0 01-3.142-3.131V5.935c0-.515.42-.933.936-.933h23.557a1.07 1.07 0 011.072 1.068v18.7c0 .462.22.896.595 1.17l.493.35M7.375 9.352h17.464"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M13.673 14.102H7.918a.701.701 0 00-.703.7v5.915c0 .386.314.7.703.7h5.755a.701.701 0 00.703-.7v-5.915c0-.387-.315-.7-.703-.7zm4.098 3.214h7.114m-7.114 3.739h7.114m-7.114 5.075h7.114m-17.721 0h7.113"
      />
    </svg>
  )
}
export default SvgSymbolicarticles
