/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicfullscreenOff = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 31 31" {...props}>
      <g
        stroke="currentColor"
        strokeWidth={1.81}
        clipPath="url(#symbolic_fullscreen-off_svg__symbolic_fullscreen-off_svg__clip0)"
      >
        <path
          strokeLinecap="round"
          strokeMiterlimit={10}
          d="M20.79 3.397h6.97c.374 0 .747.373.747.747v6.347m-.249 10.206v6.846c0 .373-.373.747-.747.747h-6.347m-10.953.124H3.366c-.373 0-.747-.373-.747-.747V21.32m.248-10.206v-6.97c0-.374.374-.747.747-.747h6.348"
        />
        <rect width={14.45} height={16.26} x={24.08} y={8.63} rx={0.9} transform="rotate(90 24.083 8.633)" />
      </g>
      <path stroke="currentColor" strokeLinecap="round" strokeWidth={1.81} d="M1 1l28.456 28.456" />
      <defs>
        <clipPath id="symbolic_fullscreen-off_svg__symbolic_fullscreen-off_svg__clip0">
          <path fill="#fff" d="M0 0h28.004v29H0z" transform="rotate(90 14.049 15.952)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicfullscreenOff
