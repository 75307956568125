/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccamera = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M14.076 18.02H3.623A1.623 1.623 0 012 16.395v-8.77C2 6.726 2.726 6 3.623 6h10.453c.897 0 1.623.726 1.623 1.625v8.757c0 .911-.726 1.638-1.623 1.638zm1.874-8.005l5.265-2.575a.31.31 0 01.449.277v8.374c0 .238-.25.383-.449.278l-5.173-2.629"
      />
    </svg>
  )
}
export default SvgSymboliccamera
