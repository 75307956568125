/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsale = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        fill="#E47B53"
        d="M22.444 23.7c.743 0 1.346-.604 1.346-1.35 0-.746-.603-1.35-1.346-1.35-.744 0-1.346.604-1.346 1.35 0 .746.602 1.35 1.346 1.35zm-7.5-6c.743 0 1.346-.604 1.346-1.35 0-.746-.603-1.35-1.346-1.35-.744 0-1.346.604-1.346 1.35 0 .746.602 1.35 1.346 1.35z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M12.358 29.035c-2.18.51-4.142-1.458-3.633-3.644.29-1.239-.218-2.55-1.308-3.206-1.89-1.166-1.89-3.936 0-5.102 1.09-.656 1.598-1.967 1.308-3.206-.509-2.187 1.453-4.154 3.633-3.644 1.235.291 2.543-.219 3.197-1.312 1.163-1.895 3.924-1.895 5.086 0 .654 1.093 1.962 1.603 3.198 1.312 2.18-.51 4.141 1.457 3.633 3.644-.291 1.239.217 2.55 1.307 3.206 1.89 1.166 1.89 3.936 0 5.102"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M14.246 22.476c2.253-2.988 7.048-4.3 6.54-6.34"
      />
    </svg>
  )
}
export default SvgSymbolicsale
