/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicshakeHands = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M3.161 4.47a6.5 6.5 0 018.84-.329 6.5 6.5 0 019.178 9.154l-7.765 7.791a2 2 0 01-2.719.102l-.11-.102-7.764-7.79a6.5 6.5 0 01.34-8.827zm1.414 1.413a4.5 4.5 0 00-.146 6.211l.146.153L12 19.672l5.303-5.304-3.535-3.535-1.06 1.06a3 3 0 01-4.244-4.242l2.102-2.102a4.501 4.501 0 00-5.837.188l-.154.146zm8.486 2.829a1 1 0 011.414 0l4.242 4.242.708-.707a4.5 4.5 0 00-6.211-6.51l-.153.146-3.182 3.182a1 1 0 00-.078 1.327l.078.088a1 1 0 001.327.077l.087-.077 1.768-1.768z"
      />
    </svg>
  )
}
export default SvgSymbolicshakeHands
