/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccheckOutline = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" {...props}>
      <path
        stroke="currentColor"
        strokeWidth={0.35}
        d="M23.52 43.825C12.6 43.825 3.695 34.92 3.695 24S12.599 4.175 23.52 4.175c10.92 0 19.825 8.904 19.825 19.825 0 10.92-8.87 19.825-19.825 19.825z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={3.08}
        d="M19.165 26.044l2.422 2.422a.43.43 0 00.64-.048l7.009-9.189"
      />
    </svg>
  )
}
export default SvgSymboliccheckOutline
