/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicplus = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 157 157" {...props}>
      <rect width={9} height={94} x={74} y={32} fill="currentColor" rx={4.5} />
      <rect width={8} height={94} x={125} y={75} fill="currentColor" rx={4} transform="rotate(90 125 75)" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M78.5 149a70.5 70.5 0 100-141 70.5 70.5 0 000 141zm0 8a78.5 78.5 0 100-157 78.5 78.5 0 000 157z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicplus
