/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolichedepyPlus = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
      <path
        fill="currentColor"
        d="M19.036 13.546c-1.959-.1-3.918.446-5.851.545-.744.05-1.885-.074-2.455-.595-.645-.57-.744-1.314-.645-2.182.174-1.611.496-3.273.744-4.884.223-1.463.422-3.05-.52-4.215C9.39 1.05 7.58.554 6.341 1.372c-.471.322-.819.769-1.091 1.24-1.76 2.9-1.959 6.396-2.108 9.743-.099 2.356-.223 4.711-.074 7.042.099 1.463.297 2.925 1.066 4.19.174.273.372.52.595.719.868.818 2.107.917 3.198.074 1.24-.967 1.14-1.884 2.033-3.149.471-.694 1.364-.942 2.207-1.066.744-.099 1.587-.074 2.083.471.744.819-.1 3.174 1.016 3.943.422.297 1.116.297 1.612.247 1.165-.099 2.082-.892 2.603-1.884.942-1.81 2.058-5.405 1.661-7.81-.173-.867-.967-1.537-2.107-1.586z"
      />
      <path
        fill="currentColor"
        d="M13.012 9.727c.422.05 1.29-.297 1.513.223.173.397-.1.769-.199 1.14-.123.422-.123.918.348 1.141.347.149.719.075 1.09.025.397-.05.794-.05 1.19-.074.546-.025.943-.223 1.141-.769.174-.446.124-.942.273-1.388.248-.695.967-.447 1.537-.447.273 0 .57-.024.793-.173.224-.149.348-.397.422-.645.198-.595.397-1.264.372-1.909 0-.496-.323-.868-.818-.967a1.716 1.716 0 00-.62-.025c-.248.025-.52.124-.769.05-.297-.074-.347-.422-.273-.694.075-.298.224-.57.248-.893.05-.57-.248-1.041-.843-1.016-.62.024-1.24.099-1.834.124-.496.024-.967.148-1.091.694-.124.52-.05 1.14-.397 1.587-.347.47-1.041.248-1.537.297-.546.05-.917.347-1.116.868-.198.57-.372 1.264-.372 1.884.025.52.422.893.942.967z"
      />
    </svg>
  )
}
export default SvgSymbolichedepyPlus
