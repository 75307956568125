/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliceyeDisabled = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 13" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M11.184 3c.461.787.816 1.803.816 2.795 0 1.27-.386 2.413-1 3.205M4.85 3C4.37 3.791 4 4.82 4 5.822 4 7.077 4.385 8.206 5 9"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M1 6.001C1.617 6.783 4.375 10 7.996 10 11.742 10 14.41 6.78 15 6m0 0c-.604-.766-3.37-4-7.004-4C4.264 2 1.603 5.193 1 5.985"
      />
      <path stroke="currentColor" strokeLinecap="round" strokeMiterlimit={10} d="M8 7a1 1 0 100-2 1 1 0 000 2z" />
      <path stroke="currentColor" strokeLinejoin="round" d="M14 .707L2.354 12.354" />
    </svg>
  )
}
export default SvgSymboliceyeDisabled
