/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicchairRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M11.828 22.083s.297-1.27 3-1.165c2.702.106 5.647.106 5.647.106m-8.51 4.509l11.646.08"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M8.207 23.839c-2.756.635-5.35-3.944-2.405-6.008 1.892-1.323 6.053-1.032 5.972 3.229l.054 8.998c0 .424-.27.82-.702.98-.73.264-1.973.529-3.324.079a1.079 1.079 0 01-.702-.873l-.676-6.035m21.186-.37c2.782.635 5.35-3.917 2.404-6.008-1.892-1.323-6.053-1.032-5.972 3.229l-.054 9.051c0 .397.244.768.622.953.702.318 1.972.609 3.512-.026a1.11 1.11 0 00.649-.874l.19-3.123"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M7.614 17.09S5.452 5.392 16.99 4.624c10.545-.701 11.82 2.482 11.187 5.697-.633 3.215-4.993 4.344-5.676.929-.838-4.191 7.147-3.353 10.5 0"
      />
    </svg>
  )
}
export default SvgSymbolicchairRebrand
