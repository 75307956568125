/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliclogoNew = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 171 62" {...props}>
      <path
        fill="currentColor"
        d="M41.007 42.86c-8.584 0-12.802-5.496-12.802-12.584 0-7.038 4.758-12.968 12.704-12.968 7.8 0 11.724 5.592 11.724 12.583v1.302h-20.21c.344 4.917 3.238 8.099 8.584 8.099 3.777 0 5.985-1.687 7.456-4.194l3.63 2.025c-2.011 3.085-5.396 5.737-11.086 5.737zm-8.535-14.367h15.844c-.245-4.484-2.649-7.618-7.456-7.618-4.856 0-7.848 2.99-8.388 7.618zm36.053-11.041c4.316 0 7.308 2.025 8.927 5.69V7.471h4.317v34.954H77.5v-5.64c-1.766 3.856-5.297 5.977-9.369 5.977-5.935 0-11.085-4.146-11.085-12.535 0-8.388 5.543-12.776 11.478-12.776zm9.27 12.632c0-5.159-2.6-9.112-8.29-9.112-4.904 0-8.093 3.567-8.093 9.208 0 5.882 3.189 9.064 8.094 9.064 5.543-.048 8.29-4.098 8.29-9.16zM100.31 42.86c-8.584 0-12.802-5.496-12.802-12.584 0-7.038 4.758-12.968 12.704-12.968 7.799 0 11.723 5.592 11.723 12.583v1.302H91.726c.344 4.917 3.238 8.099 8.584 8.099 3.777 0 5.984-1.687 7.456-4.194l3.581 2.025c-1.962 3.085-5.347 5.737-11.037 5.737zm-8.535-14.367h15.844c-.246-4.484-2.649-7.618-7.456-7.618-4.905 0-7.848 2.99-8.388 7.618zm39.093 14.27c-4.414 0-7.603-2.218-9.27-5.834v14.994h-4.317V17.838h4.317v5.64c1.815-3.953 5.444-6.026 9.565-6.026 5.788 0 10.84 4.002 10.84 12.535.049 8.196-5.003 12.776-11.135 12.776zm6.818-12.728c0-5.881-3.237-9.112-8.093-9.112-5.445 0-8.29 4.147-8.29 9.16 0 5.16 2.747 9.113 8.437 9.113 5.003 0 7.946-3.52 7.946-9.16zM13.685 17.355c-4.365 0-7.7 2.314-9.369 6.846v-3.664c.05-3.375.197-6.798 1.03-9.787.834-3.085 2.65-5.351 5.15-7.424L7.653-.001C1.472 4.772.147 11.28 0 18.367v24.058h4.316V30.613c0-6.267 3.238-9.69 7.652-9.69 4.955 0 6.573 3.23 6.573 7.762v13.74h4.268V27.721c.049-6.123-2.943-10.366-9.124-10.366zm143.474 26.371c4.366 0 7.701-2.314 9.369-6.846v3.664c-.049 3.375-.196 6.798-1.03 9.787-.834 3.086-2.649 5.352-5.15 7.425l2.845 3.326c6.18-4.773 7.504-11.281 7.651-18.368V18.656h-4.316v11.812c0 6.268-3.237 9.69-7.652 9.69-4.954 0-6.573-3.23-6.573-7.761v-13.74h-4.267V33.36c-.049 6.074 2.943 10.365 9.123 10.365z"
      />
    </svg>
  )
}
export default SvgSymboliclogoNew
