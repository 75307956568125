/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccall = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 33 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M31.33 7.982a2.709 2.709 0 01-.04 3.806l-.001.002-2.818 2.797-.002.003a2.71 2.71 0 01-3.827-.018c-.28-.28-.781-.69-1.368-1.17-.305-.25-.633-.519-.965-.798a18.872 18.872 0 01-1.262-1.137 4.65 4.65 0 01-.475-.552c-.088-.124-.278-.405-.316-.765l-.297-1.682a12.642 12.642 0 00-6.832-.016l-.001.008c-.03.146-.072.345-.115.56-.089.451-.174.918-.198 1.15l-1-.106 1 .098c-.038.388-.264.688-.362.815a5.062 5.062 0 01-.478.519c-.349.337-.802.72-1.258 1.091-.278.227-.553.448-.814.658-.644.516-1.2.962-1.497 1.26l-.005.005a2.718 2.718 0 01-3.825-.006l-.003-.002-2.792-2.813a2.71 2.71 0 01-.008-3.802c8.184-9.283 21.424-9.123 29.56.095zm-1.49 1.346C22.482.973 10.63.85 3.258 9.238l-.024.026-.025.026a.701.701 0 000 .989l2.785 2.805v.001a.71.71 0 00.993-.004c.382-.38 1.083-.944 1.766-1.491.238-.191.473-.38.692-.559.451-.368.849-.705 1.133-.98.121-.117.206-.207.26-.272.046-.336.132-.786.204-1.149a55.903 55.903 0 01.156-.76l.01-.05.003-.008a1.583 1.583 0 011.083-1.21 14.65 14.65 0 018.507.02 1.552 1.552 0 011.094 1.262l.336 1.897c.047.062.127.155.25.279.275.28.67.628 1.12 1.007.258.217.541.448.824.68.644.528 1.288 1.056 1.639 1.407l.005.006a.7.7 0 00.99 0v-.001l2.817-2.797.003-.003a.7.7 0 000-.99l-.02-.02-.019-.021zm-7.654.385l.013.023a.098.098 0 01-.013-.023zm-11.301.006a.11.11 0 01.012-.02l-.012.02z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymboliccall
