/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicpressure = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 160" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M114.991 52.05c9.108-.638 16.997.753 16.997.753s-3.655 10.082-10.5 18.716"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M97.39 40.419c8.412-5.91 17.113-9.097 17.113-9.097s3.945 22.25-4.118 36.33a5.739 5.739 0 01-.638.986m-56.992 2.91c-6.845-8.634-10.5-18.716-10.5-18.716s7.832-1.39 16.997-.754"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M59.966 56.581c-2.494-12.342-.174-25.206-.174-25.206s8.701 3.13 17.113 9.097M61.617 62.671a32.972 32.972 0 002.32 5.041c.175.348.407.695.58.985M94.111 34.19c3.77 6.432 7.6 15.008 7.6 23.352 0 6.663-2.785 11.53-5.86 14.892"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M78.421 72.441c-3.132-3.36-5.859-8.228-5.859-14.891C72.563 41.325 87.181 24 87.181 24s1.566 1.796 3.596 4.867m21.963 59.635c10.905-1.449 19.259-9.793 19.259-9.793s-10.036-10.024-22.45-10.024-22.45 10.024-22.45 10.024 8.412 8.344 19.26 9.793"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M42.263 78.715s10.035 10.024 22.45 10.024c12.413 0 22.449-10.024 22.449-10.024S77.126 68.69 64.712 68.69s-22.45 10.025-22.45 10.025zm44.844 0V64.113"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M45.118 135.116l3.393-3.389a3.183 3.183 0 012.237-.925h25.026a56.014 56.014 0 0025.064-5.893l18.779-9.321c3.046-1.502 4.203-5.316 2.507-8.281-1.504-2.658-4.782-3.698-7.597-2.466l-22.866 10.246c-.116.039-.27.077-.386.077H71.416"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M29 119.245l21.131-15.869s12.224-11.132 25.99.886h13.843a4.918 4.918 0 014.936 4.93c0 2.735-2.66 5.931-5.399 5.931"
      />
    </svg>
  )
}
export default SvgSymbolicpressure
