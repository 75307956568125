/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicheartRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M10.982 17.629S.999 11 7.619 7.848c3.363-1.164 4.815 4.297 4.815 4.297s2.128-5.598 5.415-3.671c3.816 2.237-.828 6.598-2.395 8.08-1.567 1.482-2.523 3.224-1.95 3.705.574.482 2.944 0 3.173.741"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M4.5 5s.764.333 1.261 1.037m14-1.037s-.764.333-1.261 1.037M10.5 3s.282.895.115 1.37m4.626-.003c.05-.02.213-.746.864-1.252"
      />
    </svg>
  )
}
export default SvgSymbolicheartRebrand
