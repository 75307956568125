/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicmail = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" {...props}>
      <g fill="currentColor" clipPath="url(#symbolic_mail_svg__symbolic_mail_svg__a)">
        <path d="M.3 2.6c1.9 1.63 5.28 4.5 6.27 5.38.13.13.27.19.42.19.15 0 .3-.06.42-.18 1-.9 4.37-3.76 6.29-5.39a.3.3 0 00.04-.4 1.16 1.16 0 00-.92-.45H1.17c-.36 0-.7.16-.92.45a.3.3 0 00.04.4z" />
        <path d="M13.83 3.48a.29.29 0 00-.31.05 415.8 415.8 0 00-5.7 4.89c-.49.43-1.15.43-1.63 0C5.27 7.6 2.22 5 .48 3.52a.3.3 0 00-.48.23v7.33c0 .65.52 1.17 1.17 1.17h11.66c.65 0 1.17-.52 1.17-1.17V3.75a.3.3 0 00-.17-.27z" />
      </g>
      <defs>
        <clipPath id="symbolic_mail_svg__symbolic_mail_svg__a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicmail
