/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicglobe = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M8.945 16.89a7.945 7.945 0 100-15.89 7.945 7.945 0 000 15.89z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M1.032 9.297s.998.333 1.696-.202.9-1.54 1.533-1.037c.633.502-.763 2.667-.568 2.896.203.234 1.664.606 1.8 1.304.131.698 2.199 2.733 2.832 1.298.632-1.435-.398-1.598-.633-2.263-.235-.665-.13-1.963 1.2-2.596s1.396-1.33.965-1.63c-.43-.3-1.395-1.136-1.767-.999-.366.13-3.594 1.33-3.366.033.228-1.298 3.196-1.135 3.196-1.135s2.766-.267.463-1.135c-2.302-.868-2.765.065-2.765.065s1.213-1.115-.783-1.683"
      />
    </svg>
  )
}
export default SvgSymbolicglobe
