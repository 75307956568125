/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicquote = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        fill="currentcolor"
        d="M13.2 18.982c0-1.851.944-3.454 2.448-4.582l1.375 1.174c-.688.36-1.59 1.331-1.805 2.144.086-.023.28-.068.494-.068.902 0 1.654.745 1.654 1.85 0 1.152-.902 2.1-1.954 2.1-1.16 0-2.213-.97-2.213-2.618zm5.432 0c0-1.851.945-3.454 2.449-4.582l1.375 1.174c-.688.36-1.569 1.331-1.805 2.144.086-.023.28-.068.494-.068.924 0 1.654.745 1.654 1.85 0 1.152-.902 2.1-1.954 2.1-1.16 0-2.213-.97-2.213-2.618z"
      />
    </svg>
  )
}
export default SvgSymbolicquote
