/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccheckReverse = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" {...props}>
      <circle cx={13} cy={13} r={13} fill="#fff" />
      <g clipPath="url(#symbolic_check-reverse_svg__symbolic_check-reverse_svg__clip0_840_24335)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2}
          d="M8.472 12.993l3.17 3.912 6.34-8.333"
        />
      </g>
      <defs>
        <clipPath id="symbolic_check-reverse_svg__symbolic_check-reverse_svg__clip0_840_24335">
          <path fill="#fff" d="M0 0h11.61v12.54H0z" transform="translate(7.428 6.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymboliccheckReverse
