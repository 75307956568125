import { USER_TYPE } from '../redux/UserRedux'
import { APPOINTMENT_STATUS } from '../redux/ClientRedux'
import type { TranslatorType } from '../types/FunctionTypes'
import type { CancelAppointmentType } from '../types/TherapistStateType'
import React from 'react'
import { Translate } from 'next-translate'
import { LOCALE } from '../constants/locale.constants'

export const capitalize = (string: string | undefined): string => {
  if (string?.length) {
    return `${string[0].toLocaleUpperCase(LOCALE)}${string.slice(1)}`
  }
  return ''
}

export const textToCamelCase = (string: string, delimiter: string = '-') =>
  string
    .split(delimiter)
    .map((s) => s.charAt(0).toLocaleUpperCase(LOCALE) + s.substring(1))
    .join('')

export const parseStringToOptionType = (
  options: string[],
  prefix: string,
  t: TranslatorType,
  icon?: React.ReactNode,
): { value: string; label: string }[] =>
  options.map((specialization: string) => ({
    value: specialization,
    label: t(`${prefix}${specialization}`),
    icon,
  }))

export const parseSpecializationToOptionType = (options): { value: number | string; label: string }[] =>
  Object.keys(options).map((specialization: string) => ({
    value: specialization,
    label: options[specialization],
  }))

export const convertStringToNumberIfInteger = (value: string) => (/^-?\d+$/.test(value) ? parseInt(value, 10) : value)

interface CancelTexts {
  readonly title: string
  readonly text: string
}

export const cancelAppointmentTexts = (
  appointment: CancelAppointmentType,
  userType: number,
  hasSubscription: boolean,
): CancelTexts => {
  const moreThanDay = appointment.status === APPOINTMENT_STATUS.CANCELED_BY_CLIENT_BEFORE_DEADLINE
  const paidBySubscription = appointment.userSubscriptionId
  const paidByVoucher = appointment.voucherId

  if (userType === USER_TYPE.THERAPIST) {
    return {
      title: `calendar.appointmentModal.0.cancelWithRefund.title`,
      text: `calendar.appointmentModal.1.cancel.text`,
    }
  }

  if (!moreThanDay) {
    return {
      title: `calendar.appointmentModal.afterDeadline.title`,
      text: `calendar.appointmentModal.afterDeadline.text`,
    }
  }

  if (hasSubscription && paidBySubscription) {
    return {
      title: `calendar.appointmentModal.activeSubscription.title`,
      text: `calendar.appointmentModal.activeSubscription.text`,
    }
  }

  if (!hasSubscription && paidBySubscription) {
    return {
      title: `calendar.appointmentModal.inactiveSubscription.title`,
      text: `calendar.appointmentModal.inactiveSubscription.text`,
    }
  }

  if (paidByVoucher) {
    return {
      title: `calendar.appointmentModal.voucher.title`,
      text: `calendar.appointmentModal.voucher.text`,
    }
  }

  return {
    title: `calendar.appointmentModal.other.title`,
    text: `calendar.appointmentModal.other.text`,
  }
}

export const sanitizeTextForSearch = (text: string) =>
  (text || '')
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

export const getSimplifiedAppointmentStatus = (status: number, t: Translate) => {
  if (status === 0) return t('general.appointments.statusConfirmed')
  if ([2, 8].includes(status)) return t('general.appointments.statusCompleted')
  if ([3, 4, 5, 6].includes(status)) return t('general.appointments.statusCancelled')
  return ''
}

export default {
  capitalize,
  textToCamelCase,
  parseStringToOptionType,
  parseSpecializationToOptionType,
  convertStringToNumberIfInteger,
  cancelAppointmentTexts,
}
