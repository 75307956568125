/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicwarningRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M15.036 20.211a996.64 996.64 0 01-.388-6.575c-.04-.736.575-1.345 1.352-1.345h2.276c.776 0 1.392.609 1.352 1.345l-.375 6.575c-.04.686-.629 1.219-1.352 1.219h-1.526c-.71-.026-1.299-.546-1.339-1.219z"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M31.316 23.754c-2.21-7.59-10.911-20.12-15.811-17.365-1.727.964-10.818 11.69-10.925 20.194-.067 5.255 13.2 5.623 22.425 3.82 1.084-.215 2.088-.66 2.932-1.345.83-.672 1.472-1.586.308-2.107-3.28-1.46-4.445 4.633 1.07 3.504"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M17.419 27.73c1.242 0 2.25-.955 2.25-2.133 0-1.177-1.008-2.132-2.25-2.132s-2.25.955-2.25 2.133c0 1.177 1.008 2.132 2.25 2.132z"
      />
    </svg>
  )
}
export default SvgSymbolicwarningRebrand
