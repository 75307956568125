/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccheckSquare = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 39 42" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M35.344 22.51v11.85c0 2.554-2.084 4.62-4.662 4.62H7.099c-2.577 0-4.662-2.066-4.662-4.62V11.04c0-2.555 2.085-4.62 4.662-4.62h13.108"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M12.188 20.64l5.832 5.678a2.133 2.133 0 002.971 0L34 6.891"
      />
    </svg>
  )
}
export default SvgSymboliccheckSquare
