/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicplay = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22" {...props}>
      <path
        fill="currentColor"
        d="M6.06 5.67c0-.13.03-.27.1-.38a.72.72 0 011.01-.31l9 5.32c.13.08.23.18.3.31.2.39.07.87-.3 1.09l-9 5.32a.71.71 0 01-.36.1c-.41 0-.75-.35-.75-.8V5.68z"
      />
    </svg>
  )
}
export default SvgSymbolicplay
