/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicear = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 74 74" {...props}>
      <path
        fill="currentColor"
        d="M37.844 24.625a3.894 3.894 0 015.097.093l3.34 3a26.247 26.247 0 015.691 28.61 26.248 26.248 0 01-5.69 8.516l-1.328 1.324A26.251 26.251 0 015.379 31.869c1.24-1.655 3.635-1.641 5.097-.18 1.467 1.467 1.423 3.832.27 5.556a18.757 18.757 0 00-1.742 17.576 18.75 18.75 0 0030.647 6.044l1.328-1.328a18.75 18.75 0 000-26.512l-2.634-2.634a4.5 4.5 0 01-.501-5.766zm25.875 18c-1.642-1.64-1.52-4.22-.268-6.173a18.745 18.745 0 001.545-17.273 18.75 18.75 0 00-30.647-6.044l-1.328 1.328a18.75 18.75 0 000 26.512l2.653 2.653a3.75 3.75 0 01-5.304 5.304l-2.651-2.65a26.25 26.25 0 010-37.126l1.327-1.323a26.251 26.251 0 0139.358 34.584c-1.15 1.491-3.32 1.506-4.685.208z"
      />
    </svg>
  )
}
export default SvgSymbolicear
