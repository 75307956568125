/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccheckAlt = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 22" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M1 12.635L7.972 20.2 23.162 1"
      />
    </svg>
  )
}
export default SvgSymboliccheckAlt
