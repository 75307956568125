/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicalcoholism = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 41 41" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.3}
        d="M24.392 2.026l-.737 3.27m-4.903-3.534l1.79 2.832m9.061-.39l-2.834 1.793m-4.842 7.823c-1.94-1.435-5.156-1.543-7.188-.239-2.032 1.304-5.249 1.194-7.186-.242"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.3}
        d="M13.481 6.463l-2.55-.684-3.319 7.419c-1.831 4.1 1.115 8.45 4.959 9.477 3.841 1.034 8.569-1.26 9.03-5.728l.838-8.083-2.386-.658m-7.482 14.47l-1.83 6.83m0-.001l3.36 4.506c.739.992.044 2.533-1.528 2.14l-6.744-1.806c-1.554-.446-1.387-2.128-.25-2.619l5.162-2.22z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.3}
        d="M21.711 15.885c1.713-.405 3.66-.174 5.049.72 2.032 1.303 5.25 1.195 7.188-.243"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.3}
        d="M22.215 11.043l8.355-2.238 3.316 7.417c1.834 4.103-1.114 8.452-4.956 9.477-3.668.987-8.144-1.06-8.945-5.137m8.945 5.137l1.83 6.83m0 0l-3.36 4.505c-.741.994-.044 2.533 1.526 2.142l6.744-1.808c1.556-.446 1.39-2.128.253-2.616l-5.163-2.223zM16.68 7.32h.005"
      />
    </svg>
  )
}
export default SvgSymbolicalcoholism
