/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccard = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 33" {...props}>
      <path fill="currentColor" d="M29.525 21.005a1.57 1.57 0 100-3.14 1.57 1.57 0 000 3.14z" />
      <path fill="currentColor" d="M31.545 20.395a1.57 1.57 0 100-3.14 1.57 1.57 0 000 3.14z" />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={3.16}
        d="M36.905 22.755l-27.39 8.27a1.8 1.8 0 01-2.23-1.19l-5.21-17.28c-.28-.94.25-1.94 1.2-2.22l27.39-8.26a1.8 1.8 0 012.23 1.19l5.21 17.27c.28.94-.25 1.94-1.2 2.22zm-33.4-5.46l30.82-9.3"
      />
    </svg>
  )
}
export default SvgSymboliccard
