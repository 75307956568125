/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicterapeuticTopics = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 48" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={2}
        d="M39.617 12.016a6.727 6.727 0 013.534 5.915c0 2.16-1 4.061-2.58 5.282m-3.568 3.662c.093.446.14.916.14 1.385 0 3.685-2.953 6.667-6.603 6.667-.303 0-.605-.024-.907-.07m-5.016-23.713C25.454 9.76 26.99 8.82 28.71 8.82c1.512 0 2.86.705 3.744 1.808m-1.36-1.344a4.752 4.752 0 013.627-1.667c2.65 0 4.813 2.183 4.813 4.86 0 .586-.093 1.15-.303 1.666"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={2}
        d="M40.078 22.148c.418.728.674 1.573.674 2.465a4.852 4.852 0 01-3.604 4.695m-6.033 5.755c.023.164.023.328.023.492a5.5 5.5 0 01-4.161 5.33m-2.463-27.193v-2.747a10.453 10.453 0 00-9.207-3.897c-5.58.54-9.719 5.587-9.416 11.244.558 10.681 13.438 19.93 17.437 22.56.512.328 1.186-.048 1.186-.658V18.292m3.606 12.315c-2 0-3.604-1.62-3.604-3.638m9.799-6.862c-2 0-3.604-1.62-3.604-3.638m.728 6.067a3.657 3.657 0 01.604-3.145"
      />
    </svg>
  )
}
export default SvgSymbolicterapeuticTopics
