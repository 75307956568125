/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicclock = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25" {...props}>
      <path
        fill="currentColor"
        d="M12.12 24.24C5.44 24.24 0 18.8 0 12.12 0 5.44 5.44 0 12.12 0c6.68 0 12.12 5.44 12.12 12.12 0 6.68-5.44 12.12-12.12 12.12zm0-22.11c-5.51 0-9.99 4.48-9.99 9.99s4.48 9.99 9.99 9.99 9.99-4.48 9.99-9.99c-.01-5.51-4.49-9.99-9.99-9.99z"
      />
      <path
        fill="currentColor"
        d="M11.1 15.06c-.59 0-1.07-.48-1.07-1.07V7.15a1.071 1.071 0 012.14 0v6.83c0 .6-.48 1.08-1.07 1.08z"
      />
      <path
        fill="currentColor"
        d="M17.94 15.06h-6.83a1.071 1.071 0 010-2.14h6.83c.59 0 1.07.48 1.07 1.07 0 .59-.49 1.07-1.07 1.07z"
      />
    </svg>
  )
}
export default SvgSymbolicclock
