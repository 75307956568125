/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicincognito = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 37 36" {...props}>
      <g
        fill="currentcolor"
        fillRule="evenodd"
        clipPath="url(#symbolic_incognito_svg__symbolic_incognito_svg__clip0_11308_148079)"
        clipRule="evenodd"
      >
        <path d="M13.502 22.873a2.661 2.661 0 100 5.323 2.661 2.661 0 000-5.323zm-4.865 2.662a4.865 4.865 0 018.642-3.066l.564-.23a2.202 2.202 0 011.644 0h.003l.566.232a4.865 4.865 0 11-.966 1.985l-.425-.173-.42.17a4.865 4.865 0 11-9.61 1.08zm15.197-2.662a2.661 2.661 0 100 5.323 2.661 2.661 0 000-5.323zM12.425 12.368l1.44-4.764c.196-.644.642-.9.977-.9h1.327c.188 0 .401.075.597.264l.002.001c1.039.997 2.558 1.173 3.772.38l.002-.002.771-.5.003-.001a.795.795 0 01.445-.136h.873c.337 0 .79.264.982.913l1.402 4.785c-4.143-1.46-8.447-1.474-12.593-.04zm-2.64 1.122l1.972-6.526c.418-1.38 1.591-2.464 3.085-2.464h1.327c.803 0 1.552.329 2.125.88.328.314.72.334 1.042.124l.004-.003.772-.5.001-.002a2.998 2.998 0 011.648-.492h.873c1.506 0 2.686 1.105 3.095 2.494v.001l1.918 6.54a23.18 23.18 0 011.648.931l3.846 2.38a1.102 1.102 0 01-.58 2.04H4.769a1.102 1.102 0 01-.58-2.04l3.846-2.38a23.103 23.103 0 011.75-.983zm-.59 2.856c6.11-3.787 12.83-3.787 18.94 0l.553.343H8.641l.554-.343z" />
      </g>
      <defs>
        <clipPath id="symbolic_incognito_svg__symbolic_incognito_svg__clip0_11308_148079">
          <path fill="#fff" d="M0 0h36v36H0z" transform="translate(.666)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicincognito
