/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicederned = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="#F72717"
        d="M8.07 0C3.399 0 .426 3.315.426 8.072s3.115 8.072 7.787 8.072c4.673 0 7.93-3.315 7.93-8.072S12.885 0 8.07 0z"
      />
      <path
        fill="#fff"
        d="M0 1.441h9.062l-.425 2.595H2.69v2.739h5.38l-.424 2.594H2.69v1.586c0 1.009.425 1.441 1.275 1.441h5.097l-.566 2.595H1.982c-.566-.144-1.132-.432-1.557-.865-.142-.432-.283-.576-.283-.865v-.288c0 .288.141.72.141.865"
      />
    </svg>
  )
}
export default SvgSymbolicederned
