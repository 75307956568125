/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsearchRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 37" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M19.915 26.246a11.018 11.018 0 01-5.142.928c-5.435-.358-9.842-4.726-10.241-10.17-.483-6.477 4.596-11.879 10.934-11.879 6.065 0 12.046 4.03 10.64 13.652-.42 2.932-2.393 4.24-3.84 4.79-2.435.907-3.61-2.448-.61-2.237 2.414.169 7.577 6.119 9.529 8.46.44.528.42 1.309-.063 1.794l-.105.127a1.332 1.332 0 01-1.91.021l-5.709-6.119"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M12.294 23.004S6.882 20.309 8.7 12.693"
      />
    </svg>
  )
}
export default SvgSymbolicsearchRebrand
