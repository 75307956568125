/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicinstant = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 53 30" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M17.271 21.182h8.421c2.234 4.334 6.825 7.28 12.129 7.28 7.488 0 13.552-5.915 13.552-13.22C51.373 7.94 45.309 2 37.821 2H2"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M37.06 7.148v8.166l4.173 4.191M5.953 8.418h19.273m-12.472 6.609h10.483"
      />
    </svg>
  )
}
export default SvgSymbolicinstant
