/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicglobeRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 37" {...props}>
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M30 20.041s-.822.564-1.956 1.087c-1.135.523-1.682-1.811-3.012-2.857-1.37-1.047-1.957-.684-2.817.2-.861.886-1.291.725-1.683-.08-.39-.804-.234.121-1.134 1.45-.86 1.327-3.912 2.172-3.912 2.172-4.343.161-2.817 4.105-1.057 2.938 1.76-1.167.391-9.256.391-9.256s-.547-1.529 1.213-1.931c1.76-.403 4.186.12 4.89-1.047.705-1.167-.39-1.489 1.018-2.857.547-.523 2.112-1.006 3.834-.443"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M30 20.805a12.133 12.133 0 01-1.697 4.156c-3.605 5.583-11.112 7.22-16.753 3.652a11.926 11.926 0 01-3.647-16.58c2.813-4.36 7.954-6.308 12.763-5.288"
      />
    </svg>
  )
}
export default SvgSymbolicglobeRebrand
