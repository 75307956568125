/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccustomerSupport = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.72}
        d="M12.86 20.916H6.428a1.125 1.125 0 01-1.127-1.128V9.7a6.7 6.7 0 1113.399 0v6.228h2.854a.45.45 0 00.446-.446v-4.566a.45.45 0 00-.446-.446h-2.855"
      />
      <path
        stroke="currentcolor"
        strokeMiterlimit={10}
        strokeWidth={1.72}
        d="M5.3 10.437H2.447a.45.45 0 00-.446.446v4.607a.45.45 0 00.446.446h2.855"
      />
    </svg>
  )
}
export default SvgSymboliccustomerSupport
