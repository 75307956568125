/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicstress = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 41 40" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.2}
        d="M25.823 16.317a2.452 2.452 0 00-.284-.112c.035-1.689-.907-3.32-2.526-4.068-2.056-.948-4.464-.137-5.563 1.808a2.154 2.154 0 00-2.84 1.088 2.19 2.19 0 001.068 2.894 2.148 2.148 0 001.631.069 4.344 4.344 0 002.102 2.082 4.272 4.272 0 002.782.281 2.825 2.825 0 005.03-.249 2.871 2.871 0 00-1.4-3.793zM13.692 27.574L10.09 30c-.088.06-.201-.03-.164-.132l3.341-9.072a.112.112 0 00-.095-.15l-9.07-.63c-.105-.008-.14-.148-.05-.206l6.478-4.078m21.972 10.916l4.25.84c.106.02.174-.107.1-.186l-6.542-7.078a.112.112 0 01.03-.175l8.132-4.103c.095-.048.073-.19-.03-.208l-7.537-1.25m-20.066-3.175l-2.217-3.45c-.058-.088.03-.2.128-.164l3.783 1.303M28.26 10.62l2.216-3.448c.058-.09-.03-.2-.128-.165L26.565 8.31"
      />
    </svg>
  )
}
export default SvgSymbolicstress
