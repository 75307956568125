/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicinvoice = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M18.905.054a.76.76 0 01.479.705v15.327A3.906 3.906 0 0115.485 20H4.165C1.864 20 0 18.12 0 15.81v-1.658c0-.42.34-.76.76-.76h2.494V.76A.76.76 0 014.486.165l2.187 1.74L8.656.186A.76.76 0 019.66.195l1.886 1.688 1.97-1.698A.76.76 0 0114.5.18l.389.328a2131.488 2131.488 0 011.614 1.365A170.693 170.693 0 0017.986.33l.087-.092a.76.76 0 01.832-.184zm-1.04 2.597a30.77 30.77 0 01-.715.717c-.033.03-.073.067-.112.099a.787.787 0 01-.55.185.761.761 0 01-.494-.223l.013.013-.087-.074a925.719 925.719 0 00-1.904-1.61l-1.984 1.71a.76.76 0 01-1.002-.01L9.146 1.772 7.192 3.466a.76.76 0 01-.97.021l-1.45-1.153v11.059h8.651c.42 0 .76.34.76.76v1.78c0 1.236.921 2.258 2.102 2.409h.002a2.394 2.394 0 001.578-2.256V2.651zm-4.27 15.83a3.943 3.943 0 01-.931-2.548v-1.021H4.228a.762.762 0 01-.43 0h-2.28v.898a2.66 2.66 0 002.648 2.671h9.428zM17.07 2.357z"
        clipRule="evenodd"
      />
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M6.897 8.436c0-.469.38-.85.85-.85h5.517a.85.85 0 110 1.7H7.747a.85.85 0 01-.85-.85zm0 3.448c0-.47.38-.85.85-.85h9.655a.85.85 0 110 1.7H7.747a.85.85 0 01-.85-.85z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicinvoice
