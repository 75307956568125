/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicworry = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 160" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={5}
        d="M128.768 136.84h-10.777V17.652c0-.613-.499-1.111-1.111-1.111H44.548c-.613 0-1.111.498-1.111 1.111V136.84H32"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M45.16 18.195l43.36 12.642v91.049l-45.083 14.955m36.946-67.298v13.415"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="M111.988 112.98c-1.784 1.527-4.081 1.527-5.868 0-1.783-1.526-4.081-1.526-5.867 0-1.784 1.527-4.082 1.527-5.868 0-1.784-1.526-4.082-1.526-5.868 0M116 124.5c-2.28-2-5.217-2-7.5 0-2.28 2-5.217 2-7.5 0m17.072-25.356c-1.784-1.526-4.082-1.526-5.868 0-1.784 1.527-4.082 1.527-5.868 0-1.784-1.526-4.081-1.526-5.868 0-1.783 1.527-4.081 1.527-5.868 0-1.783-1.526-4.08-1.526-5.867 0"
      />
    </svg>
  )
}
export default SvgSymbolicworry
