import GeneralActions from '../redux/GeneralRedux'

export const withoutFetching = 'noFetching'

const START_FETCHING_ACTION_ENUMS = {
  REGISTER_REQUEST: 'authorization',
  LOGIN_REQUEST: 'authorization',
  LOGIN_REQUEST_FACEBOOK: 'authorizationFacebook',
  CHANGE_PASSWORD_REQUEST: 'authorization',
  RESET_PASSWORD_REQUEST: 'resetPassword',
  VERIFIED_USER_REQUEST: 'verifiedUser',
  UPDATE_USER_DATA_REQUEST: 'updateUserData',
  SEND_THERAPIST_FEEDBACK_REQUEST: 'feedback',
  UPDATE_THERAPIST_FEEDBACK_REQUEST: 'feedback',
  GET_ALL_CLIENTS_REQUEST: 'clients',
  GET_DIAGNOSIS_REQUEST: 'diagnosis',
  GET_THERAPISTS_REQUEST: 'therapists',
  GET_USER_DATA_REQUEST: 'user',
  GET_ALL_APPOINTMENTS_REQUEST: 'allAppointments',
  RESERVE_PREBOOKING_APPOINTMENT_REQUEST: 'reservePrebookingAppointment',
  GET_SCHEDULE_REQUEST: 'therapistSchedule',
  GET_SCHEDULE_AS_CLIENT_REQUEST: 'therapistSchedule',
}

const FetchingMiddleware =
  ({ dispatch }: { dispatch: Function }) =>
  (next: Function) =>
  (action: Record<string, any>) => {
    if (action && action.type) {
      const actionType: string = START_FETCHING_ACTION_ENUMS[action.type] || 'general'
      if (action && action.type && action.type.includes('_REQUEST') && actionType !== withoutFetching) {
        dispatch(GeneralActions.onStartFetching([actionType]))
      }
    }
    next(action)
  }

export default FetchingMiddleware
