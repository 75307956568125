/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicrelationshipsRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.4}
        d="M25.42 28.927s1.072 2.481-.273 6.536c.124-.142 1.358-4.902 7.4-6.93 6.043-2.027 12.744-2.374 11.709-9.673-1.035-7.3-14.09-2.677-11.18.152 3.412 3.316 4.959-6.313 2.11-8.827-2.848-2.513-9.304-1.285-10.42 3.756-1.115 5.041-.967 10.038 1.41 9.518 2.377-.52.41-7.146-4.837-9.355-5.234-2.313-9.064 6.011-7.997 9.286 1.067 3.275 5.856-.994.775-4.028-5.082-3.034-9.48-3.43-9.56 3.98-.081 7.41 7.336 8.407 9.271 9.947 1.935 1.54 3.679 5.226 3.679 5.226s-1.795-5.973 2.683-8.43"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.4}
        d="M21.338 14.104c-5.234-2.313-9.064 6.011-7.997 9.286 1.067 3.275 5.856-.994.775-4.028-5.082-3.035-9.48-3.43-9.56 3.98-.081 7.41 7.336 8.407 9.271 9.947 1.935 1.54 3.679 5.226 3.679 5.226s-1.795-5.973 2.683-8.43"
      />
    </svg>
  )
}
export default SvgSymbolicrelationshipsRebrand
