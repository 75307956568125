/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicscaleScreen = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M2 10.711V3.682C2 2.75 2.74 2 3.664 2H20.25c.912 0 1.664.749 1.664 1.682V20.23c0 .922-.741 1.683-1.664 1.683h-7.113"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M9.158 21.9H3.265A1.277 1.277 0 012 20.622v-5.945c0-.703.57-1.28 1.265-1.28h5.893c.695 0 1.265.577 1.265 1.28v5.945c0 .715-.57 1.28-1.265 1.28zm9.085-16.224l-5.721 5.83m1.994-5.841h3.738v3.664"
      />
    </svg>
  )
}
export default SvgSymbolicscaleScreen
