/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccalm = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 160" {...props}>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        clipPath="url(#symbolic_calm_svg__symbolic_calm_svg__clip0_7715_100719)"
      >
        <path d="M31.79 101.803l97.065-15.929M30.93 59.542l98.139 6.605M89.42 91.53c6.99 0 12.658-5.702 12.658-12.735 0-7.033-5.668-12.734-12.659-12.734-6.991 0-12.659 5.701-12.659 12.734S82.428 91.53 89.42 91.53zM56.206 60.837c9.029 0 16.349-7.364 16.349-16.448 0-9.083-7.32-16.447-16.35-16.447-9.029 0-16.349 7.364-16.349 16.447 0 9.084 7.32 16.448 16.35 16.448zm51.965 2.418c4.977 0 9.012-4.059 9.012-9.066 0-5.006-4.035-9.065-9.012-9.065s-9.011 4.059-9.011 9.065c0 5.007 4.034 9.066 9.011 9.066zM45.65 98.306c4.052 0 7.337-3.304 7.337-7.381s-3.285-7.382-7.337-7.382c-4.053 0-7.338 3.305-7.338 7.382s3.285 7.382 7.338 7.382z" />
        <path strokeLinejoin="round" d="M64.488 101.285l16.563 31.772H47.924l16.564-31.772z" />
      </g>
      <defs>
        <clipPath id="symbolic_calm_svg__symbolic_calm_svg__clip0_7715_100719">
          <path fill="#fff" d="M0 0h102v109H0z" transform="translate(29 26)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymboliccalm
