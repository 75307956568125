/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicedit = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.89}
        d="M3.93 3.938l1.223-1.037a2.64 2.64 0 013.722.303L18.895 15c.035.04.06.09.075.14l2.015 7.109a.375.375 0 01-.52.442l-6.688-3.138a.387.387 0 01-.127-.097L3.631 7.66a2.637 2.637 0 01.3-3.722zm6 .51L4.686 8.906m13.946 5.785l-5.246 4.456"
      />
    </svg>
  )
}
export default SvgSymbolicedit
