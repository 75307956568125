/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsleep = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 160" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M67.971 113.681c-24.785-3.83-35.05-24.855-35.01-39.686.041-17.806 15.174-29.093 19.305-31.741.572-.367 1.308-.367 1.922 0 .736.448 1.022 1.303.777 2.118-1.881 5.542-7.566 27.178 11.656 41.52.532.408.86.979.86 1.63l.817 43.354a2.754 2.754 0 002.74 2.69h5.85c5.07 0 9.529-3.26 11.042-8.068a10.33 10.33 0 00.49-3.178v-15.81"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M61.55 81.37l8.957-3.87c.981-.408 2.086-.53 3.108-.245 1.963.53 4.745 2.078 4.949 6.805.327 7.415 0 13.12 0 13.12m9.856 23.103h1.432c4.786 0 8.712-3.831 8.794-8.598V96.446c0-6.478-5.276-11.694-11.738-11.694h-8.14"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M96.437 89.072s7.444-4.32 9.448-5.827c2.045-1.507 3.967 1.426 2.863 3.871-1.105 2.445-2.618 9.25-10.143 15.85"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={4}
        d="M93.1 26h7.567L93.55 36.553h6.79m1.145 11.898h7.567l-7.117 10.553h6.79m10.265-23.348h7.567l-7.076 10.553h6.789"
      />
    </svg>
  )
}
export default SvgSymbolicsleep
