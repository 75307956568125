/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicclockRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M30.253 13.959c.566 1.433.868 2.98.868 4.601 0 6.94-5.62 12.56-12.56 12.56S6 25.5 6 18.56C6 11.81 11.318 6.302 18.032 6c0 0 0 .15.113 9.467.114 9.355-4.827 8.638-4.714 4.829.075-2.716 12.824-1.736 12.824-1.736"
      />
      <path
        stroke="#E47B53"
        strokeDasharray="3.6 3.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M21.813 6.428a12.539 12.539 0 018.395 7.422"
      />
    </svg>
  )
}
export default SvgSymbolicclockRebrand
