/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsnap = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 61 60" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={3.5}
        d="M43.128 27.055a3.311 3.311 0 000-4.706 3.311 3.311 0 00-4.706 0l-4.706 4.706a3.311 3.311 0 000 4.706c1.308 1.308 3.422 1.308 4.706 0l4.706-4.706zm3.547 5.892a3.311 3.311 0 000-4.706 3.311 3.311 0 00-4.706 0l-3.523 3.523a3.311 3.311 0 000 4.706 3.311 3.311 0 004.706 0l3.523-3.523z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={3.5}
        d="M50.191 38.832c.63-.63.982-1.485.982-2.366a3.35 3.35 0 00-.982-2.365 3.31 3.31 0 00-2.365-.982c-.881 0-1.737.352-2.366.982l-2.365 2.365a3.31 3.31 0 00-.982 2.366c0 .906.353 1.736.982 2.365a3.35 3.35 0 002.365.982c.881 0 1.737-.353 2.366-.982.78-.755 1.61-1.61 2.365-2.365z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={3.5}
        d="M34.008 52.965a4.97 4.97 0 003.523-1.46l12.658-12.657M38.411 22.365l8.254-8.254c.63-.63.982-1.485.982-2.366A3.354 3.354 0 0044.3 8.398c-.88 0-1.737.353-2.366.982L24.268 27.046s-.755-3.221-1.56-6.72a4.869 4.869 0 00-3.423-3.598c-1.71-.478-3.548-.025-4.806 1.233l.025 19.982-5.008 5.008"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={3.5}
        d="M24.287 27.057l1.183 1.182c3.246 3.247 3.246 8.531 0 11.778m-.935-30.35V2.998m-6.659 6.671l-1.661-1.661m14.98 1.661l1.661-1.661"
      />
    </svg>
  )
}
export default SvgSymbolicsnap
