/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicanger = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 48" {...props}>
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M11.77 14.558C12.83 9.113 17.63 5 23.384 5c4.498 0 8.407 2.51 10.407 6.192 4.487.106 8.087 3.784 8.087 8.283a8.283 8.283 0 01-12.124 7.34 11.77 11.77 0 01-6.37 1.864 11.78 11.78 0 01-6.573-1.995 6.674 6.674 0 01-10.312-5.6 6.673 6.673 0 015.27-6.526zM23.384 8.03a8.81 8.81 0 00-8.771 8.027l-.12 1.363-1.368.02a3.645 3.645 0 00.048 7.29 3.64 3.64 0 002.551-1.038l.978-.956 1.05.875a8.769 8.769 0 005.632 2.039c2.03 0 3.896-.69 5.381-1.842l.888-.689.917.65a5.254 5.254 0 008.28-4.294 5.26 5.26 0 00-5.798-5.227l-1.128.14-.45-1.043a8.812 8.812 0 00-8.09-5.316zm-1.4 21.457c.64.398.835 1.239.437 1.878l-1.112 1.786h8.525c1.466 0 2.108 1.857.952 2.766l-.041.032-5.79 3.867a1.363 1.363 0 01-.631 2.572h-2.03a1.812 1.812 0 01-1.81-1.948l.002-.015.181-1.834a1.363 1.363 0 012.385-.762 1.33 1.33 0 01.175-.138l2.715-1.813h-6.608c-1.382 0-2.155-1.648-1.262-2.685l2.034-3.269a1.363 1.363 0 011.878-.437z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicanger
