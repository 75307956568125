/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicchatBubbles = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 60 60" {...props}>
      <path
        stroke="currentcolor"
        strokeMiterlimit={10}
        strokeWidth={3.04}
        d="M50.782 36.637a18.142 18.142 0 001.891-10.913c-1.269-9.265-9.529-16.14-18.868-15.704-10.813.507-18.783 10.208-17.185 20.913 1.391 9.308 9.837 16.09 19.226 15.454a18.152 18.152 0 007.098-1.963.788.788 0 01.533-.071l9.965 1.08a1.28 1.28 0 001.513-1.584l-4.241-6.644a.788.788 0 01.068-.568z"
      />
      <path
        stroke="currentcolor"
        strokeMiterlimit={10}
        strokeWidth={2.74}
        d="M24.23 43.026c-1.867 2.79-5.093 4.588-8.632 4.488a10.016 10.016 0 01-3.952-.926.446.446 0 00-.296-.029l-5.464.812a.708.708 0 01-.869-.84l2.192-3.752a.424.424 0 00-.05-.314 10.003 10.003 0 01-1.276-5.968c.5-5.127 4.898-9.093 10.05-9.053.744.007 1.47.093 2.164.25"
      />
    </svg>
  )
}
export default SvgSymbolicchatBubbles
