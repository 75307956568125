/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccalendarRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M21.038 21c-8.55-3.325-2.544-8.457-.701-5.291C24 22 5.538 21.944 4.5 19 3.463 16.057 3.463 7.7 3.463 7.7m0 0V5.3c0-1.034.838-1.87 1.866-1.862h12.97c1.027 0 1.865.836 1.865 1.862v2.4H3.463zm4.225-4.305V2m8.408 1.272V2"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M10.889 12.429s-.13-3.248 2.507-2.232-4.945 6.55-3.105 6.763c1.841.214 3.709-.507 3.709-.507"
      />
    </svg>
  )
}
export default SvgSymboliccalendarRebrand
