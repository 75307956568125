/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicblog = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 48" {...props}>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        clipPath="url(#symbolic_blog_svg__symbolic_blog_svg__clip0_6514_28526)"
      >
        <path d="M40.305 18.263v13.572c0 2.109-1.7 3.827-3.785 3.827h-9.222l-7.415 5.342-.077-5.342h-9.717c-2.085 0-3.784-1.718-3.784-3.827V14.608c0-2.109 1.699-3.827 3.784-3.827h17.518" />
        <path d="M27.086 17.887l10.36-10.409a1.646 1.646 0 012.32 0l1.954 1.96a1.659 1.659 0 010 2.328l-9.724 9.74-4.83.605-.08-4.224zm-15.117 8.949H25.19m-13.221-5.672h9.444" />
      </g>
      <defs>
        <clipPath id="symbolic_blog_svg__symbolic_blog_svg__clip0_6514_28526">
          <path fill="#fff" d="M0 0h48v48H0z" transform="translate(.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicblog
