/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicbaselineCard = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25" {...props}>
      <path
        fill="currentColor"
        d="M20.2 2.02H4.04c-1.121 0-2.02.899-2.02 2.02v11.11c0 1.121.899 2.02 2.02 2.02h4.04v5.05l4.04-2.02 4.04 2.02v-5.05h4.04c1.121 0 2.02-.899 2.02-2.02V4.04c0-1.121-.899-2.02-2.02-2.02zm0 13.13H4.04v-2.02H20.2v2.02zm0-5.05H4.04V4.04H20.2v6.06z"
      />
    </svg>
  )
}
export default SvgSymbolicbaselineCard
