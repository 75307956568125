/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicplayBgWhite = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <circle cx={18} cy={18} r={18} fill="#fff" />
      <path
        fill="currentColor"
        d="M14 13.684a.71.71 0 01.082-.332.638.638 0 01.888-.266l7.86 4.566a.7.7 0 010 1.195l-7.86 4.567a.63.63 0 01-.317.086c-.36 0-.653-.306-.653-.683v-9.133z"
      />
    </svg>
  )
}
export default SvgSymbolicplayBgWhite
