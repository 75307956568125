/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicpsychotherapy2 = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 37" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M6.077 13.422s-.211-3.63 1.516-3.716c1.727-.086 20.85-.82 22.577.346.548.389 1.517 3.456-.632 3.542-2.148.044-22.956.476-23.335 5.4-.38 4.925-.31 9.032.935 10.714 1.246 1.681 21.263 1.512 22.864 0 1.685-1.599.923-13.04.923-13.04"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M13.097 9.549l.612-1.633c.306-.76 1.138-1.291 2.056-1.291h5.294c.831 0 1.619.418 1.969 1.101l.569 1.149"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeWidth={1.8}
        d="M14.793 22.37l7.49.392m-3.941 3.549l.392-7.49"
      />
    </svg>
  )
}
export default SvgSymbolicpsychotherapy2
