/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicbell = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 33 32" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.2}
        d="M25.294 22.727H7.933a1.167 1.167 0 01-1.182-1.183v-8.68C6.75 7.432 11.183 3 16.613 3c5.468 0 9.864 4.433 9.864 9.863v8.681c0 .665-.518 1.183-1.183 1.183z"
      />
      <path
        fill="currentColor"
        d="M16.502 29.007a3.137 3.137 0 01-3.14-3.14v-.185h6.28v.185a3.137 3.137 0 01-3.14 3.14z"
      />
    </svg>
  )
}
export default SvgSymbolicbell
