/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicbookPayLater = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 61 60" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={3.5}
        d="M22.646 50.556h-4.787c-4.863 0-8.8-3.927-8.8-8.778V16.582"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={3.5}
        d="M16.202 9.111c-4.033 0-7.314 3.33-7.14 7.353.173 3.735 3.261 6.699 7.044 6.699H43.55a7.025 7.025 0 017.044 7.026"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={3.5}
        d="M16.105 9.111H43.55c3.899 0 7.045 3.138 7.045 7.026v1.848m-34.49-1.848H43.57m-6.169 37.156c-4.503-1.078-7.848-5.107-7.848-9.932 0-5.646 4.58-10.394 10.242-10.394 2.856 0 5.43 1.231 7.282 3.156"
      />
      <path
        fill="currentColor"
        d="M49.156 47.392c0 .76.63 1.422 1.426 1.422.796 0 1.426-.628 1.426-1.422 0-.76-.63-1.422-1.426-1.422-.796 0-1.426.661-1.426 1.422zm-1.886 3.184c0 .76.63 1.422 1.426 1.422.796 0 1.426-.628 1.426-1.422 0-.761-.63-1.422-1.426-1.422-.796 0-1.426.661-1.426 1.422zm-2.88 2.232c0 .761.63 1.422 1.427 1.422.796 0 1.426-.628 1.426-1.422 0-.76-.63-1.422-1.426-1.422-.796 0-1.426.661-1.426 1.422zm-3.652 1.055c0 .76.63 1.422 1.426 1.422.796 0 1.426-.628 1.426-1.422 0-.76-.63-1.422-1.426-1.422-.796 0-1.426.661-1.426 1.422z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={3.5}
        d="M39.595 42.93l-2.907-2.85"
      />
      <path
        fill="currentColor"
        d="M42.83 44.346a1.904 1.904 0 01-1.896 1.896 1.904 1.904 0 01-1.897-1.896c0-1.041.855-1.896 1.897-1.896 1.04 0 1.896.855 1.896 1.896z"
      />
    </svg>
  )
}
export default SvgSymbolicbookPayLater
