/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicphone = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
      <path
        fill="currentColor"
        d="M4.97 7.03a4.84 4.84 0 01-1.46-2.75.41.41 0 01.12-.34L4.55 3a.41.41 0 00.06-.51L3.13.2A.41.41 0 002.6.07L.23 1.17a.41.41 0 00-.23.41c.13 1.18.64 4.08 3.5 6.93A11.45 11.45 0 0010.42 12c.17.02.33-.08.4-.23l1.12-2.37a.41.41 0 00-.15-.53L9.5 7.39a.41.41 0 00-.51.06l-.93.93a.41.41 0 01-.34.11 4.84 4.84 0 01-2.75-1.46z"
      />
      <path
        fill="currentColor"
        d="M9.52 6.41A.41.41 0 019.1 6 3.1 3.1 0 006 2.9a.41.41 0 110-.83A3.94 3.94 0 019.93 6c0 .23-.18.41-.41.41z"
      />
      <path
        fill="currentColor"
        d="M11.59 6.41a.41.41 0 01-.42-.41A5.18 5.18 0 006 .83.41.41 0 116 0a6 6 0 016 6 .41.41 0 01-.41.41z"
      />
    </svg>
  )
}
export default SvgSymbolicphone
