/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicmicrophoneOff = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeWidth={2} d="M1 1l22.5 22.5" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.127 4.73A2.666 2.666 0 009.46 7.396v3.696a2.666 2.666 0 105.334 0V7.396c0-1.47-1.19-2.666-2.667-2.666zM7.73 7.396a4.396 4.396 0 118.793 0v3.696a4.396 4.396 0 11-8.793 0V7.396z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.73 12.771a.865.865 0 10-1.73 0c0 3.525 2.98 6.334 6.34 6.72v3.22a.788.788 0 101.575 0v-3.22c3.359-.386 6.339-3.195 6.339-6.72a.865.865 0 10-1.73 0c0 2.714-2.551 5.036-5.397 5.036S6.73 15.485 6.73 12.77z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicmicrophoneOff
