/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicmessages = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 25" {...props}>
      <g fill="currentColor" clipPath="url(#symbolic_messages_svg__symbolic_messages_svg__clip0)">
        <path d="M18.64 23.87c-5.83 0-10.93-4.32-11.8-10.16-.5-3.34.44-6.75 2.58-9.36C11.56 1.75 14.72.16 18.1 0c6.1-.27 11.54 4.23 12.37 10.29.32 2.35-.05 4.7-1.07 6.82l2.41 3.87c.17.62 0 1.27-.44 1.74-.43.45-1.04.65-1.65.54l-5.78-.63c-1.4.69-2.91 1.1-4.49 1.2-.28.03-.55.04-.81.04zm.01-21.74c-.15 0-.3 0-.45.01a9.843 9.843 0 00-7.13 3.57 9.832 9.832 0 00-2.12 7.69c.75 5 5.28 8.65 10.35 8.32 1.35-.09 2.63-.45 3.82-1.06.3-.15.64-.2.96-.15l5.31.58-2.18-3.5c-.1-.37-.06-.77.12-1.12a9.852 9.852 0 001.02-5.87c-.66-4.83-4.88-8.47-9.7-8.47z" />
        <path d="M7.45 24.41h-.19c-.88-.03-1.74-.21-2.54-.55l-3.05.45c-.45.1-.91-.04-1.25-.36-.35-.34-.5-.82-.39-1.3l.11-.27 1.07-1.84a6.845 6.845 0 01-.68-3.76c.34-3.52 3.37-6.27 6.91-6.27h.05c.5 0 1.01.06 1.5.17l-.42 1.87c-.35-.08-.72-.12-1.09-.13h-.04c-2.56 0-4.75 1.99-5 4.54-.1 1.05.12 2.08.64 2.99.15.26.2.57.14.86l-.11.29-.67 1.14 2.14-.32c.25-.05.52-.02.75.09.62.29 1.29.44 1.98.46 1.7.05 3.36-.81 4.33-2.25l1.6 1.07c-1.3 1.95-3.5 3.12-5.79 3.12z" />
      </g>
      <defs>
        <clipPath id="symbolic_messages_svg__symbolic_messages_svg__clip0">
          <path fill="#fff" d="M0 0h31.87v24.41H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicmessages
