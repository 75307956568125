/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsearch = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 34 34" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M13.111 24.222c6.137 0 11.111-4.974 11.111-11.11C24.222 6.974 19.248 2 13.112 2 6.974 2 2 6.975 2 13.111c0 6.137 4.975 11.111 11.111 11.111zM22 22l10 10"
      />
    </svg>
  )
}
export default SvgSymbolicsearch
