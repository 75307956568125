/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccursorCircle = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 41 42" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M17.528 33.208c-8.344 0-15.116-6.804-15.116-15.187 0-8.384 6.772-15.218 15.116-15.218s15.116 6.804 15.116 15.188"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M38.295 25.494l-20.83-8.384c-.241-.09-.544.03-.634.274a.389.389 0 000 .364l8.343 20.928a.456.456 0 00.424.304h.03a.498.498 0 00.453-.273l2.933-5.893 4.595 4.617a.51.51 0 00.695 0l2.782-2.794a.517.517 0 000-.7l-4.595-4.616 5.865-2.946a.5.5 0 00.211-.669.483.483 0 00-.272-.212z"
      />
    </svg>
  )
}
export default SvgSymboliccursorCircle
