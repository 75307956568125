/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicbanned = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 33 33" {...props}>
      <path
        fill="currentColor"
        d="M16.5 0C7.4 0 0 7.4 0 16.5S7.4 33 16.5 33 33 25.6 33 16.5 25.6 0 16.5 0zm0 3.4c4.04 0 7.66 1.85 10.06 4.73L4.73 22.2A13.1 13.1 0 0116.5 3.4zm0 26.2c-3.86 0-7.32-1.7-9.72-4.36l21.7-13.99A13.1 13.1 0 0116.5 29.59z"
      />
    </svg>
  )
}
export default SvgSymbolicbanned
