/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicfeedback = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.656 3.626c-.663 0-1.24.563-1.24 1.296v9.026c0 .732.577 1.296 1.24 1.296h5.001c.388 0 .703.31.709.698l.02 1.425 2.709-1.986a.708.708 0 01.419-.137h4.746c.663 0 1.24-.564 1.24-1.296v-4.78a.708.708 0 111.417 0v4.78c0 1.476-1.173 2.712-2.657 2.712h-4.515l-3.629 2.662a.708.708 0 01-1.127-.561l-.03-2.1H2.656C1.173 16.66 0 15.423 0 13.946V4.922C0 3.446 1.173 2.21 2.656 2.21h7.219a.708.708 0 010 1.417H2.656z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.186 1.989l-4.93 4.953.02 1.008 1.374-.172 4.65-4.657a.115.115 0 00.004-.16l-.966-.968c-.05-.048-.117-.04-.152-.004zm-1.003-1A1.525 1.525 0 0118.33.982l.006.007.97.973c.595.596.586 1.553.007 2.149l-.007.006-4.826 4.834a.709.709 0 01-.414.202l-2.397.301a.708.708 0 01-.796-.69l-.04-2.096a.708.708 0 01.206-.513L16.183.99zM3.334 11.251c0-.39.317-.708.708-.708h5.834a.708.708 0 110 1.417H4.042a.708.708 0 01-.708-.709zm0-3.334c0-.39.317-.708.708-.708H8.21a.708.708 0 010 1.417H4.042a.708.708 0 01-.708-.709z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicfeedback
