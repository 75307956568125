/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicfastRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M20.517 12.662c0 .993.032.563.064 3.64.065 7.083-3.48 6.42-3.48 3.64 0-2.944 9.475-1.323 9.475-1.323"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M4.5 7.5h15.952c5.93 0 10.732 4.93 10.732 11.02s-4.802 11.02-10.732 11.02c-5.768 0-10.473-4.666-10.731-10.557H7.755"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M9.785 16.005H6.434m3.351-2.912H5.467m4.318-2.913H4.5"
      />
    </svg>
  )
}
export default SvgSymbolicfastRebrand
