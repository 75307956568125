/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicplaceholder = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 98 110" {...props}>
      <path fill="#fff" d="M0 0h98v110H0z" />
      <mask
        id="symbolic_placeholder_svg__a"
        style={{
          maskType: 'alpha',
        }}
        width={98}
        height={110}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#C5E7E8"
          d="M97.125 55.036C97.125 23.806 81.855 0 48.493 0 19.546 0 .875 21.403.875 55.546.875 90.999 19.546 110 48.493 110c32.277 0 48.632-24.533 48.632-54.964z"
        />
      </mask>
      <g mask="url(#symbolic_placeholder_svg__a)">
        <rect width={110.75} height={110} x={-6} fill="currentcolor" rx={14.44} />
      </g>
      <path
        fill="#0B4752"
        d="M49.487 65.031a35.682 35.682 0 01-18.59-5.206 1.864 1.864 0 01-.625-2.562 1.855 1.855 0 012.561-.618A32 32 0 0049.48 61.31c5.887 0 11.642-1.61 16.647-4.665a1.868 1.868 0 012.562.618 1.863 1.863 0 01-.625 2.562 35.698 35.698 0 01-18.577 5.206zm-8.71-14.515a2.742 2.742 0 100-5.485 2.742 2.742 0 000 5.485zm17.414 0a2.742 2.742 0 100-5.485 2.742 2.742 0 000 5.485z"
      />
    </svg>
  )
}
export default SvgSymbolicplaceholder
