/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolictwoHearts = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.06 11.166c1.245-2.448 4.08-3.011 5.895-2.133 1.97.952 2.864 3.67 2.009 6.86-.522 2.17-2.616 4.788-4.47 6.638a.75.75 0 01-.723.194c-2.531-.675-5.653-1.895-7.19-3.513C1.245 16.877.66 14.076 1.89 12.266c1.132-1.668 3.87-2.598 6.17-1.1zm-.2 1.763c-1.633-1.631-3.87-1.085-4.73.179-.417.615-.474 1.394-.254 2.215.252.94.86 1.926 1.772 2.834l.015.016c1.295 1.369 3.879 2.368 6.076 2.987 1.593-1.634 3.332-3.792 3.768-5.624a.658.658 0 01.006-.022c.334-1.242.369-2.4.117-3.341-.22-.821-.66-1.467-1.328-1.79-1.376-.666-3.586-.02-4.187 2.21a.751.751 0 01-1.254.336z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.987 14.449a.75.75 0 01-.537-1.401c1.534-.588 3.012-1.338 3.887-2.263l.015-.016c.911-.909 1.52-1.894 1.772-2.835.22-.821.163-1.6-.255-2.214-.859-1.265-3.096-1.811-4.73-.18a.75.75 0 01-1.254-.336c-.601-2.23-2.811-2.876-4.187-2.21-.669.324-1.108.97-1.328 1.79-.252.941-.217 2.099.117 3.342l.006.021c.123.52.359 1.068.664 1.624a.749.749 0 11-1.314.722c-.373-.68-.654-1.352-.807-1.989-.855-3.19.039-5.907 2.009-6.86 1.814-.878 4.65-.315 5.894 2.134 2.301-1.499 5.039-.568 6.171 1.099 1.229 1.81.645 4.611-1.684 6.939-1.005 1.062-2.679 1.958-4.439 2.633zM6.17 5.165a.75.75 0 11-1.341.67l-.5-1a.75.75 0 111.342-.67l.5 1zm11.159 13.67a.75.75 0 111.342-.67l.5 1a.75.75 0 11-1.342.67l-.5-1zM3.835 9.671a.75.75 0 11-.67-1.342l1-.5a.75.75 0 11.67 1.342l-1 .5zm15.83 4.658a.75.75 0 11.67 1.342l-1 .5a.75.75 0 11-.67-1.342l1-.5z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolictwoHearts
