/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicmicrophone = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M12 13.22c-1.874 0-3.389-1.503-3.389-3.361V6.36C8.611 4.502 10.126 3 12 3c1.874 0 3.389 1.502 3.389 3.36v3.52c0 1.838-1.515 3.34-3.389 3.34zm0 7.689v-3.183"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M18 11.46c0 3.104-2.87 5.614-6 5.614s-6-2.51-6-5.614"
      />
    </svg>
  )
}
export default SvgSymbolicmicrophone
