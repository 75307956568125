/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicgift = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M12.72 13.717H4.5v-1.683c0-1.872 1.505-3.39 3.362-3.39h16.332c1.857 0 3.362 1.518 3.362 3.39v1.683h-8.204m1.206-5.073h-4.53v-.888c0-.751.328-1.465.898-1.953l1.963-1.68c1.666-1.428 4.237-.243 4.237 1.951 0 1.42-1.15 2.57-2.568 2.57z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M11.498 8.644h4.53v-.888a2.57 2.57 0 00-.898-1.953l-1.963-1.68C11.501 2.694 8.93 3.88 8.93 6.073c0 1.42 1.15 2.57 2.568 2.57zm7.794 11.957l-3.263-2.325-3.263 2.325V8.645h6.526V20.6z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M6.377 13.824v11.614c0 1.872 1.505 3.39 3.362 3.39h12.58c1.855 0 3.358-1.514 3.362-3.383m-.001-5.366v-6.255"
      />
    </svg>
  )
}
export default SvgSymbolicgift
