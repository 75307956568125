/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccardRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 37" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M30.91 25.594l-.144 1.704c-.097 1.169-1.11 2.045-2.269 1.948L4.938 27.102a2.12 2.12 0 01-1.93-2.289l.289-3.213.966-10.956c.144-1.412 1.4-2.483 2.8-2.386l22.64 2.045c1.4.146 2.463 1.412 2.366 2.824 0 0-.386 7.2-1.303 8.619M4.07 13.031l27.807 2.532"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M32.07 13.128s-.387 7.2-1.304 8.619c-.917 1.418-16.523 4.028-19.516-.744-1.883-2.97 1.5-5.25 4.5-3.75s-.886 7.383-7.5 6.75"
      />
    </svg>
  )
}
export default SvgSymboliccardRebrand
