/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicfile = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 21" {...props}>
      <path
        fill="currentColor"
        d="M24.317 7.283a2.29 2.29 0 00-1.663-.7H2.3c-.636 0-1.227.25-1.663.7A2.262 2.262 0 000 8.956l2.132 9.742c.043 1.207 1.075 2.19 2.3 2.19h16.062c1.202 0 2.212-.95 2.299-2.174l2.16-9.775a2.255 2.255 0 00-.637-1.656zM21.154 18.61a.674.674 0 01-.66.634H4.435a.66.66 0 01-.66-.62l-2.13-9.74a.624.624 0 01.176-.458.662.662 0 01.481-.199h20.353c.186 0 .358.071.482.2.12.122.182.285.177.44l-2.159 9.743z"
      />
      <path
        fill="currentColor"
        d="M21.53 2.446h-9.027L10.323.928a1.535 1.535 0 00-1.01-.372H3.37c-1.057 0-1.917.873-1.917 1.945v4.903h1.643V2.501c0-.169.12-.301.274-.301h5.91a.7.7 0 00.058.044l2.438 1.698a.822.822 0 00.47.148h9.285c.154 0 .274.132.274.301v3.014h1.644V4.39c0-1.072-.86-1.945-1.918-1.945z"
      />
    </svg>
  )
}
export default SvgSymbolicfile
