/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicbigCalendar = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 65" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.6}
        d="M57.286 63H6.496C4.023 63 2 60.993 2 58.537V12.109c0-2.457 2.022-4.463 4.497-4.463h50.79c2.474 0 4.496 2.006 4.496 4.463v46.428c0 2.486-1.992 4.463-4.497 4.463zM2 27.573h59.783M19.587 2v10.331M44.484 2v10.331M10.997 41.193l5.256-5.255m-5.256 0l5.256 5.255m14.836 12.644l5.256-5.256m-5.256 0l5.256 5.256"
      />
    </svg>
  )
}
export default SvgSymbolicbigCalendar
