/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolichandshake = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M13.52 17.743l.537 5.067m3.779-9.837l2.385 5.855"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M10.672 22.269c.613-2.517 1.484-5.543 2.194-6.052 1.29-.892 6.466-2.824 6.402-7.06"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M4.508 19.052c.935-2.102 2.29-4.841 2.904-5.064.903-.383 4.29-1.21 3.42-2.326a.935.935 0 00-.807-.318c-.968.095-3.517.446-3.936 1.115"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M18.79 6.294s.432 1.29-1.278 3.297m-.155-4.729s.96 1.13-1.267 3.773m-.64-5.681s1.06-.084.705 1.477c-.355 1.56-.968 2.325-.968 2.325"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M7.799 6.597s-3.986.552-3.792 2.559"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M13.528 5.34S12.246 7.675 11 6.5s.874-1.957 2.187-2.228C14.5 4 15.349 1.66 12.703 2.042c-2.01.295-3.248 1.079-4.187 2.458"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M9.639 11.375s1.613-.032 1.678-1.02c.064-.987-2.194-.604-2.194-.604s-3.13.159-3.775.86m5.257-1.019s.322-1.465-.871-1.593c-1.194-.16-3.582.542-3.582.542"
      />
    </svg>
  )
}
export default SvgSymbolichandshake
