/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicplayOutline = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 12" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.33}
        d="M3.94 1.9l5.32 3.45c.92.6.89 1.95-.06 2.51l-5.32 3.12c-.98.57-2.22-.14-2.22-1.28V3.14c0-1.17 1.3-1.87 2.28-1.24z"
      />
    </svg>
  )
}
export default SvgSymbolicplayOutline
