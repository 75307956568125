/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicstarEmpty = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentcolor"
        d="M12 2.714c.268 0 .553.143.696.428l2.446 4.963 5.481.804c.625.089.893.875.428 1.32l-3.98 3.857.928 5.463a.774.774 0 01-1.125.821L12 17.764l-4.892 2.57a.878.878 0 01-.357.09.793.793 0 01-.767-.91l.928-5.464-3.963-3.838a.77.77 0 01.428-1.321l5.48-.786 2.447-4.963A.76.76 0 0112 2.714zM12 1a2.46 2.46 0 00-2.214 1.375l-2.07 4.16-4.589.66a2.461 2.461 0 00-2 1.678 2.454 2.454 0 00.625 2.553l3.321 3.231-.785 4.57a2.441 2.441 0 00.553 2.018c.482.572 1.16.893 1.892.893a2.64 2.64 0 001.16-.286L12 19.692l4.106 2.16c.357.179.75.286 1.16.286.733 0 1.429-.322 1.893-.893a2.55 2.55 0 00.553-2.017l-.785-4.57 3.32-3.232c.679-.66.929-1.642.625-2.535a2.406 2.406 0 00-2-1.678l-4.605-.679-2.053-4.16A2.48 2.48 0 0012 1z"
      />
    </svg>
  )
}
export default SvgSymbolicstarEmpty
