/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicburn = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.4}
        d="M19.07 29.996s2.495-3.723 2.48-6.913c-.213-3.034 4.743 3.955 3.996 7.104 0 0 3.395-1.17 3.048-3.357-.346-2.188 5.675 4.343-.755 10.198-3.818 3.482-13.51 2.778-15.311-6.05-1.801-8.83 4.875-8.796 5.86-23.179 0 0 6.394 1.697 8.089 9.01 1.695 7.311 1.17 6.443 3.638 3.222 2.468-3.221 2.56-4.895 3.637-3.66 1.076 1.235 5.378 9.698 3.027 14.269"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.4}
        d="M19.068 29.997s2.496-3.723 2.481-6.913c-.214-3.034 4.742 3.955 3.996 7.103 0 0 3.394-1.169 3.048-3.356-.347-2.188 5.674 4.342-.755 10.198-3.818 3.482-13.51 2.778-15.312-6.05"
      />
      <path
        fill="#E47B53"
        d="M10.276 15.1a1.16 1.16 0 10.129-2.317 1.16 1.16 0 00-.129 2.317zm20.098-6.25a1.16 1.16 0 10.128-2.317 1.16 1.16 0 00-.128 2.317zm8.192 6.07a1.16 1.16 0 10.127-2.317 1.16 1.16 0 00-.127 2.317z"
      />
    </svg>
  )
}
export default SvgSymbolicburn
