/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsettings = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.12}
        d="M22.26 10.22h-1.13c-.22-.96-.6-1.86-1.1-2.67l.8-.8c.28-.28.28-.74 0-1.02l-1.84-1.84a.724.724 0 00-1.02 0l-.8.8c-.81-.5-1.71-.88-2.67-1.1V2.47c0-.4-.32-.72-.72-.72h-2.6c-.4 0-.72.32-.72.72V3.6c-.96.22-1.86.6-2.67 1.1l-.8-.8a.724.724 0 00-1.02 0L4.15 5.74c-.28.28-.28.74 0 1.02l.8.8c-.51.81-.88 1.71-1.1 2.67H2.72c-.4 0-.72.32-.72.72v2.6c0 .4.32.72.72.72h1.13c.22.96.6 1.86 1.1 2.67l-.8.8c-.28.28-.28.74 0 1.02l1.84 1.84c.28.28.74.28 1.02 0l.8-.8c.81.5 1.71.88 2.67 1.1v1.13c0 .4.32.72.72.72h2.6c.4 0 .72-.32.72-.72V20.9c.96-.22 1.86-.6 2.67-1.1l.8.8c.28.28.74.28 1.02 0l1.84-1.84c.28-.28.28-.74 0-1.02l-.8-.8c.5-.81.88-1.71 1.1-2.67h1.13c.4 0 .72-.32.72-.72v-2.61a.754.754 0 00-.74-.72z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.91}
        d="M12.49 15.87a3.63 3.63 0 100-7.26 3.63 3.63 0 000 7.26z"
      />
    </svg>
  )
}
export default SvgSymbolicsettings
