/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicconfidence = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 41 40" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.4}
        d="M14.323 34v-9.388A7.823 7.823 0 016.5 16.788v-1.422a2.134 2.134 0 114.267 0v1.422a3.556 3.556 0 003.556 3.556h12.802a3.556 3.556 0 003.557-3.556v-1.422a2.134 2.134 0 014.267 0v1.422a7.823 7.823 0 01-7.823 7.824V34"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.4}
        d="M25.704 15.366a4.978 4.978 0 11-9.957 0 4.978 4.978 0 019.957 0zM20.725 6.12V3.986m-4.623 3.372l-1.067-1.847m-2.318 5.232L10.87 9.676m17.863 1.067l1.848-1.067m-5.232-2.318l1.067-1.847"
      />
    </svg>
  )
}
export default SvgSymbolicconfidence
