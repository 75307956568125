/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicperson = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 24" {...props}>
      <g clipPath="url(#symbolic_person_svg__symbolic_person_svg__clip0)">
        <path
          fill="currentColor"
          d="M13.43 23.7h-4.8c-.95 0-1.72-.77-1.72-1.72v-2.97l-2.27.24c-.69.07-1.35-.1-1.88-.49-.61-.45-.98-1.15-1.04-1.9l-.15-2.11c-.31-.06-.69-.15-1.02-.5-.65-.68-.73-1.71-.19-2.49L1.53 9.7c-.14-2.55.69-5.01 2.31-6.81C5.53 1.03 7.9 0 10.54 0c2.92 0 5.36.99 7.08 2.87.94 1.02 1.63 2.26 2.05 3.68.38 1.28.52 2.66.4 4.01-.11 1.25-.57 2.62-1.32 3.84-.87 1.43-2.08 2.64-3.52 3.49-.19.11-.32.33-.32.54v3.77c0 .83-.66 1.5-1.48 1.5zm-4.39-2.13h3.75v-3.13c0-.97.52-1.88 1.36-2.38 1.14-.68 2.1-1.63 2.79-2.76.58-.95.94-1.99 1.01-2.92.09-1.09-.02-2.2-.32-3.23-.33-1.1-.86-2.06-1.57-2.84-1.3-1.42-3.2-2.17-5.49-2.17-2.04 0-3.85.78-5.13 2.19-1.3 1.44-1.93 3.45-1.75 5.53l.03.33-1.44 2.53c.56.12.85.26 1.08.49l.27.28.23 3.23c.01.14.07.26.19.34.09.07.22.1.37.08L7 16.86c.65-.07 1.3.22 1.69.74.23.31.36.7.36 1.1v2.87h-.01z"
        />
      </g>
      <defs>
        <clipPath id="symbolic_person_svg__symbolic_person_svg__clip0">
          <path fill="#fff" d="M0 0h20.11v23.7H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicperson
