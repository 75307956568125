/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicfullscreen = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 27" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M19.1 1.44h6.74c.36 0 .72.36.72.72v6.12m-.24 9.84v6.6c0 .36-.36.72-.72.72h-6.13m-10.57.12H2.27a.77.77 0 01-.72-.72v-6.12m.25-9.84V2.16c0-.36.36-.72.72-.72h6.13"
      />
      <rect
        width={13.94}
        height={15.7}
        x={22.29}
        y={6.49}
        stroke="currentColor"
        strokeWidth={2}
        rx={1}
        transform="rotate(90 22.29 6.49)"
      />
    </svg>
  )
}
export default SvgSymbolicfullscreen
