/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsend = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16" {...props}>
      <g
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.24}
        clipPath="url(#symbolic_send_svg__symbolic_send_svg__clip0)"
      >
        <path d="M.6 6.8L17.2.6 13 14.3l-5-3.7-2.2 4.2-.1-6-5.1-2zm5 2.1L17 .7m-9.1 10L17.2.8" />
      </g>
      <defs>
        <clipPath id="symbolic_send_svg__symbolic_send_svg__clip0">
          <path fill="#fff" d="M0 0h17.8v15.5H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicsend
