/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicchatRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M20.365 26.019s5.008 6.248 5.095 4.748c.086-1.5.259-4.915.259-4.915l.81-.014a4 4 0 003.927-4.178l-.378-8.428a5 5 0 00-5.012-4.776l-14.979.053a3.98 3.98 0 00-3.974 3.966c-.009 3.457 0 8.195.106 9.962 0 1.083.863 2 1.9 2.166.69.084 7.713.73 5.381-2.853-2.988-2.104-6.634 5.188 1.742 5.772"
      />
      <path
        fill="#E47B53"
        d="M13.5 19a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm5 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm5 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
      />
    </svg>
  )
}
export default SvgSymbolicchatRebrand
