/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicconsultation = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M20.583 16.029h-8.12l-5.798 4.406-.047-4.406H4.023A2.019 2.019 0 012 14.015V5.347c0-1.112.906-2.014 2.023-2.014h16.56a2.02 2.02 0 012.024 2.014v8.668a2.019 2.019 0 01-2.024 2.014z"
      />
      <path
        fill="currentcolor"
        d="M8.508 10.878a.94.94 0 00.941-.937.94.94 0 00-.941-.937.94.94 0 00-.942.937.94.94 0 00.942.937zm3.945 0a.94.94 0 00.942-.937.94.94 0 00-.942-.937.94.94 0 00-.941.937.94.94 0 00.941.937zm3.945 0a.94.94 0 00.942-.937.94.94 0 00-.942-.937.94.94 0 00-.941.937.94.94 0 00.941.937z"
      />
    </svg>
  )
}
export default SvgSymbolicconsultation
