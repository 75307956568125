/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccirclePlus = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" {...props}>
      <rect width={2.29} height={23.95} x={18.85} y={8.15} fill="currentColor" rx={1.15} />
      <rect
        width={2.04}
        height={23.95}
        x={31.85}
        y={19.11}
        fill="currentColor"
        rx={1.02}
        transform="rotate(90 31.847 19.108)"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20 37.962c9.92 0 17.962-8.042 17.962-17.962S29.92 2.038 20 2.038 2.038 10.08 2.038 20 10.08 37.962 20 37.962zM20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymboliccirclePlus
