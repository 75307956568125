/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolichand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 65 60" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.25}
        d="M12.47 58.572l2.204-2.205a2.067 2.067 0 011.453-.6h16.256a36.346 36.346 0 0016.281-3.833l12.199-6.062c1.979-.977 2.73-3.456 1.628-5.385-.977-1.728-3.106-2.405-4.935-1.603l-14.853 6.638a.891.891 0 01-.25.05h-12.9"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.25}
        d="M2 48.227l13.726-10.32s7.94-7.238 16.883.577H41.6a3.196 3.196 0 013.206 3.206c0 1.778-1.729 3.857-3.507 3.857m1.981-29.381V10.73m4.007-1.177c0 3.607-1.728 6.763-3.883 6.763-2.154 0-3.882-3.156-3.882-6.763 0-3.607 3.056-7.765 3.882-7.765 1.077 0 3.883 4.133 3.883 7.765zM38.646 29.44l-4.66-5.085m2.73-4.558c3.106 3.382 4.183 7.84 2.18 9.694-2.004 1.853-6.363.375-9.469-3.031-3.105-3.407-4.082-9.518-3.005-10.62 1.077-1.053 7.189.576 10.294 3.957zm11.998 9.643l4.66-5.085m-2.731-4.558c-3.106 3.382-4.183 7.84-2.18 9.694 2.005 1.853 6.363.375 9.469-3.031 3.106-3.407 4.083-9.518 3.006-10.62-1.077-1.053-7.19.576-10.295 3.957z"
      />
    </svg>
  )
}
export default SvgSymbolichand
