/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicmonitor = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 65 59" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.6}
        d="M32 31a7 7 0 100-14 7 7 0 000 14zM18 46c0-7.724 6.276-14 14-14s14 6.276 14 14"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.6}
        d="M54.777 48H10.254C5.697 48 2 44.294 2 39.726V10.274C2 5.706 5.697 2 10.254 2h44.491C59.303 2 63 5.706 63 10.274v29.42c.032 4.6-3.665 8.306-8.223 8.306zM2 57h61"
      />
    </svg>
  )
}
export default SvgSymbolicmonitor
