/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicmicrophoneMute = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M8.4 4.49c.6-1.1 1.7-1.8 3-1.8 1.9 0 3.4 1.5 3.4 3.4v3.5c0 .6-.2 1.2-.5 1.7m-2.9 1.7c-1.9 0-3.4-1.5-3.4-3.4m3.4 11.1v-3.2m3.1-1.5c-.9.5-2 .8-3.1.8-3.1 0-6-2.5-6-5.6m12 0c0 .9-.2 1.7-.6 2.4"
      />
      <path stroke="currentcolor" strokeLinecap="round" strokeWidth={1.2} d="M20 19.5L5 3.5" />
    </svg>
  )
}
export default SvgSymbolicmicrophoneMute
