/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicchat = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 20" {...props}>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        clipPath="url(#symbolic_chat_svg__symbolic_chat_svg__clip0)"
      >
        <path
          strokeWidth={1.72}
          d="M20.3 15.538H9.1c-.1 0-.2 0-.2.1l-2.9 2.5c-.2.2-.5.1-.5-.1l-.3-2.2c0-.1-.1-.2-.3-.2H1.1c-.2 0-.3-.1-.3-.3v-13.6c.1-.1.2-.2.3-.2h19.2c.2 0 .3.1.3.3v13.4c0 .1-.1.3-.3.3z"
        />
        <path strokeWidth={1.55} d="M6.3 7.938h7.6m-7.6 3h4.9m-4.9-6H10" />
      </g>
      <defs>
        <clipPath id="symbolic_chat_svg__symbolic_chat_svg__clip0">
          <path fill="#fff" d="M0 0h21.5v18.4H0z" transform="translate(0 .638)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicchat
