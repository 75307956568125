/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicuniversity = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 32" {...props}>
      <g
        fill="currentcolor"
        fillRule="evenodd"
        clipPath="url(#symbolic_university_svg__symbolic_university_svg__clip0_14132_155349)"
        clipRule="evenodd"
      >
        <path d="M5.16 2.467c-1.48 0-2.723 1.24-2.723 2.814v17.164a4.179 4.179 0 012.274-.672h13.357c1.48 0 2.724-1.24 2.724-2.814V5.281c0-1.574-1.244-2.814-2.724-2.814H5.161zm-2.723 23.66c0-1.312 1.051-2.354 2.274-2.354h13.357c2.63 0 4.724-2.18 4.724-4.814V5.281c0-2.634-2.095-4.814-4.724-4.814H5.161C2.53.467.437 2.647.437 5.281v20.912c0 2.37 1.902 4.353 4.274 4.353h8.67v-2H4.71c-1.223 0-2.274-1.042-2.274-2.353v-.066z" />
        <path d="M3.52 26.127a1 1 0 011-1h18.044a1 1 0 110 2H4.52a1 1 0 01-1-1z" />
        <path d="M19.518 26.127v4.011c0 .46-.225.885-.597 1.138a1.338 1.338 0 01-1.372.081l-.026-.013-1.497-.876-1.33.838c-.53.348-1.1.185-1.407-.01a1.378 1.378 0 01-.651-1.158v-3.88h2v2.72l.688-.433.04-.02a1.4 1.4 0 011.296 0l.025.013.831.486v-2.897h2zm.657 3.485a1 1 0 011-1h1.44a1 1 0 110 2h-1.44a1 1 0 01-1-1zM5.895 7.846a1 1 0 011-1h8.861a1 1 0 110 2H6.895a1 1 0 01-1-1zm0 3.354a1 1 0 011-1h5.33a1 1 0 110 2h-5.33a1 1 0 01-1-1z" />
      </g>
      <defs>
        <clipPath id="symbolic_university_svg__symbolic_university_svg__clip0_14132_155349">
          <path fill="#fff" d="M0 0h24v32H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicuniversity
