/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicfeeling = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 160" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M98.37 122.701c13.439 0 24.332-11.116 24.332-24.828 0-13.713-10.893-24.83-24.332-24.83-13.438 0-24.332 11.117-24.332 24.83 0 13.712 10.894 24.828 24.332 24.828z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M89.929 98.835l4.29 5.99 14.58-14.897M36.19 110.702l-2.117 3.049c-2.022 2.953.77 6.825 4.187 5.857 13.718-3.872 25.656-9.487 35.812-15.732m-34.122 1.312l.385-.533c1.974-2.904 1.733-6.632-.385-9.197-.53-.678-1.203-1.259-2.022-1.743l-8.904-5.324c-3.033-1.84-2.215-6.438 1.251-7.067 17.906-3.244 54.92-12.102 78.795-32.287l-4.14-4.163c-1.54-1.549-.722-4.163 1.396-4.598l19.446-3.873c3.562-.726 6.739 2.324 6.161 5.954l-2.984 19.895c-.337 2.226-3.032 3.098-4.573 1.5l-4.476-4.501s-3.706 6.39-11.36 15.199"
      />
    </svg>
  )
}
export default SvgSymbolicfeeling
