/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolictext = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 153 116" {...props}>
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={10}
        d="M135.32 80.222c4.781-9.136 6.863-19.786 5.349-30.814-3.598-26.176-26.933-45.583-53.298-44.353-30.53 1.42-53.062 28.827-48.518 59.073 3.929 26.27 27.785 45.441 54.292 43.642 7.243-.473 14.011-2.461 20.07-5.538.473-.237.994-.331 1.515-.189l28.163 3.029c2.651.568 4.971-1.846 4.261-4.449l-11.976-18.744c-.189-.568-.095-1.136.142-1.657z"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={10}
        d="M60.343 98.303c-5.254 7.905-14.39 12.97-24.377 12.686-3.976-.095-7.762-1.042-11.17-2.604-.284-.142-.568-.142-.852-.094l-15.431 2.272c-1.468.378-2.793-.9-2.462-2.367l6.201-10.603c.047-.284 0-.615-.142-.9-2.84-4.922-4.213-10.744-3.597-16.85 1.42-14.484 13.821-25.702 28.4-25.56 2.083 0 4.166.236 6.106.71"
      />
    </svg>
  )
}
export default SvgSymbolictext
