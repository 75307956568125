/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicapplepay = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 38 16" {...props}>
      <g clipPath="url(#symbolic_applepay_svg__symbolic_applepay_svg__clip0_8382_107961)">
        <path
          fill="#000"
          d="M7.259 2.36c-.431.514-1.12.919-1.81.86-.086-.693.251-1.43.646-1.886C6.526.806 7.28.43 7.891.4c.072.723-.209 1.432-.632 1.96zm.625.998c-.999-.058-1.853.571-2.327.571-.481 0-1.207-.542-1.997-.527-1.027.014-1.982.6-2.506 1.532-1.077 1.866-.28 4.628.761 6.146.51.752 1.12 1.576 1.925 1.547.761-.029 1.063-.498 1.982-.498.926 0 1.192.498 1.996.484.833-.014 1.358-.752 1.868-1.504.581-.853.818-1.685.833-1.728-.015-.014-1.609-.629-1.623-2.48-.015-1.547 1.256-2.285 1.314-2.328-.718-1.07-1.839-1.186-2.226-1.215zm5.766-2.097v11.272h1.738V8.68h2.406c2.198 0 3.742-1.519 3.742-3.717 0-2.198-1.516-3.702-3.684-3.702H13.65zm1.738 1.475h2.004c1.508 0 2.37.81 2.37 2.235 0 1.424-.862 2.24-2.377 2.24h-1.997V2.737zm9.322 9.884c1.092 0 2.104-.557 2.564-1.439h.036v1.352h1.608v-5.61c0-1.627-1.292-2.676-3.282-2.676-1.845 0-3.21 1.063-3.26 2.524h1.566c.129-.694.768-1.15 1.644-1.15 1.063 0 1.66.5 1.66 1.417v.622l-2.17.13c-2.018.123-3.11.955-3.11 2.4 0 1.461 1.128 2.43 2.744 2.43zm.467-1.337c-.927 0-1.515-.449-1.515-1.136 0-.708.567-1.12 1.651-1.185l1.932-.123v.636c0 1.056-.89 1.808-2.068 1.808zm5.889 4.316c1.695 0 2.492-.65 3.188-2.624l3.053-8.62H35.54l-2.047 6.66h-.036l-2.047-6.66h-1.817l2.945 8.207-.158.5c-.266.845-.697 1.17-1.465 1.17-.137 0-.402-.014-.51-.028v1.352c.1.029.531.043.66.043z"
        />
      </g>
      <defs>
        <clipPath id="symbolic_applepay_svg__symbolic_applepay_svg__clip0_8382_107961">
          <path fill="#fff" d="M0 0h36.77v15.2H0z" transform="translate(.537 .4)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicapplepay
