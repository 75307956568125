/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsecurity = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 37" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M23.283 11.126s.842-7.295-4.742-6.985c-5.584.31-5.983 2.172-5.806 6.176.177 4.653-.624 8.468 2.478 8.335 1.714-.074 2.885-3.312-.395-3.445-3.28-.088-6.559-.399-6.958 1.463-.354 1.64-.886 15.245.887 15.688 1.905.443 18.347.754 18.967-.443.842-1.684.754-14.758-.133-15.999-.797-1.152-4.564-.753-4.564-.753"
      />
      <path
        stroke="#E47B53"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M21.6 23.08c0 1.349-.533 2.541-1.98 2.852-.29.052 1.32 2.593-1.262 2.593-2.038 0-1.146-2.54-1.378-2.54-1.505-.26-1.98-1.453-1.98-2.904 0-1.608 1.447-2.956 3.3-2.956 1.853 0 3.3 1.348 3.3 2.956z"
      />
    </svg>
  )
}
export default SvgSymbolicsecurity
