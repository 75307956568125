/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsavings = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 104 100" {...props}>
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={7.4}
        d="M100 71.6A10 10 0 0084 64L68.5 75.4a6.1 6.1 0 01-6.2.6l-4.2-3.5c-.6-.6-.5-1.6.2-2A10 10 0 0055.1 52s0 0 0 0c-2-.3-3.9.1-5.5 1l-9.2 4.7c-2.4 1.1-5 1.7-7.6 1.6L17.3 59h-1.8a14.5 14.5 0 002 28.6l41.4 7.7c6.4 1.4 13.2.1 18.7-3.6L92.7 82c3.9-2.7 7.5-5.6 7.3-10.4zm-20.5-31a18.3 18.3 0 100-36.6 18.3 18.3 0 000 36.6z"
      />
      <path stroke="currentColor" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={7.4} d="M58.8 70.8L43 76" />
    </svg>
  )
}
export default SvgSymbolicsavings
