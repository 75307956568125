/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicmedication = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 160" {...props}>
      <g clipPath="url(#symbolic_medication_svg__symbolic_medication_svg__clip0_7685_101138)">
        <path
          stroke="currentColor"
          strokeMiterlimit={10}
          strokeWidth={5}
          d="M95.27 98.014c-4.285 4.285-11.226 4.285-15.51 0L62.37 80.626c-4.285-4.285-4.285-11.226 0-15.51 4.285-4.286 11.226-4.286 15.511 0L95.27 82.502c4.285 4.285 4.285 11.262 0 15.511zm-24.116-8.782l15.44-15.405m19.726 60.416c-6.587-2.833-9.597-10.483-6.764-17.07l11.545-26.701c2.833-6.587 10.482-9.597 17.069-6.764 6.587 2.833 9.597 10.482 6.764 17.069l-11.544 26.702a12.996 12.996 0 01-17.07 6.764zm-.85-30.385l23.691 10.27m-10.73-68.313c-.46 5.205-5.064 9.03-10.27 8.534l-21.07-1.947c-5.207-.46-9.031-5.065-8.536-10.27.46-5.206 5.065-9.03 10.27-8.535l21.071 1.948c5.171.495 8.995 5.1 8.535 10.27zm-20.787 7.472l1.7-18.698m-37.285 81.21c4.914-7.723 4.417-16.835-1.111-20.353-5.528-3.517-13.993-.109-18.908 7.614-4.914 7.723-4.417 16.835 1.111 20.353 5.528 3.517 13.993.109 18.908-7.614z"
        />
        <path
          stroke="currentColor"
          strokeMiterlimit={10}
          strokeWidth={5}
          d="M43.072 123.301l6.587 4.958c5.524 3.506 13.988.106 18.91-7.614 4.923-7.72 4.427-16.857-1.133-20.363l-6.02-4.533m-20.611 9.667l22.027 8.003M35.033 84.345c7.197 0 13.032-5.835 13.032-13.032 0-7.198-5.835-13.033-13.032-13.033C27.835 58.28 22 64.115 22 71.312c0 7.198 5.835 13.033 13.033 13.033zm-7.65-5.348l15.299-15.334"
        />
        <path
          fill="currentColor"
          d="M59.645 21.167l-1.913 5.348c-.07.212-.248.424-.46.495l-4.745 2.16c-.673.32-.673 1.382 0 1.665l4.745 2.16c.212.107.354.284.46.496l1.913 5.348c.283.743 1.204.743 1.487 0l1.912-5.348c.071-.212.248-.425.46-.496l4.746-2.16c.673-.319.673-1.381 0-1.664l-4.745-2.16c-.213-.107-.354-.284-.46-.496l-1.913-5.348c-.248-.744-1.204-.744-1.487 0zM38.007 34.66l-1.416 3.966c-.071.177-.177.318-.319.39l-3.541 1.593c-.496.212-.496 1.027 0 1.24l3.541 1.593a.59.59 0 01.319.39l1.416 3.966c.213.566.921.566 1.098 0l1.417-3.967c.07-.177.177-.318.318-.39l3.542-1.593c.496-.212.496-1.027 0-1.24l-3.542-1.593a.593.593 0 01-.318-.39l-1.417-3.966c-.212-.566-.92-.566-1.098 0zm92.339 30.94a4.144 4.144 0 10-.666-8.263 4.144 4.144 0 00.666 8.262zm-17.758 6.847a1.841 1.841 0 100-3.683 1.841 1.841 0 000 3.683z"
        />
      </g>
      <defs>
        <clipPath id="symbolic_medication_svg__symbolic_medication_svg__clip0_7685_101138">
          <path fill="#fff" d="M0 0h160v160H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicmedication
