/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicbefore = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 9 12" {...props}>
      <path
        fill="currentColor"
        d="M2.929 6l5.304-3.912c.336-.24.48-.648.48-.936C8.713.528 8.185 0 7.56 0c-.264 0-.48.072-.672.216L.529 5.04C.265 5.256 0 5.544 0 6c0 .456.264.744.528.96l6.36 4.824c.192.144.408.216.672.216.624 0 1.152-.528 1.152-1.152 0-.288-.144-.696-.48-.936L2.929 6z"
      />
    </svg>
  )
}
export default SvgSymbolicbefore
