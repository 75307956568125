/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicinfo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" {...props}>
      <circle cx={15} cy={15} r={15} fill="currentColor" />
      <path
        fill="#fff"
        d="M15.331 9.818c.315 0 .57.108.767.325.196.206.295.47.295.789 0 .338-.113.625-.34.86-.216.225-.486.338-.81.338s-.59-.103-.796-.31a1.081 1.081 0 01-.295-.775c0-.33.118-.616.354-.86.245-.244.52-.367.825-.367zm-.516 3.552c.492 0 .84.141 1.047.423.216.282.324.686.324 1.212 0 .282-.04.71-.118 1.283a90.88 90.88 0 00-.147 1.085c-.02.122-.06.395-.118.818-.06.413-.089.7-.089.86 0 .244.074.366.222.366.265 0 .506-.372.722-1.114l.796.226c-.059.31-.187.634-.383.972a3.02 3.02 0 01-.767.874 1.774 1.774 0 01-1.09.352c-.522 0-.89-.131-1.106-.394-.207-.263-.31-.663-.31-1.198 0-.423.088-1.17.265-2.241.148-.921.221-1.536.221-1.847 0-.244-.078-.366-.235-.366-.256 0-.492.371-.708 1.113l-.796-.225c.059-.3.187-.62.383-.959a2.97 2.97 0 01.781-.873 1.79 1.79 0 011.106-.367z"
      />
    </svg>
  )
}
export default SvgSymbolicinfo
