/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicdateCheckbox = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 61 60" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={3.75}
        d="M49.204 52.618h-37.21c-1.813 0-3.295-1.534-3.295-3.41V13.723c0-1.878 1.482-3.412 3.295-3.412h37.21c1.813 0 3.294 1.534 3.294 3.412v35.483c0 1.9-1.459 3.411-3.294 3.411zM8.7 25.836h43.798M21.434 6.377v7.875m18.33-7.875v7.875"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.25}
        d="M23.5 40.038l4.813 3.96 9.187-11"
      />
    </svg>
  )
}
export default SvgSymbolicdateCheckbox
