/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicpsychiatry = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 62 62" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={4.01}
        d="M54.042 52.985l-45.915-.23c-1.529-.008-2.796-1.12-2.803-2.507l-.146-29.542c-.006-1.366 1.226-2.487 2.779-2.48l45.915.231c1.529.008 2.796 1.12 2.803 2.507l.145 29.542c.007 1.366-1.25 2.486-2.778 2.479zM12.28 18.92l.162 32.88m37.604-32.064l.163 33.097m-26.11-34.59l-.04-7.911 13.627.068.039 7.912m-6.761 8.932l.082 16.69m-8.294-8.385l16.497.083"
      />
    </svg>
  )
}
export default SvgSymbolicpsychiatry
