/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicconcentration = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 160" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={4.8}
        d="M74.131 136.055c-27.258 0-49.417-22.076-49.417-49.234 0-27.157 22.159-49.233 49.417-49.233 27.259 0 49.418 22.076 49.418 49.233 0 27.158-22.159 49.234-49.418 49.234z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={4.8}
        d="M61.441 51.72c3.957-1.446 8.222-2.19 12.706-2.19 20.664 0 37.459 16.732 37.459 37.319 0 20.587-16.795 37.319-37.459 37.319s-37.459-16.732-37.459-37.32a37.146 37.146 0 017.167-21.944"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={4.8}
        d="M85.713 109.412a25.567 25.567 0 01-11.563 2.759c-14.026 0-25.457-11.388-25.457-25.361S60.124 61.448 74.15 61.448c14.025 0 25.456 11.389 25.456 25.362 0 2.803-.44 5.475-1.275 7.972"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={4.8}
        d="M87.64 86.805c0 7.446-6.023 13.447-13.497 13.447s-13.498-6-13.498-13.447c0-7.447 6.024-13.448 13.498-13.448 7.43 0 13.498 6.045 13.498 13.448zm29.43-43.193L74.379 86.144m46.224-61.434l-9.892 9.856c-1.231 1.227-1.935 2.848-2.023 4.6l.22 12.57 12.75-12.746-1.055-14.28zm15.398 15.369l-14.333-1.052-12.75 12.747 12.574.219c1.715-.044 3.386-.789 4.617-2.015l9.892-9.9z"
      />
    </svg>
  )
}
export default SvgSymbolicconcentration
