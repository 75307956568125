/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicyoutube = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.282.34a1.691 1.691 0 011.19 1.19c.29 1.056.278 3.258.278 3.258s0 2.19-.278 3.247a1.691 1.691 0 01-1.19 1.19C11.226 9.503 7 9.503 7 9.503s-4.215 0-5.282-.29a1.692 1.692 0 01-1.19-1.19C.25 6.98.25 4.778.25 4.778s0-2.19.278-3.247A1.726 1.726 0 011.718.329C2.774.05 7 .05 7 .05s4.226 0 5.282.289zM9.168 4.777L5.654 6.8V2.753l3.514 2.024z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicyoutube
