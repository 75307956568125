/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsadness = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" {...props}>
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M23.405 11c-5.177 0-9.559 2.05-12.8 4.282a31.765 31.765 0 00-6.083 5.498c-.349.303-.526.73-.522 1.16-.004.438.181.874.543 1.177a33.87 33.87 0 006.181 5.481c3.26 2.232 7.636 4.301 12.68 4.301 5.199 0 9.594-2.066 12.838-4.309a31.756 31.756 0 005.954-5.37 1.512 1.512 0 00.623-1.26 1.512 1.512 0 00-.645-1.276 33.7 33.7 0 00-6.056-5.36C32.854 13.082 28.466 11 23.405 11zM39.25 21.975a31.133 31.133 0 00-4.005-3.553 10.48 10.48 0 01.503 3.133c0 1.459-.245 2.86-.695 4.164a29.032 29.032 0 004.197-3.744zm-8.97-6.451c-2.094-.9-4.41-1.494-6.876-1.494-2.523 0-4.853.578-6.937 1.456C15.005 17.329 14 19.536 14 21.555a9.682 9.682 0 002.994 7.014c1.97.791 4.127 1.3 6.41 1.3 2.218 0 4.287-.446 6.17-1.15a9.704 9.704 0 003.146-7.165c0-2.004-.992-4.196-2.44-6.03zm-18.793 2.852a29.166 29.166 0 00-3.934 3.547c.9.95 2.281 2.276 4.039 3.576a12.722 12.722 0 01-.623-3.944c0-1.08.191-2.15.518-3.179z"
        clipRule="evenodd"
      />
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M23.185 20.68a1.485 1.485 0 100 2.97 1.485 1.485 0 000-2.97zm-4.515 1.485a4.515 4.515 0 119.03 0 4.515 4.515 0 01-9.03 0zM40.8 28.64c.552 1.086 1.033 1.756 1.449 2.336l.207.29c.476.673.958 1.428.951 2.584a4.129 4.129 0 01-4.075 4.118h-.027a4.116 4.116 0 01-4.084-4.123c0-1.155.477-1.908.953-2.585l.213-.299c.414-.577.892-1.245 1.44-2.322.615-1.217 2.36-1.217 2.974.001zm-1.487 2.88c.275.426.523.77.728 1.056.068.094.13.18.188.263.407.575.454.734.452.996v.01c0 .755-.61 1.38-1.371 1.396a1.39 1.39 0 01-1.363-1.396c0-.276.053-.442.457-1.015.058-.084.122-.173.191-.269.203-.283.447-.622.718-1.041z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicsadness
