/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicphoneRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M18.5 16.5s1.5 1 1.492 2.613c-.154.669.185 3.071-4.214 2.646-4.4-.426-8.86-3.558-10.583-7.876C3.472 9.565 3.5 8.5 4.488 7.223c.987-1.276 2.06-1.49 3.107-.82 1.046.668 2.676 2.158 2.46 3.344-.215 1.186-.863 1.552-.555 2.83.308 1.277 1.493 2.906 3 3.423 1.507.517 4.5 1.5 3.278 2.86-.739 1.155-3.877.527-1.477-1.936l1.661-1.49"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M10.965 3.176s2.43.608 2.676 3.223M9.929 5.248s1.353.335 1.507 1.794"
      />
    </svg>
  )
}
export default SvgSymbolicphoneRebrand
