/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccross = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 41" {...props}>
      <rect
        width={28.04}
        height={2.5}
        fill="currentColor"
        rx={1.25}
        transform="rotate(-45 39.549 3.918) scale(.9997)"
      />
      <rect
        width={28.04}
        height={2.5}
        fill="currentColor"
        rx={1.25}
        transform="rotate(45 -5.978 17.396) scale(.9997)"
      />
    </svg>
  )
}
export default SvgSymboliccross
