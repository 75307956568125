/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicplayBg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 61 60" {...props}>
      <circle cx={30.5} cy={30} r={30} fill="currentColor" />
      <path
        fill="#fff"
        d="M24.804 22.945c0-.194.047-.384.136-.554.293-.55.955-.749 1.48-.442l13.101 7.61c.178.103.325.257.423.443.293.55.102 1.244-.423 1.55l-13.1 7.61a1.05 1.05 0 01-.529.143c-.6 0-1.088-.51-1.088-1.139V22.945z"
      />
    </svg>
  )
}
export default SvgSymbolicplayBg
