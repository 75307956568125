/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicrelationships = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" {...props}>
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M36.228 13.888c2.768 2.745 3.936 7.18 1.017 11.431l-6.43 9.35c-1.677 2.444-5.02 3.052-7.456 1.382l-9.35-6.43c-4.209-2.886-4.759-7.38-3.234-10.919V18.7c2.241-5.173 8.763-6.457 12.965-3.135-.258-1.313.285-2.742 1.582-3.487l.01-.005c3.78-2.135 8.206-.853 10.896 1.815zm-9.4.819c2.328-1.31 5.302-.617 7.266 1.331 1.887 1.872 2.623 4.698.653 7.566l-6.43 9.351a2.336 2.336 0 01-3.244.598l-9.349-6.43h-.002c-2.85-1.954-3.2-4.82-2.165-7.22 1.471-3.396 6.03-4.227 8.742-1.576l-.654.749-.004.004a4.703 4.703 0 00-.026 6.147l.002.001.937 1.098.005.005a2.735 2.735 0 004.144-3.569l-.51-.589.764-.875.004-.004a4.564 4.564 0 00-.176-6.175.264.264 0 01.042-.412z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicrelationships
