/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicshield = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        fill="currentColor"
        d="M16 1.333l10.956 2.435c.61.135 1.044.676 1.044 1.301v13.316a8 8 0 01-3.562 6.656L16 30.666l-8.438-5.625A8 8 0 014 18.385V5.069c0-.625.434-1.166 1.044-1.301L16 1.333zm0 2.732L6.667 6.139v12.246c0 1.783.89 3.448 2.375 4.438L16 27.46l6.958-4.638a5.333 5.333 0 002.375-4.438V6.139L16 4.065zm4.994 7.84a1.333 1.333 0 011.885 1.886l-6.128 6.128a2 2 0 01-2.828 0l-3.3-3.3a1.333 1.333 0 111.886-1.886l2.827 2.828 5.658-5.656z"
      />
    </svg>
  )
}
export default SvgSymbolicshield
