/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolictest = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 41 40" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={22.93}
        strokeWidth={2}
        d="M3 20.678c2.206 10.807 16.146 15.423 18 8.39 1.146-4.35-3.557-12.936-8.677-11.53-3.03.833-4.722 4.99-1.181 9.412 2.802 3.5 8.308 6.22 12.283 6.042 8.83-.395 11.973-14.179-4.695-22.308-8.06-3.93-12.333.642-10.56 5.873 3.918 11.563 20.572 13.724 24.922 8.379 4.655-5.722-3.834-15.964-12.819-16.902-5.28-.551-6.242 5.694 2.21 10.199 8.774 4.676 13.482 1.477 15.517-.156"
      />
    </svg>
  )
}
export default SvgSymbolictest
