/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccameraDisabled = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M6.6 5.6h8.1c.9 0 1.6.7 1.6 1.6v7.5m-2.7 2.9H4.2c-.9 0-1.6-.7-1.6-1.6V8.3m14 1.3L21.8 7c.2-.1.4.1.4.3v8.4c0 .2-.3.4-.4.3l-5.2-2.6"
      />
      <path stroke="currentcolor" strokeLinecap="round" strokeWidth={1.2} d="M18 19L2.5 5" />
    </svg>
  )
}
export default SvgSymboliccameraDisabled
