/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccheckInline = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.54}
        d="M9.582 13.022l1.21 1.21a.216.216 0 00.322-.023l3.503-4.595"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.9}
        d="M12.1 22c5.468 0 9.9-4.432 9.9-9.9s-4.432-9.9-9.9-9.9c-5.467 0-9.9 4.432-9.9 9.9S6.633 22 12.1 22z"
      />
    </svg>
  )
}
export default SvgSymboliccheckInline
