/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicpsychotherapyRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M25.472 26.388c-3.168 3.558-7.62 4.582-7.62 4.582s-9.41-3.201-9.501-12.637c0 0-.028-8.803.623-9.107.703-.35 5.43.816 10.342-3.375 4.397 4.68 8.928 3.998 9.542 4.417 0 0-.405 7.716-3.287 4.906-2.048-1.945 1.961-3.863 2.82-.675.374 1.277.008 2.66-.966 3.528-.513.457-1.267.853-2.287.75"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeWidth={1.8}
        d="M14.237 18.538l7.49.392m-3.941 3.549l.392-7.49"
      />
    </svg>
  )
}
export default SvgSymbolicpsychotherapyRebrand
