/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsquare = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
      <rect width={20} height={20} fill="currentColor" rx={6} />
    </svg>
  )
}
export default SvgSymbolicsquare
