/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicklarna = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 444.75" {...props}>
      <g fillRule="evenodd" transform="translate(.236 .3) scale(17.769)">
        <rect width={45} height={25} fill="#ffb3c7" rx={4.32} />
        <path
          fill="#0a0b09"
          d="M40.787 14.674a1.07 1.07 0 00-1.066 1.076 1.07 1.07 0 001.066 1.075 1.07 1.07 0 001.066-1.075 1.07 1.07 0 00-1.066-1.076zm-3.507-.832c0-.813-.69-1.472-1.54-1.472s-1.538.659-1.538 1.472c0 .814.689 1.473 1.539 1.473s1.539-.66 1.539-1.473zm.005-2.862h1.699v5.724h-1.699v-.365a2.96 2.96 0 01-1.683.524c-1.653 0-2.994-1.352-2.994-3.02s1.34-3.021 2.994-3.021c.624 0 1.204.194 1.683.524v-.366zm-13.592.746v-.746h-1.739v5.724h1.743v-2.672c0-.902.969-1.386 1.64-1.386h.021v-1.665c-.69 0-1.324.298-1.665.745zm-4.331 2.116c0-.813-.69-1.472-1.54-1.472-.85 0-1.538.659-1.538 1.472 0 .814.689 1.473 1.539 1.473s1.539-.66 1.539-1.473zm.005-2.862h1.699v5.724h-1.699v-.365a2.96 2.96 0 01-1.683.524c-1.653 0-2.994-1.352-2.994-3.02s1.34-3.021 2.994-3.021c.624 0 1.204.194 1.683.524v-.366zm10.223-.154c-.678 0-1.32.213-1.75.8v-.645h-1.69v5.723h1.711v-3.008c0-.87.579-1.296 1.275-1.296.747 0 1.176.45 1.176 1.284v3.02h1.696v-3.64c0-1.332-1.05-2.238-2.418-2.238zm-17.374 5.878h1.778V8.43h-1.778zm-7.809.003H6.29v-8.28H4.407zm6.584-8.28a6.62 6.62 0 01-1.925 4.7l2.602 3.58H9.343l-2.828-3.891.73-.552a4.768 4.768 0 001.904-3.836h1.842z"
        />
      </g>
    </svg>
  )
}
export default SvgSymbolicklarna
