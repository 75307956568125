/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicstamina = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 160" {...props}>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        clipPath="url(#symbolic_stamina_svg__symbolic_stamina_svg__clip0_7685_101173)"
      >
        <path d="M102.325 74.183c-3.858-2.026-8.267-3.14-12.912-3.14-8.621 0-16.337 3.895-21.494 10.055" />
        <path d="M54.539 52.569l4.015 5.404a8.493 8.493 0 009.763 2.902l10.314-3.776c4.802-1.748 7.007-7.391 4.684-12.001l-4.842-9.657c-1.22-2.425-3.936-3.656-6.534-2.941l-23.895 6.557c-5.236 1.43-9.33 5.603-10.669 10.889a128.316 128.316 0 00-4.094 32.15v19.672c0 3.139.866 6.239 2.48 8.901a17.01 17.01 0 0014.566 8.267H65.01c2.323 0 4.567.874 6.338 2.344 4.96 4.173 11.338 6.637 18.305 6.637 15.432 0 27.989-12.677 27.989-28.256 0-4.808-1.102-9.299-3.149-13.273" />
        <path d="M70.396 97.578v-5.206c0-6.04-2.205-11.922-6.18-16.453-4.45-5.047-6.89-11.564-6.89-18.32v-.874m16.518 52.037c8.62 8.703 22.596 8.703 31.178 0M39.457 87.086c-3.858 2.345-6.18 6.557-6.18 11.088m24.819-46.891l-1.18.557c-2.441 1.152-5.315.278-6.771-1.988m58.29-17.542l-6.653 19.671h10.786l-3.267 15.737L128 44.112h-10.904l4.487-11.802h-13.148z" />
      </g>
      <defs>
        <clipPath id="symbolic_stamina_svg__symbolic_stamina_svg__clip0_7685_101173">
          <path fill="#fff" d="M0 0h105v104H0z" transform="translate(28 28)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicstamina
