import { pathOr, assocPath, assoc } from 'ramda'
import { createActions, createReducer } from 'reduxsauce'
import { mergeValuesInArray } from '../utilities/reduxHelpers'
import type { GeneralStateType, RedirectObject } from '../types/GeneralStateType'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  onChangeGeneralValue: ['path', 'value'],
  onSetFetching: ['path', 'fetching'],
  onStartFetching: ['path'],
  onStopFetching: ['path'],
  onChangeRedirectObject: ['redirectObject'],
  onFailure: ['error'],
  setFilterTime: ['path', 'value'],
  addGtmEvent: ['event', 'destination'],
  addGtmUserInAppEvent: ['credentials', 'userType'],
  addGtmSuccessPaymentEvent: ['appointmentId', 'lastPayedAppointment', 'identifier', 'user'],
  addGtmSuccessVoucherEvent: ['appointments', 'therapistId'],
  setGtmPageView: ['page'],
  getAllServicesRequest: null,
})
export const GeneralTypes = Types
export default Creators
/* ------------- Helpers ------------- */
/* ------------- Initial State ------------- */
export const INITIAL_STATE: GeneralStateType = {
  allErrors: [],
  fetching: {
    general: 0,
  },
  redirectObject: null,
  currentError: '',
  timeFilter: {
    days: [],
    times: [],
    filterTimeVisible: false,
  },
  services: [],
  lastScrollPosition: {},
  emailIsInOtherCountry: false,
}

/* ------------- Reducers ------------- */

export const onChangeGeneralValueR = (
  state: GeneralStateType,
  { path, value }: { path: string; value: Object | string | boolean | number },
): GeneralStateType => assocPath(path.split('.'), value, state)

const setFilterTimeR = (
  state: GeneralStateType,
  { path, value }: { path: string; value: Object | string | boolean | number },
): GeneralStateType => mergeValuesInArray(state, path, value)

const onSetFetchingR = (
  state: GeneralStateType,
  { path, fetching }: { path: string; fetching: number },
): GeneralStateType => assocPath(['fetching', path], fetching, state)

const onStartStopFetchingR = (
  state: GeneralStateType,
  { path }: { path: string[] },
  stop?: boolean,
): GeneralStateType => {
  let actualFetching = pathOr(0, ['fetching', ...path], state)
  if (typeof actualFetching === 'undefined') {
    if (stop) {
      console.warn('Trying to stop undefined fetching!')
    }
    return assocPath(['fetching', ...path], stop ? 0 : 1, state)
  }
  if (actualFetching === 0 && stop) {
    console.warn('Trying to stop fetching without starting!')
    actualFetching = 1
  }
  return assocPath(['fetching', ...path], stop ? actualFetching - 1 : actualFetching + 1, state)
}

const onChangeRedirectObjectR = (
  state: GeneralStateType,
  { redirectObject }: { redirectObject: RedirectObject },
): GeneralStateType => assoc('redirectObject', redirectObject, state)

const onFailureR = (state: GeneralStateType, error: Error): GeneralStateType => {
  const stateActualized = assocPath(['currentError'], error, onStartStopFetchingR(state, { path: ['general'] }, true))
  return assocPath(['allErrors'], [...pathOr({}, ['allErrors'], stateActualized), error], stateActualized)
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_CHANGE_REDIRECT_OBJECT]: onChangeRedirectObjectR,
  [Types.ON_SET_FETCHING]: onSetFetchingR,
  [Types.ON_START_FETCHING]: (state, path) => onStartStopFetchingR(state, path, false),
  [Types.ON_STOP_FETCHING]: (state, path) => onStartStopFetchingR(state, path, true),
  [Types.ON_FAILURE]: onFailureR,
  [Types.SET_FILTER_TIME]: setFilterTimeR,
  [Types.ON_CHANGE_GENERAL_VALUE]: onChangeGeneralValueR,
  [Types.ADD_GTM_EVENT]: (state) => state,
  [Types.SET_GTM_PAGE_VIEW]: (state) => state,
  [Types.ADD_GTM_USER_IN_APP_EVENT]: (state) => state,
  [Types.ADD_GTM_SUCCESS_PAYMENT_EVENT]: (state) => state,
  [Types.ADD_GTM_SUCCESS_VOUCHER_EVENT]: (state) => state,
  [Types.GET_ALL_SERVICES_REQUEST]: (state) => state,
})
