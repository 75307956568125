/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicaboutTherapy = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 48" {...props}>
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M30.585 42.017c6.511 0 11.79-5.27 11.79-11.77s-5.279-11.77-11.79-11.77c-6.51 0-11.788 5.27-11.788 11.77s5.278 11.77 11.788 11.77z"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M18.642 30.526C12.375 30.26 7.375 25.098 7.375 18.77 7.375 12.273 12.657 7 19.164 7c6.295 0 11.436 4.922 11.774 11.123"
      />
      <path
        fill="currentColor"
        d="M14.166 18.477a1.126 1.126 0 10.002-2.252 1.126 1.126 0 00-.002 2.252zm10.156 0a1.126 1.126 0 10.002-2.252 1.126 1.126 0 00-.002 2.252zm1.125 7.601a1.126 1.126 0 10.002-2.252 1.126 1.126 0 00-.002 2.252zm10.141 0a1.126 1.126 0 10.001-2.252 1.126 1.126 0 00-.001 2.252z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M27.43 30.51s2.93 2.87 6.042-.041m-12.769-7.497c-1.127-.21-2.521-.07-3.93 1.111"
      />
    </svg>
  )
}
export default SvgSymbolicaboutTherapy
