/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccheckboxFull = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <rect width={22} height={22} x={1} y={1} fill="#fff" stroke="#fff" strokeWidth={2} rx={3} />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.593 6.404a1.385 1.385 0 00-1.993.04l-6.41 8.172-3.862-3.867a1.384 1.384 0 00-1.957 1.958l4.884 4.888a1.384 1.384 0 001.991-.037l7.367-9.215a1.386 1.386 0 00-.018-1.939h-.002z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymboliccheckboxFull
