/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicreschedule = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M18.937 10.262V7.294c-.03-.465-.34-.83-.773-.896-2.256-.3-9.117-1.395-10.323 1.03-.649 1.295 3.308 4.151 2.318-3.222l-.03-.598c-.031-.565-.65-.797-1.02-.432l-6.924 7.706a.676.676 0 000 .93l6.83 7.606c.372.398 1.02.1 1.02-.465l-.03-2.392c0-.332.247-.598.556-.598l1.237.034"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M14.052 15.233s-.742-4.418 2.226-3.156c2.967 1.262-5.564 8.138-3.493 8.403 2.07.266 3.678-.166 3.678-.166"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M19.461 12.907s-4.39 5.71-1.917 5.544c2.473-.166 3.82-1.378 3.82-1.378m-1.284-.844s-1.36 2.39-1.112 4.35"
      />
    </svg>
  )
}
export default SvgSymbolicreschedule
