/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicwatch = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 54 62" {...props}>
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2.6}
        d="M6.346 22.544l-3.268-7.107a3.4 3.4 0 011.07-4.155l10.39-7.735a3.394 3.394 0 014.15.062l6.353 5.02m22.692 30.483L51 46.22a3.4 3.4 0 01-1.07 4.155l-10.39 7.734a3.394 3.394 0 01-4.15-.061l-6.353-5.02"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2.6}
        d="M47.13 39.74L29.811 52.63a6.127 6.127 0 01-8.576-1.254L5.819 30.664a6.127 6.127 0 011.259-8.576l17.317-12.89a6.127 6.127 0 018.576 1.254L48.39 31.164c2.04 2.701 1.457 6.553-1.26 8.576z"
      />
      <path
        fill="currentColor"
        d="M24.338 39.205l-.94-1.262c1.416-4.637 1.747-6.1 1.067-7.014-.486-.652-1.354-.547-1.963-.093-.87.647-1.263 1.481-1.397 2.392l-1.767-.376c.092-1.42.932-2.654 1.976-3.43 1.653-1.231 3.595-1.257 4.728.266.972 1.305.726 3.246-.163 6.138l3.089-2.299 1.069 1.436-5.7 4.242zm10.224-7.61l-1.166-1.566-3.655 2.72-.939-1.262-.484-7.009 2.218-1.651 3.433 4.611 1-.745 1.07 1.436-1.001.745 1.166 1.566-1.61 1.198-.032-.043zm-4.599-6.178l.32 4.698 2.044-1.522-2.364-3.176z"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2.6}
        d="M43.806 18.377l-2.927 2.18-5.98-8.033 2.929-2.179a.957.957 0 011.334.195l4.826 6.483a.962.962 0 01-.181 1.354z"
      />
    </svg>
  )
}
export default SvgSymbolicwatch
