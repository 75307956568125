/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicselectArrow = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 5" {...props}>
      <path
        fill="currentColor"
        d="M4.055 3.133L1.936.26A.652.652 0 001.429 0a.633.633 0 00-.624.624c0 .143.039.26.117.364l2.613 3.445c.117.143.273.286.52.286s.403-.143.52-.286L7.188.988a.581.581 0 00.117-.364A.633.633 0 006.681 0a.652.652 0 00-.507.26L4.055 3.133z"
      />
    </svg>
  )
}
export default SvgSymbolicselectArrow
