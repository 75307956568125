/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicgenderBoth = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={1.54}
        d="M15.305 10.66a6.162 6.162 0 01-6.163 6.163c-3.406 0-6.214-2.762-6.214-6.163 0-3.402 2.812-6.214 6.214-6.214 3.401 0 6.163 2.808 6.163 6.214zm-6.163 6.279v6.28M6.06 20.56h6.163"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={1.54}
        d="M21.053 10.71c0 3.405-2.812 6.213-6.214 6.213-3.402 0-6.164-2.812-6.164-6.213a6.165 6.165 0 016.164-6.164c3.405 0 6.214 2.758 6.214 6.164zM22.536.944l-3.76 4.796m-.443-4.438l4.326-.52.52 4.326"
      />
    </svg>
  )
}
export default SvgSymbolicgenderBoth
