/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccheck = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21" {...props}>
      <path
        fill="currentColor"
        d="M9.605.921C4.3.921 0 5.218 0 10.519c0 5.301 4.3 9.599 9.605 9.599 5.305 0 9.605-4.298 9.605-9.599C19.205 5.221 14.908.927 9.605.921zm5.288 6.655l-6.861 6.856a.686.686 0 01-.97 0L4.318 11.69a.685.685 0 11.97-.97l2.259 2.258 6.376-6.371a.686.686 0 01.97.97z"
      />
    </svg>
  )
}
export default SvgSymboliccheck
