/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicpills = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 48" {...props}>
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M36.342 24.717a5.285 5.285 0 100 10.57 5.285 5.285 0 000-10.57zm-8.315 5.285a8.315 8.315 0 1116.63 0 8.315 8.315 0 01-16.63 0zM15.355 14.217a5.355 5.355 0 017.483-1.152 5.355 5.355 0 011.152 7.483l-2.499 3.412-8.64-6.324 2.504-3.418zm-4.294 5.863l-2.496 3.408a5.355 5.355 0 001.153 7.483c2.381 1.743 5.726 1.226 7.484-1.155l.002-.002 2.497-3.41-8.64-6.324zm13.567-9.46c-3.734-2.732-8.982-1.929-11.717 1.808l-6.79 9.27c-2.733 3.733-1.93 8.982 1.807 11.717 3.738 2.736 8.972 1.914 11.714-1.803l.003-.004 6.79-9.27c2.733-3.734 1.93-8.982-1.807-11.718z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicpills
