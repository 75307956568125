/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicchair = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={1.6}
        d="M18.624 15.863H6.784V5.376a2.38 2.38 0 012.38-2.38h7.084a2.38 2.38 0 012.38 2.38v10.487h-.004zM6.766 8.89c-.313-.804-1.301-1.374-2.202-1.374-1.18 0-2.132.974-2.132 2.175 0 1.04.718 1.91 1.675 2.124v4.051h2.676V8.891h-.017z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={1.6}
        d="M20.84 7.512c-.9 0-1.888.57-2.201 1.375h-.018v6.97h2.672v-4.05a2.157 2.157 0 001.68-2.12c0-1.2-.953-2.175-2.133-2.175zM6.565 20.535v1.031m12.277-1.031v1.031M6.781 11.89h11.597m2.92 3.973H4.11v2.977c0 .813.661 1.475 1.475 1.475h14.238"
      />
    </svg>
  )
}
export default SvgSymbolicchair
