/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicclockAlt = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 34" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={4}
        d="M2 2h23.294M2 32h23.294m-19.1-.024V24.81c0-4.14 3.376-7.5 7.533-7.5 4.158 0 7.533 3.36 7.533 7.5v7.067m0-29.408v7.166c0 4.14-3.375 7.5-7.533 7.5-4.157 0-7.533-3.36-7.533-7.5V2.58"
      />
    </svg>
  )
}
export default SvgSymbolicclockAlt
