/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsad = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 19" {...props}>
      <path
        fill="currentColor"
        d="M35.22 18.99a1.8 1.8 0 01-.92-.26 30.38 30.38 0 00-31.61 0 1.77 1.77 0 11-1.84-3.02c5.3-3.23 11.4-4.94 17.65-4.94s12.34 1.7 17.65 4.94a1.77 1.77 0 01-.93 3.28zM10.23 5.2a2.6 2.6 0 100-5.2 2.6 2.6 0 000 5.2zm16.53 0a2.6 2.6 0 100-5.2 2.6 2.6 0 000 5.2z"
      />
    </svg>
  )
}
export default SvgSymbolicsad
