/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicnotes = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 37 42" {...props}>
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M20.235 39.459L4.51 32.654c-2.055-.89-3.03-3.314-2.144-5.432l9.5-22.703c.876-2.096 3.257-3.094 5.335-2.195l15.724 6.805c2.056.89 3.03 3.313 2.144 5.432L25.57 37.264c-.877 2.096-3.28 3.084-5.335 2.195z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M15.082 10.968l12.138 5.253m-14.246-.213l6.889 2.982"
      />
    </svg>
  )
}
export default SvgSymbolicnotes
