/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicgenderWoman = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={1.79}
        d="M18.412 8.208a7.162 7.162 0 01-7.164 7.164c-3.958 0-7.223-3.206-7.223-7.164 0-3.958 3.269-7.223 7.223-7.223 3.954 0 7.164 3.269 7.164 7.223zm-7.164 7.298v7.299m-3.582-3.09h7.164"
      />
    </svg>
  )
}
export default SvgSymbolicgenderWoman
