/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolictwitter = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
      <path
        fill="currentColor"
        d="M15.48 4.981a5.54 5.54 0 01-1.531.42c.55-.33.97-.846 1.168-1.469a5.31 5.31 0 01-1.684.643 2.656 2.656 0 00-4.596 1.817c0 .21.018.413.061.606a7.52 7.52 0 01-5.476-2.78c-.94 1.693.11 3.08.816 3.552a2.624 2.624 0 01-1.2-.327 2.68 2.68 0 002.129 2.64c-.264.087-.841.135-1.2.041a2.682 2.682 0 002.483 1.851 5.327 5.327 0 01-3.93 1.097 7.48 7.48 0 004.075 1.192c4.89 0 7.563-4.05 7.563-7.56 0-.118-.004-.231-.01-.344a5.3 5.3 0 001.332-1.379z"
      />
    </svg>
  )
}
export default SvgSymbolictwitter
