/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicdaytimeAfternoon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M19.157 19.396l-1.213-1.212M5.816 6.056L4.604 4.843M22 12.12h-1.715m-16.809 0H1.76m17.397-7.277l-1.213 1.213M5.816 18.184l-1.212 1.212m7.276 2.844v-1.716m0-16.809V2m0 15.265a5.145 5.145 0 100-10.29 5.145 5.145 0 000 10.29z"
      />
    </svg>
  )
}
export default SvgSymbolicdaytimeAfternoon
