/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicteen = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 32" {...props}>
      <path
        fill="currentColor"
        d="M19.604 32a21.86 21.86 0 01-1.714-.09c-1.407-.178-2.77-.715-4.044-1.608-1.23-.85-3.56-2.235-5.846-2.458-2.462-.18-4.396.089-5.363.312-.659.135-1.318-.044-1.846-.446A2.416 2.416 0 010 25.922c0-.76.352-1.475 1.011-1.877l6.77-4.514C8 17.25 8.966 10.86 13.538 6.525c3.12-2.95 7.252-4.335 12.307-4.112 4.352.224 7.824 1.833 10.242 4.783 4.967 6.078 3.78 15.553 3.736 15.955l-.044.134c-.22 1.028-1.362 2.503-3.516 2.28a65.295 65.295 0 00-4.22-.18C30.637 27.218 26.066 32 19.604 32zM6.33 24.67c.571 0 1.23.045 1.89.09 2.857.223 5.538 1.743 7.34 2.95.88.625 1.803.983 2.726 1.117 6.945.894 11.78-5.81 11.868-5.855l.483-.67h.792c.131 0 3.604.044 5.23.223h.176c.132-1.475.616-8.804-3.077-13.274-1.846-2.279-4.571-3.486-8.044-3.664-4.175-.224-7.56.893-10.066 3.262-4.571 4.335-4.835 11.576-4.835 11.665l-.044.804-5.055 3.352h.616z"
      />
      <path
        fill="currentColor"
        d="M31.604 25.43h-.22c-.747-.09-7.165-.938-9.187-1.564-2.33-.715-6.462-1.788-8-1.832-1.758-.09-4.88 0-4.923 0-.88.044-1.539-.67-1.583-1.52 0-.85.66-1.564 1.495-1.609.132 0 3.253-.09 5.143 0 2.154.09 7.033 1.475 8.747 1.966 1.494.448 6.813 1.207 8.703 1.475.835.09 1.45.894 1.319 1.743a1.525 1.525 0 01-1.494 1.341zm-2.68-19.71l-6.858-.223.132-2.19c.088-.983.484-1.832 1.187-2.413.703-.626 1.583-.894 2.506-.85 1.89.135 3.34 1.833 3.208 3.755l-.175 1.922z"
      />
      <path
        fill="currentColor"
        d="M14.637 21.989h-.176c-.835-.09-1.45-.894-1.363-1.743.088-.581 1.89-14.883 11.297-17.654.835-.223 1.67.224 1.89 1.073.22.849-.22 1.698-1.055 1.922-7.473 2.19-9.099 14.882-9.099 15.016-.088.805-.747 1.386-1.494 1.386z"
      />
      <path
        fill="currentColor"
        d="M28.22 24.939c-.176 0-.352-.045-.527-.09a1.56 1.56 0 01-.88-2.011c4.572-12.112-1.23-17.34-1.45-17.564a1.578 1.578 0 01-.176-2.19c.528-.67 1.495-.76 2.154-.179.308.268 7.736 6.749 2.374 21.095-.264.581-.88.939-1.495.939z"
      />
    </svg>
  )
}
export default SvgSymbolicteen
