/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicuserRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M7.894 24.59c-.659-7.888 6.849-8.153 6.849-8.153-2.503-.995-4.15-3.514-3.82-6.364.264-3.248 3.161-5.7 6.388-5.568 3.49.199 6.124 3.182 5.795 6.695-.198 2.453-1.712 4.375-3.82 5.237 0 0 3.161.132 5.203.729 3.095.928 9.021 11.6 1.514 12.064-5.86.331-9.68-2.784-7.178-4.905 2.437-2.055 5.598 7.623-4.741 7.159"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M26.003 29.23c-5.86.331-9.68-2.784-7.178-4.905 2.437-2.055 5.598 7.623-4.741 7.159"
      />
    </svg>
  )
}
export default SvgSymbolicuserRebrand
