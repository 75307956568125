import { createActions, createReducer } from 'reduxsauce'
import thunk from 'redux-thunk'
import { assocPath, mergeDeepRight } from 'ramda'
import { mergeValuesInArray } from '../utilities/reduxHelpers'
import FetchingMiddleware from '../services/FetchingMiddleware'
import type { ClientStateType } from '../types/ClientStateType'

const THERAPISTS_BEFORE_FILLED = [
  { placeholder: 1, visible: true },
  { placeholder: 2, visible: true },
  { placeholder: 3, visible: true },
  { placeholder: 4, visible: true },
  { placeholder: 5, visible: true },
  { placeholder: 6, visible: true },
]

/* ------------- Types and Action Creators ------------- */

// ENUMS
export const APPOINTMENT_STATUS = {
  RESERVED: 0,
  BEFORE_PAYMENT: 1,
  REFUND_REQUEST: 2,
  CANCELED_BY_CLIENT: 3,
  CANCELED_BY_CLIENT_BEFORE_DEADLINE: 4,
  CANCELED_BY_THERAPIST: 5,
  CANCELED_BY_ADMIN: 6,
  FREE: 7,
  COMPLETED: 8,
  PREBOOKING: 9,
  REFUSED: 10,
}

export const CELL_DURATION = 60

export const CANCELED_APPOINTMENT_STATUS = [3, 4, 5, 6]

export const CANCELED_OR_COMPLETED_APPOINTMENTS = [
  APPOINTMENT_STATUS.CANCELED_BY_CLIENT,
  APPOINTMENT_STATUS.CANCELED_BY_CLIENT_BEFORE_DEADLINE,
  APPOINTMENT_STATUS.CANCELED_BY_THERAPIST,
  APPOINTMENT_STATUS.CANCELED_BY_ADMIN,
  APPOINTMENT_STATUS.COMPLETED,
  APPOINTMENT_STATUS.REFUND_REQUEST,
]

export enum FEEDBACK_STATUS {
  happy,
  neutral,
  unhappy,
}

export const FEEDBACK_SPECIFICATIONS_ENUMS = {
  status: {
    [FEEDBACK_STATUS.happy]: 'feedbackClientHappySpec',
    [FEEDBACK_STATUS.neutral]: 'feedbackClientNeutralSpec',
    [FEEDBACK_STATUS.unhappy]: 'feedbackClientUnhappySpec',
  },
  reason: {
    '0': 'feedbackClientUnhappyMessageSpec',
    '1': 'feedbackClientUnhappyMessageSpec',
    '2': 'feedbackClientUnhappyMessageSpec',
  },
}

export const FEEDBACK_SPECIFICATIONS_EDIT_ENUMS = {
  status: {
    [FEEDBACK_STATUS.happy]: 'feedbackClientHappySpecEdit',
    [FEEDBACK_STATUS.neutral]: 'feedbackClientHappySpecEdit',
    [FEEDBACK_STATUS.unhappy]: 'feedbackClientHappySpecEdit',
  },
  reason: {
    '0': 'feedbackClientUnhappyMessageSpec',
    '1': 'feedbackClientUnhappyMessageSpec',
    '2': 'feedbackClientUnhappyMessageSpec',
  },
}

export const SHOW_THERAPISTS = {
  ALL: 0,
  ABSOLVED_THERAPIST: 1,
}

export const REQUIRED_FEEDBACK_ENUMS = ['first']

const { Types, Creators } = createActions({
  onChangeClientValue: ['path', 'value'],
  onMergeClientValue: ['value'],
  mergeClientValuesInArray: ['path', 'value'],
  updateTherapistFeedbackRequest: ['feedback'],
  getTherapistsRequest: null,
  getTherapistReviewsRequest: ['therapistId', 'therapistUserId', 'userType'],
  getTherapistDetailRequest: ['therapistId'],
  cancelPrebookedAppointmentsRequest: ['eventsId'],
})

// ======================================================
// Middleware Configuration
// ======================================================
const middleware = [thunk]

middleware.push(FetchingMiddleware as any)

export const ClientTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  feedback: {},
  diagnosisTest: false,
  recentlyVisitedTherapist: null,
  favouredTherapists: [],
  reviews: {},
  appointments: [],
  schedules: {},
  reservationModalVisible: false,
  freeTherapists: THERAPISTS_BEFORE_FILLED,
  clientCalendarDefaultDate: null,
  showTherapists: SHOW_THERAPISTS.ABSOLVED_THERAPIST,
}

/* ------------- Reducers ------------- */

export const onChangeClientValueR = (
  state: ClientStateType,
  { path, value }: { path: string; value: Object | string | boolean | number },
): ClientStateType => assocPath(path.split('.'), value, state)

export const onMergeClientValueR = (
  state: ClientStateType,
  { value }: { value: Object | boolean | number | string },
): ClientStateType => mergeDeepRight(state, value)

export const mergeClientValuesInArrayR = (
  state: ClientStateType,
  { path, value }: { path: string; value: Object | string | boolean | number },
): ClientStateType => mergeValuesInArray(state, path, value)

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_CHANGE_CLIENT_VALUE]: onChangeClientValueR,
  [Types.ON_MERGE_CLIENT_VALUE]: onMergeClientValueR,
  [Types.MERGE_CLIENT_VALUES_IN_ARRAY]: mergeClientValuesInArrayR,
  [Types.UPDATE_THERAPIST_FEEDBACK_REQUEST]: (state) => state,
  [Types.GET_THERAPISTS_REQUEST]: (state) => state,
  [Types.GET_THERAPIST_REVIEWS_REQUEST]: (state) => state,
  [Types.GET_THERAPIST_DETAIL_REQUEST]: (state) => state,
  [Types.CANCEL_PREBOOKED_APPOINTMENTS_REQUEST]: (state) => state,
} as any)
