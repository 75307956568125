/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicclientsRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        stroke="#E47B53"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M20.927 30.984c-1.27-1.24-3.015-2.685-4.707-4.492-1.692-1.806-3.384-2.839-2.538-5.782 1.005-3.562 6.504-4.078 8.196-.516 1.968 4.142-2.04 4.556-2.38 2.306-.318-2.109 4.496-6.797 8.409-3.39 4.018 3.51-2.433 8.26-4.442 10.48-2.01 2.22-2.01 1.91-2.538 1.394z"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M8.452 31.5c-.476-3.2-.952-6.814-.952-11.512s9.254-6.092 9.254-6.092c-2.01-.62-3.437-2.427-3.437-4.595 0-2.684 2.22-4.801 4.918-4.801 2.696 0 4.917 2.168 4.917 4.801 0 1.91-1.11 3.51-2.75 4.337 0 0 1.904-.465 5.077 1.239"
      />
    </svg>
  )
}
export default SvgSymbolicclientsRebrand
