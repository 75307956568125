/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsurvey = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64" {...props}>
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M56.709 36.246l-23.206-9.394a.544.544 0 00-.71.308.56.56 0 000 .411l9.282 23.485a.55.55 0 00.485.348h.024a.545.545 0 00.489-.306l3.271-6.62 5.135 5.199a.542.542 0 00.773 0l3.093-3.13a.557.557 0 000-.783l-5.136-5.199 6.54-3.309a.557.557 0 00-.04-1.008v-.002z"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M38.854 43.171A3.915 3.915 0 0135.085 46H14.916C12.752 46 11 44.256 11 42.107V15.893C11 13.743 12.754 12 14.916 12h20.168C37.246 12 39 13.744 39 15.893v12.703"
      />
    </svg>
  )
}
export default SvgSymbolicsurvey
