/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicteam = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 27 27" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={2.12}
        d="M6.206 12.661a4.007 4.007 0 118.015 0 4.007 4.007 0 11-8.015 0z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={2.12}
        d="M12.461 25.033H3.957a1.957 1.957 0 01-1.862-2.556c1.08-3.353 4.384-5.782 8.094-5.782a8.28 8.28 0 014.76 1.495m1.374-12.218a4.007 4.007 0 118.015 0 4.007 4.007 0 11-8.015 0z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={2.12}
        d="M14.252 12.619a8.31 8.31 0 016.06-2.61 8.28 8.28 0 014.76 1.495m-3.667 13.529v-7.331m3.666 3.665H17.74"
      />
    </svg>
  )
}
export default SvgSymbolicteam
