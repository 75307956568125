/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicprocrastination = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" {...props}>
      <path
        fill="currentcolor"
        d="M46.251 26.11l-4.8-10.25c-.11-.24-.24-.48-.37-.71-1.48-3.68-5.11-6.15-9.08-6.15h-16.83c-3.97 0-7.49 2.35-9.02 6-.17.28-.32.57-.46.86l-4.8 10.25c-1.08 2.29-1.18 4.98-.29 7.37.87 2.34 2.58 4.18 4.81 5.18 1.21.54 2.47.79 3.72.79 3.29 0 6.46-1.78 8.12-4.79.19.02.39.06.59.06 2.16 0 4-1.37 4.72-3.28h2.04c.72 1.91 2.56 3.28 4.72 3.28.19 0 .36-.04.55-.06 2.29 4.15 7.47 5.95 11.85 3.99 2.23-1 3.94-2.83 4.81-5.18.9-2.38.79-5.07-.28-7.36zm-28.41 5.45c-1.05 0-1.91-.86-1.91-1.91 0-1.05.86-1.91 1.91-1.91 1.05 0 1.91.86 1.91 1.91 0 1.05-.86 1.91-1.91 1.91zm9.58-1.9c0-1.05.86-1.91 1.91-1.91 1.05 0 1.91.86 1.91 1.91 0 1.05-.86 1.91-1.91 1.91-1.05 0-1.91-.86-1.91-1.91zm16.16 2.72c-.57 1.54-1.69 2.75-3.14 3.4-2.81 1.25-6.13.17-7.69-2.41 1-.93 1.64-2.24 1.64-3.71 0-2.79-2.27-5.06-5.07-5.06-2.31 0-4.25 1.57-4.85 3.69h-1.78c-.6-2.12-2.54-3.69-4.85-3.69-2.79 0-5.07 2.27-5.07 5.06 0 1.46.63 2.77 1.62 3.69-1.56 2.59-4.88 3.69-7.7 2.43-1.45-.65-2.57-1.85-3.14-3.4-.6-1.6-.53-3.4.19-4.93l4.81-10.25c.11-.23.23-.45.36-.67l.12-.23a6.603 6.603 0 016.14-4.15h16.83c2.72 0 5.2 1.71 6.18 4.25l.11.23c.11.18.21.37.3.57l4.8 10.25c.72 1.53.79 3.33.19 4.93z"
      />
    </svg>
  )
}
export default SvgSymbolicprocrastination
