/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicchatAlt = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 151 104" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={6}
        d="M83.325 58.23h-34.65l-24.75 18.813L23.7 58.23H12.675c-4.8 0-8.625-3.882-8.625-8.585V12.617c0-4.778 3.9-8.586 8.625-8.586H83.4c4.8 0 8.625 3.882 8.625 8.586v37.028c0 4.703-3.9 8.585-8.7 8.585z"
      />
      <path
        fill="currentColor"
        d="M27.692 36.753c3.06 0 5.539-2.468 5.539-5.513s-2.48-5.513-5.539-5.513c-3.058 0-5.538 2.468-5.538 5.513s2.48 5.513 5.538 5.513zm22.154 0c3.059 0 5.539-2.468 5.539-5.513s-2.48-5.513-5.539-5.513c-3.059 0-5.538 2.468-5.538 5.513s2.48 5.513 5.538 5.513zm22.154 0c3.059 0 5.538-2.468 5.538-5.513s-2.48-5.513-5.538-5.513c-3.059 0-5.539 2.468-5.539 5.513s2.48 5.513 5.539 5.513z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={6}
        d="M104 27h32.325c4.725 0 8.625 3.807 8.625 8.585v37.029c0 4.703-3.825 8.585-8.625 8.585H125.3l-.225 18.813-24.75-18.813H75"
      />
    </svg>
  )
}
export default SvgSymbolicchatAlt
