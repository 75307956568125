/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicmoneyback = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M7.867 11.767H2"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M21.73 6.941S18.942 4 16.754 4C11.65 4 8.191 7.62 8.191 7.62"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M15.596 8.222s-13.347-.454-13.469.327C1.913 9.93 2 18.2 2.17 18.706c.172.505 10.895.873 12.139-.046 1.201-.873 1.373-3.723-1.458-2.85-2.531.782-.944 4.367 2.745 4.183"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M16.505 8.295c-.646-.257-2.583 1.287-4.768 1.853-2.185.566-5.463 2.367-4.072 3.499 1.39 1.132 1.44-2.059 7.648.823 4.221 1.029 6.952-2.316 6.952-2.316"
      />
    </svg>
  )
}
export default SvgSymbolicmoneyback
