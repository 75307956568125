/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicinfoAlt = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...props}>
      <circle cx={12.76} cy={12} r={9.17} stroke="currentcolor" strokeWidth={1.67} />
      <path
        fill="currentcolor"
        d="M12.99 8a.79.79 0 01.613.26.88.88 0 01.236.63c0 .271-.09.5-.271.689a.862.862 0 01-.649.27.861.861 0 01-.637-.248.864.864 0 01-.236-.62.96.96 0 01.284-.688c.196-.195.416-.293.66-.293zm-.413 2.841c.393 0 .672.113.838.339.173.225.259.548.259.97 0 .225-.031.567-.094 1.025-.063.451-.103.74-.118.869a41.28 41.28 0 00-.095.654c-.047.33-.07.56-.07.688 0 .195.059.293.176.293.213 0 .405-.297.578-.891l.637.18c-.047.248-.15.508-.306.778-.158.27-.362.504-.614.7a1.42 1.42 0 01-.872.281c-.417 0-.712-.105-.885-.315-.165-.21-.248-.53-.248-.959 0-.338.071-.936.213-1.793.118-.736.177-1.229.177-1.477 0-.195-.063-.293-.19-.293-.204 0-.392.297-.565.89l-.637-.18c.047-.24.15-.496.307-.766.165-.278.373-.511.625-.7.26-.195.554-.293.884-.293z"
      />
    </svg>
  )
}
export default SvgSymbolicinfoAlt
