/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicdepressed = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 19" {...props}>
      <path
        fill="currentColor"
        d="M1.77 18.66a1.77 1.77 0 01-.92-3.28c5.3-3.23 11.4-4.94 17.65-4.94s12.34 1.7 17.65 4.94a1.77 1.77 0 11-1.84 3.02 30.38 30.38 0 00-31.6 0c-.3.18-.62.26-.94.26zM26.73 4.35a7.3 7.3 0 01-3.8-1.07c-.84-.5-1.1-1.6-.59-2.43.51-.84 1.6-1.1 2.43-.59 1.18.72 2.74.72 3.92 0a1.77 1.77 0 011.84 3.02 7.28 7.28 0 01-3.8 1.07zm-16.74 0a7.3 7.3 0 01-3.8-1.07A1.77 1.77 0 018.02.26c1.17.72 2.73.72 3.91 0a1.77 1.77 0 011.85 3.02 7.3 7.3 0 01-3.8 1.07z"
      />
    </svg>
  )
}
export default SvgSymbolicdepressed
