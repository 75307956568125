/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicemail = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M5.882 9.348s6.832 2.53 6.808 4.71c-.024 2.181-3.47 2.116-2.806.335.663-1.781 2.196-2.63 4.173-3.392 1.978-.762 6.855-1.923 6.127-3.743-.727-1.82-14.067-2.088-15.496-.735-1.585 1.513-2.439 12.114-.061 12.545 2.346.432 12.467 1.01 14.91-.265.939-.49.994-4.758.662-5.669"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M5.88 9.35s6.833 2.529 6.809 4.71c-.024 2.18-3.47 2.115-2.806.334.663-1.781 2.196-2.63 4.173-3.392 1.978-.762 6.855-1.923 6.127-3.743"
      />
    </svg>
  )
}
export default SvgSymbolicemail
