/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicexhaustion = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 41 40" {...props}>
      <path
        fill="currentColor"
        d="M21.384 17.15a.427.427 0 00-.453.02l-3.393 2.316c-.486.34-.504 1.073-.034 1.339l1.46.826-2.56 2.402c-.14.13-.03.353.142.288l5.763-2.181c.477-.181.58-.862.163-1.098l-1.613-.912 2.348-1.504a.27.27 0 00-.006-.46l-1.816-1.038v.001z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.4}
        d="M25.517 28.034l5.942-10.5c.827-1.464.332-3.309-1.107-4.123l-1.106-.626.992-1.753a1.101 1.101 0 00-.404-1.503l-5.109-2.891a1.101 1.101 0 00-1.497.428l-.99 1.751-1.107-.625c-1.438-.815-3.276-.29-4.103 1.174l-9.53 16.84c-.827 1.462-.332 3.308 1.107 4.122l9.22 5.217c1.44.815 3.277.289 4.104-1.174l.64-1.13M11 27l8.269 4.679"
      />
    </svg>
  )
}
export default SvgSymbolicexhaustion
