/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicarrow = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 22" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.676 12.204H2.553a1.56 1.56 0 010-3.117h18.121l-6.253-6.25A1.557 1.557 0 1116.623.635l8.864 8.86a1.557 1.557 0 01-.02 2.32l-8.85 8.846a1.555 1.555 0 11-2.196-2.201l6.255-6.257z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicarrow
