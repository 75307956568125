/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicminusSimple = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
      <path
        fill="currentColor"
        d="M3 12.73c0-.484.393-.877.877-.877h16.246a.877.877 0 010 1.755H3.877A.877.877 0 013 12.73z"
      />
    </svg>
  )
}
export default SvgSymbolicminusSimple
