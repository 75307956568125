/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicstats = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
      <g clipPath="url(#symbolic_stats_svg__symbolic_stats_svg__clip0_14500_3195)">
        <path
          fill="currentColor"
          d="M4 3.07a1 1 0 011 1v15h15a1 1 0 010 2H3v-17a1 1 0 011-1zm15.586 4A1 1 0 0121 8.482l-4.293 4.293a1 1 0 01-1.414 0l-1.94-1.938a.5.5 0 00-.707 0L9.415 14.07A1 1 0 018 12.654l4.293-4.293a1 1 0 011.414 0l1.94 1.939a.5.5 0 00.707 0l3.232-3.232z"
        />
      </g>
      <defs>
        <clipPath id="symbolic_stats_svg__symbolic_stats_svg__clip0_14500_3195">
          <path fill="#fff" d="M0 0h24v24H0z" transform="translate(0 .07)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicstats
