/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicheadPhones = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 41 37" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.33 3.58c-5.9 0-10.7 4.8-10.7 10.7v18.49c0 .27.22.5.49.5h11.79a1.58 1.58 0 010 3.15H10.12a3.65 3.65 0 01-3.65-3.65V14.28a13.86 13.86 0 0127.72-.18h3.66a2.4 2.4 0 012.4 2.4v8.38a2.4 2.4 0 01-2.4 2.4h-6.82v-13c0-5.9-4.8-10.7-10.7-10.7zm13.86 13.68v6.86h2.9v-6.86h-2.9z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.42 16.45a2.4 2.4 0 012.4-2.4h5.24v3.16H3.58v6.92h4.48v3.16H2.82a2.4 2.4 0 01-2.4-2.4v-8.44z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicheadPhones
