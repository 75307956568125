/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicfrequent = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.13}
        d="M30.26 8.012l-13.27 5.51c-.09.04-.19.04-.28 0L3.44 8.002c-.3-.12-.3-.55 0-.67l13.27-5.31c.09-.03.18-.03.27 0l13.27 5.32c.31.12.31.55.01.67z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.13}
        d="M10.25 11.222v8.91c0 .51.3.97.76 1.16.98.42 2.92 1.02 5.83 1.02 2.68 0 4.73-.63 5.79-1.05.49-.19.8-.66.8-1.18v-9M3.2 7.473v7.22m.1 4.009c-.72 0-1.3-.58-1.3-1.3v-1.4c0-.72.58-1.3 1.3-1.3s1.3.58 1.3 1.3v1.4c0 .72-.58 1.3-1.3 1.3z"
      />
    </svg>
  )
}
export default SvgSymbolicfrequent
