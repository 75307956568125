/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicplacePin = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 18" {...props}>
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M12.25 5.145c0 2.013-3.246 7.21-4.001 8.937a.175.175 0 01-.317 0c-.77-1.727-4.002-6.924-4.002-8.937A4.143 4.143 0 018.083 1c2.295 0 4.168 1.847 4.168 4.145z"
      />
      <path
        fill="currentColor"
        d="M8.083 5.836c.434 0 .785-.35.785-.78a.783.783 0 00-.785-.782.783.783 0 00-.785.781c0 .432.351.781.785.781z"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M9.05 12.79c3.805.075 6.795.841 6.795 1.787 0 .992-3.322 1.788-7.43 1.788S1 15.554 1 14.562c0-.856 2.507-1.577 5.86-1.757"
      />
    </svg>
  )
}
export default SvgSymbolicplacePin
