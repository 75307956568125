/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicfaq = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M19.517 13.775h-.322l-.055 1.675c-.028.8-1.009 1.172-1.556.588l-2.13-2.263h-3.987a3.24 3.24 0 01-3.24-3.24V6.63a3.24 3.24 0 013.24-3.24h8.053a3.24 3.24 0 013.24 3.24v3.907a3.24 3.24 0 01-3.243 3.239z"
      />
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M8.26 8.573H4.38a2.62 2.62 0 00-2.62 2.621v4.074a2.62 2.62 0 002.62 2.618h.516l.276 1.716c.074.47.64.67.995.355l2.332-2.07h3.584a2.618 2.618 0 002.62-2.619v-1.582"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={1.5}
        d="M14.08 7.534a1.642 1.642 0 011.676-1.641 1.654 1.654 0 011.608 1.555c.05.946-.705 1.73-1.642 1.73v.94m.002 1.66v.02M5.438 13.463v.02m2.055-.02v.02"
      />
    </svg>
  )
}
export default SvgSymbolicfaq
