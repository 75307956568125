/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccompleted = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 160" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M59.492 101.948h-17.99A6.499 6.499 0 0135 95.45V45.081c0-3.59 2.91-6.498 6.503-6.498H96.47a6.499 6.499 0 016.503 6.498v21.136M35 60.473h67.974M54.826 32v11.857M83.374 32v11.857"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M97.79 128.446c14.952 0 27.073-12.121 27.073-27.074 0-14.953-12.121-27.074-27.074-27.074-14.953 0-27.074 12.121-27.074 27.074 0 14.953 12.121 27.074 27.074 27.074zm11.069-37.705L86.51 113.154m22.349 0L86.51 90.741"
      />
    </svg>
  )
}
export default SvgSymboliccompleted
