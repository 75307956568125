/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicdaytimeEvening = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M15.845 15.531c-3.44 0-6.26-2.788-6.26-6.228 0-2.222 1.197-4.198 2.948-5.3L12.49 4c-4.234 0-7.73 3.464-7.73 7.698s3.496 7.698 7.73 7.698c3.374 0 6.233-2.215 7.258-5.243a6.199 6.199 0 01-3.904 1.378z"
      />
      <path
        fill="currentColor"
        d="M16.626 4.507a.2.2 0 01.375 0l.591 1.596a.2.2 0 00.118.119l1.597.59a.2.2 0 010 .376l-1.597.59a.2.2 0 00-.118.119l-.59 1.596a.2.2 0 01-.376 0l-.59-1.596a.2.2 0 00-.119-.119l-1.596-.59a.2.2 0 010-.376l1.596-.59a.2.2 0 00.118-.119l.591-1.596z"
      />
    </svg>
  )
}
export default SvgSymbolicdaytimeEvening
