/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolictests = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 48" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={2}
        d="M16.895 19.2h-7.34a2.933 2.933 0 01-2.93-2.93V8.93A2.933 2.933 0 019.555 6h7.34a2.933 2.933 0 012.93 2.93v7.34a2.933 2.933 0 01-2.93 2.93zm22.796 0h-7.339a2.933 2.933 0 01-2.93-2.93V8.93A2.933 2.933 0 0132.352 6h7.34a2.933 2.933 0 012.93 2.93v7.34a2.933 2.933 0 01-2.93 2.93zm-1.864-4.803l-3.6-3.6m3.6 0l-3.6 3.6m-17.332 27.6h-7.34a2.933 2.933 0 01-2.93-2.93v-7.34a2.933 2.933 0 012.93-2.93h7.34a2.933 2.933 0 012.93 2.93v7.34a2.933 2.933 0 01-2.93 2.93zm-1.873-4.795l-3.6-3.6m3.6 0l-3.6 3.6"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={2}
        d="M16.227 10.797l-3.995 3.6-2.005-1.796m29.464 29.396h-7.339a2.933 2.933 0 01-2.93-2.93v-7.34a2.933 2.933 0 012.93-2.93h7.34a2.933 2.933 0 012.93 2.93v7.34a2.933 2.933 0 01-2.93 2.93z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={2}
        d="M39.023 33.602l-4.003 3.6-1.997-1.796m-13.195-.609h9.6m-9.6-21.594h9.6m.697 16.297l-11-11"
      />
    </svg>
  )
}
export default SvgSymbolictests
