/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsupport = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 26" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.12}
        d="M15.39 24.09H7.46c-.77 0-1.39-.62-1.39-1.39V10.26a8.26 8.26 0 0116.52 0v7.68h3.52c.3 0 .55-.25.55-.55v-5.63c0-.3-.25-.55-.55-.55h-3.52"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2.12}
        d="M6.07 11.17H2.55c-.3 0-.55.25-.55.55v5.68c0 .3.25.55.55.55h3.52"
      />
    </svg>
  )
}
export default SvgSymbolicsupport
