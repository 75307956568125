/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicfilter = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        d="M4.17 16a3.001 3.001 0 015.66 0H19a1 1 0 110 2H9.83a3.001 3.001 0 01-5.66 0H1a1 1 0 110-2h3.17zm6-7a3.001 3.001 0 015.66 0H19a1 1 0 110 2h-3.17a3.001 3.001 0 01-5.66 0H1a1 1 0 110-2h9.17zm-6-7a3.001 3.001 0 015.66 0H19a1 1 0 110 2H9.83a3.001 3.001 0 01-5.66 0H1a1 1 0 010-2h3.17zM7 4a1 1 0 100-2 1 1 0 000 2zm6 7a1 1 0 100-2 1 1 0 000 2zm-6 7a1 1 0 100-2 1 1 0 000 2z"
      />
    </svg>
  )
}
export default SvgSymbolicfilter
