/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicteenRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M20.513 7.601C9.867 7.027 9.577 20.036 9.577 20.036l-4.874 3.717a.609.609 0 00-.203.44c0 .337.261.574.522.506.696-.169 2.06-.405 3.8-.236 1.712.169 3.424 1.318 4.352 2.061.667.54 1.421.912 2.234 1.014 0 0 8.006 1.52 6.904-4.968"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M9.577 20.036s2.088-.067 3.307 0c1.218.068 4.235 1.014 5.511 1.453 1.306.44 3.743 1.115 9.138 1.284 1.045.034 1.219-.81 1.219-.81s.522-4.664-.958-8.685c0 0-2.03-5.71-5.105-2.027-3.481 4.19 4.67 7.738 8.558 1.926M14.48 20.002S14.652 9.426 20.31 7.5"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M27.533 22.773c1.044.034 1.219-.81 1.219-.81s.522-4.664-.958-8.685c0 0-2.03-5.71-5.105-2.027-3.481 4.19 4.67 7.738 8.557 1.926"
      />
    </svg>
  )
}
export default SvgSymbolicteenRebrand
