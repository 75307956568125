/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliclogoHedepyPlus = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 55" {...props}>
      <path
        fill="#C5E7E8"
        d="M22.985 47C36.045 47 46 40.654 46 26.79 46 14.76 37.05 7 22.772 7 7.946 7 0 14.76 0 26.79 0 40.203 10.26 47 22.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M29.985 47C43.045 47 53 40.654 53 26.79 53 14.76 44.05 7 29.772 7 14.946 7 7 14.76 7 26.79 7 40.203 17.26 47 29.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M36.985 47C50.045 47 60 40.654 60 26.79 60 14.76 51.05 7 36.772 7 21.946 7 14 14.76 14 26.79 14 40.203 24.26 47 36.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M43.985 47C57.045 47 67 40.654 67 26.79 67 14.76 58.05 7 43.772 7 28.946 7 21 14.76 21 26.79 21 40.203 31.26 47 43.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M50.985 47C64.045 47 74 40.654 74 26.79 74 14.76 65.05 7 50.772 7 35.946 7 28 14.76 28 26.79 28 40.203 38.26 47 50.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M57.985 47C71.045 47 81 40.654 81 26.79 81 14.76 72.05 7 57.772 7 42.946 7 35 14.76 35 26.79 35 40.203 45.26 47 57.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M64.985 47C78.045 47 88 40.654 88 26.79 88 14.76 79.05 7 64.772 7 49.946 7 42 14.76 42 26.79 42 40.203 52.26 47 64.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M71.985 47C85.045 47 95 40.654 95 26.79 95 14.76 86.05 7 71.772 7 56.946 7 49 14.76 49 26.79 49 40.203 59.26 47 71.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M78.985 47C92.045 47 102 40.654 102 26.79 102 14.76 93.05 7 78.772 7 63.946 7 56 14.76 56 26.79 56 40.203 66.26 47 78.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M85.985 47C99.045 47 109 40.654 109 26.79 109 14.76 100.05 7 85.772 7 70.946 7 63 14.76 63 26.79 63 40.203 73.26 47 85.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M92.985 47C106.045 47 116 40.654 116 26.79 116 14.76 107.05 7 92.772 7 77.946 7 70 14.76 70 26.79 70 40.203 80.26 47 92.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M99.985 47C113.045 47 123 40.654 123 26.79 123 14.76 114.05 7 99.772 7 84.946 7 77 14.76 77 26.79 77 40.203 87.26 47 99.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M106.985 47C120.045 47 130 40.654 130 26.79 130 14.76 121.05 7 106.772 7 91.946 7 84 14.76 84 26.79 84 40.203 94.26 47 106.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M113.985 47C127.045 47 137 40.654 137 26.79 137 14.76 128.05 7 113.772 7 98.946 7 91 14.76 91 26.79 91 40.203 101.259 47 113.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M120.985 47C134.045 47 144 40.654 144 26.79 144 14.76 135.05 7 120.772 7 105.946 7 98 14.76 98 26.79 98 40.203 108.259 47 120.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M127.985 47C141.045 47 151 40.654 151 26.79 151 14.76 142.05 7 127.772 7 112.946 7 105 14.76 105 26.79 105 40.203 115.259 47 127.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M134.985 47C148.045 47 158 40.654 158 26.79 158 14.76 149.05 7 134.772 7 119.946 7 112 14.76 112 26.79 112 40.203 122.259 47 134.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M141.985 47C155.045 47 165 40.654 165 26.79 165 14.76 156.05 7 141.772 7 126.946 7 119 14.76 119 26.79 119 40.203 129.259 47 141.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M148.985 47C162.045 47 172 40.654 172 26.79 172 14.76 163.05 7 148.772 7 133.946 7 126 14.76 126 26.79 126 40.203 136.259 47 148.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M155.985 47C169.045 47 179 40.654 179 26.79 179 14.76 170.05 7 155.772 7 140.946 7 133 14.76 133 26.79 133 40.203 143.259 47 155.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M162.985 47C176.045 47 186 40.654 186 26.79 186 14.76 177.05 7 162.772 7 147.946 7 140 14.76 140 26.79 140 40.203 150.259 47 162.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M169.985 47C183.045 47 193 40.654 193 26.79 193 14.76 184.05 7 169.772 7 154.946 7 147 14.76 147 26.79 147 40.203 157.259 47 169.985 47z"
      />
      <path
        fill="#C5E7E8"
        d="M176.985 47C190.045 47 200 40.654 200 26.79 200 14.76 191.05 7 176.772 7 161.946 7 154 14.76 154 26.79 154 40.203 164.259 47 176.985 47z"
      />
      <path
        fill="#0B4752"
        d="M46.376 38.487c-7.551 0-11.286-4.872-11.286-11.205 0-6.251 4.14-11.61 11.123-11.61 6.902 0 10.312 4.953 10.312 11.204v1.218H38.743c.325 4.385 2.842 7.227 7.551 7.227 3.33 0 5.278-1.462 6.577-3.735l3.167 1.786c-1.705 2.76-4.71 5.115-9.662 5.115zm-7.551-12.828H52.79c-.244-3.979-2.355-6.82-6.577-6.82-4.303 0-6.901 2.679-7.388 6.82zm31.665-9.826c3.816 0 6.414 1.786 7.876 5.034V6.82h3.816v31.26h-3.735v-5.034c-1.543 3.49-4.71 5.358-8.282 5.358-5.196 0-9.743-3.653-9.743-11.204s4.953-11.448 10.068-11.367zm8.2 11.286c0-4.628-2.273-8.201-7.226-8.201-4.303 0-7.145 3.167-7.145 8.282 0 5.277 2.76 8.119 7.145 8.119 4.79-.081 7.227-3.735 7.227-8.2zM98.5 38.487c-7.55 0-11.285-4.872-11.285-11.205 0-6.251 4.14-11.61 11.123-11.61 6.902 0 10.312 4.953 10.312 11.204v1.218H90.868c.325 4.385 2.842 7.227 7.551 7.227 3.329 0 5.278-1.462 6.577-3.735l3.167 1.786c-1.705 2.76-4.71 5.115-9.662 5.115zm-7.55-12.828h13.965c-.244-3.979-2.355-6.82-6.577-6.82-4.303 0-6.901 2.679-7.388 6.82zM125.377 38.4c-3.897 0-6.658-1.949-8.12-5.197V46.6h-3.816V16.153h3.816v5.034c1.624-3.573 4.791-5.359 8.444-5.359 5.034 0 9.581 3.573 9.581 11.205-.081 7.307-4.547 11.367-9.905 11.367zm5.927-11.367c0-5.278-2.842-8.12-7.145-8.12-4.791 0-7.307 3.654-7.307 8.2 0 4.629 2.435 8.12 7.388 8.12 4.466 0 7.064-3.085 7.064-8.2zM22.344 15.67c-3.816 0-6.74 2.111-8.282 6.09v-3.33c0-3.003.163-6.089.893-8.768.731-2.76 2.355-4.79 4.547-6.658L16.985 0c-5.44 4.303-6.576 10.068-6.739 16.4v21.517h3.816V27.362c0-5.602 2.842-8.688 6.74-8.688 4.384 0 5.764 2.842 5.764 6.983v12.26H30.3V24.764c.08-5.278-2.517-9.094-7.957-9.094zm126.091 23.624c3.816 0 6.739-2.111 8.282-6.09v3.33c0 3.003-.162 6.089-.893 8.768-.731 2.76-2.355 4.79-4.547 6.658l2.517 3.004c5.44-4.303 6.577-10.068 6.739-16.401V17.046h-3.816v10.556c0 5.602-2.842 8.688-6.739 8.688-4.384 0-5.765-2.842-5.765-6.983v-12.26h-3.735V30.2c-.081 5.278 2.517 9.094 7.957 9.094zm19.811-14.609h18.999v3.248h-18.999v-3.248zm7.876-7.794h3.248V35.89h-3.248v-19z"
      />
    </svg>
  )
}
export default SvgSymboliclogoHedepyPlus
