/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicmailStroke = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 17" {...props}>
      <path
        stroke="currentcolor"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M15.234 15.37H3.531C2.127 15.37 1 14.206 1 12.78V3.59C1 2.155 2.137 1 3.53 1h11.704c1.404 0 2.53 1.164 2.53 2.59v9.179c.01 1.437-1.126 2.6-2.53 2.6z"
      />
      <path
        stroke="currentcolor"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M1 1.798l6.617 5.039c.983.753 2.251.73 3.215-.056l6.134-4.983"
      />
    </svg>
  )
}
export default SvgSymbolicmailStroke
