/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicinternet = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 14.166A6.163 6.163 0 011.833 8 6.163 6.163 0 018 1.836 6.163 6.163 0 0114.164 8 6.163 6.163 0 018 14.166zM.663 8A7.333 7.333 0 008 15.336 7.333 7.333 0 0015.334 8 7.333 7.333 0 008 .666 7.333 7.333 0 00.664 8z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.189 10.115H1.812v1.17H14.19v-1.17zm0-5.402H1.812v1.17H14.19v-1.17z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.634 12.557c-.455-1.136-.75-2.747-.75-4.556 0-1.81.295-3.42.75-4.556.228-.57.484-.992.74-1.262.254-.268.465-.347.626-.347.16 0 .372.079.626.347.255.27.512.692.74 1.262.455 1.136.749 2.747.749 4.556 0 1.81-.294 3.42-.749 4.556-.228.57-.485.992-.74 1.262-.254.268-.466.347-.626.347-.16 0-.372-.079-.626-.347-.256-.27-.512-.692-.74-1.262zm-.11 2.066c.392.414.89.713 1.476.713.585 0 1.084-.299 1.476-.713.39-.413.716-.98.976-1.631.523-1.307.833-3.071.833-4.991 0-1.92-.31-3.685-.833-4.99-.26-.652-.585-1.219-.976-1.632C9.084.965 8.586.666 8 .666s-1.084.299-1.476.713c-.391.413-.716.98-.977 1.631-.522 1.306-.832 3.07-.832 4.99 0 1.92.31 3.685.832 4.992.26.651.586 1.218.977 1.63z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicinternet
