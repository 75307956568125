/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicmarks = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 29 25" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.893 16.277L3.272 19.49l2.621 3.213h15.043v-6.426H5.893zM4.44 14.682a1.417 1.417 0 011.113-.539h16.08c.77 0 1.436.619 1.436 1.437v7.83c0 .827-.686 1.427-1.436 1.427H5.553c-.448 0-.849-.208-1.113-.54L1.26 20.4a1.429 1.429 0 010-1.818l3.18-3.899zM7.59 3.067v6.426h15.982l2.621-3.213-2.621-3.213H7.589zM5.455 2.36c0-.78.637-1.427 1.436-1.427h17.02c.448 0 .849.208 1.113.539l3.18 3.899a1.429 1.429 0 010 1.818l-3.18 3.899a1.417 1.417 0 01-1.112.539H6.893c-.77 0-1.437-.619-1.437-1.437V2.36z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.282 15v-4.22h1.92V15h-1.92zm4.83-.05v-4.22h1.92v4.22h-1.92z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicmarks
