/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicadhd = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.4}
        d="M32.3 24.386a5.752 5.752 0 01-1.23-3.554c0-.412.03-.822.09-1.23.139-.959.16-1.956.05-2.977H8.071c-.047.425-.071.857-.071 1.294 0 2.79.982 5.352 2.62 7.357 1.259 1.541 1.96 3.47 1.885 5.46a9.04 9.04 0 01-.758 3.327l12.386 2.485c-.143-2.26.792-3.273.792-3.273h2.266A3.483 3.483 0 0030.6 30.5l.554-2.665c1.348-1.029 2.711-1.751 1.145-3.449zM12.606 7.86H8v4.621M8 7.86l4.765 4.765M28.83 7.86h4.606v4.621m.001-4.621l-4.766 4.765M17.437 7.257L20.694 4l3.268 3.268M20.693 4v6.739"
      />
    </svg>
  )
}
export default SvgSymbolicadhd
