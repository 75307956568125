/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliclock = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 14" {...props}>
      <path
        fill="currentColor"
        d="M10.958 5.25h-.875V4.083C10.083 1.832 8.251 0 6 0S1.916 1.832 1.916 4.083V5.25h-.875a.292.292 0 00-.291.292v7.291c0 .644.523 1.167 1.166 1.167h8.167c.644 0 1.167-.523 1.167-1.167V5.542a.292.292 0 00-.292-.292zm-4.085 6.093a.293.293 0 01-.29.324H5.416a.292.292 0 01-.29-.324l.184-1.655a1.154 1.154 0 01-.477-.938 1.168 1.168 0 012.333 0c0 .377-.178.72-.477.938l.184 1.655zm1.46-6.093H3.666V4.083C3.666 2.797 4.713 1.75 6 1.75s2.333 1.047 2.333 2.333V5.25z"
      />
    </svg>
  )
}
export default SvgSymboliclock
