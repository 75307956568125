/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicupbenefity = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 18" {...props}>
      <path fill="#F59100" d="M17.02.786H0v17.143h17.02V.785z" />
      <path
        fill="#fff"
        d="M6.757 9.334c0 .886-.192 1.563-1.177 1.563-1.102 0-1.22-.796-1.22-1.452V4.387l-1.412.315v4.956c0 1.887 1.304 2.608 2.567 2.608 1.648 0 2.654-1.067 2.654-2.402V4.387l-1.412.315v4.632zm4.975-2.881a2.629 2.629 0 00-1.528.484v-.81l-1.361.333v7.574l1.357-.315v-1.935c.45.315.984.484 1.532.484a2.82 2.82 0 001.982-.874 2.86 2.86 0 00.789-2.029 2.864 2.864 0 00-.789-2.03 2.827 2.827 0 00-1.982-.872m-.087 4.562a1.586 1.586 0 01-1.131-.498 1.61 1.61 0 01-.442-1.162 1.598 1.598 0 01.429-1.178 1.575 1.575 0 011.144-.496 1.566 1.566 0 011.145.496 1.59 1.59 0 01.428 1.178 1.62 1.62 0 01-.44 1.162 1.596 1.596 0 01-1.133.498z"
      />
    </svg>
  )
}
export default SvgSymbolicupbenefity
