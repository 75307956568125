/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicbalance = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 18" {...props}>
      <path
        fill="currentColor"
        d="M14.45 0c4.88 0 9.64 1.33 13.79 3.86a1.38 1.38 0 11-1.44 2.36 23.74 23.74 0 00-24.7 0A1.38 1.38 0 11.67 3.86 26.4 26.4 0 0114.47 0zM25.5 18c-4.88 0-9.65-1.33-13.8-3.86a1.38 1.38 0 111.44-2.36 23.74 23.74 0 0024.7 0 1.38 1.38 0 111.44 2.36A26.47 26.47 0 0125.48 18z"
      />
    </svg>
  )
}
export default SvgSymbolicbalance
