/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicrest = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 160" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M70.007 43.308c-.9-.078-1.826-.13-2.753-.13-20.25-.154-36.72 16.234-36.72 36.487 0 1.494.104 2.989.258 4.432L23.64 94.249a3.447 3.447 0 001.21 5.05l6.046 2.937 2.599 12.626a8.921 8.921 0 008.98 7.266l8.878-.206a4.658 4.658 0 014.683 3.814l1.235 6.596m34.661 0l-1.853-6.261a24.41 24.41 0 01.953-17.779 12.42 12.42 0 012.29-3.376 36.447 36.447 0 0010.112-25.174c.026-13.811-7.745-25.922-19.119-32.131M38.627 84.393c3.409 2.251 6.678 2.207 10.064-.052"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M70.857 101.078c7.674 0 13.895-6.23 13.895-13.914 0-7.684-6.221-13.914-13.895-13.914-7.675 0-13.896 6.23-13.896 13.914 0 7.685 6.221 13.914 13.896 13.914z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M65.35 74.332l5.043-33.007c.232-1.52 1.647-2.577 3.165-2.345l8.62 1.314a2.746 2.746 0 012.317 3.144l-4.941 32.62"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={4.7}
        d="M104.492 47.394a4.589 4.589 0 004.586-4.592 4.589 4.589 0 00-4.586-4.592 4.588 4.588 0 00-4.586 4.592 4.588 4.588 0 004.586 4.592zm16.329 5.366a4.589 4.589 0 004.585-4.591 4.589 4.589 0 00-4.585-4.592 4.589 4.589 0 00-4.586 4.592 4.589 4.589 0 004.586 4.592z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={4.7}
        d="M109.078 42.647V24l16.297 4.964v19.05M109.078 32.41l16.174 5.056m-.279 45.919a4.589 4.589 0 004.585-4.592 4.589 4.589 0 00-4.585-4.592 4.589 4.589 0 00-4.586 4.592 4.589 4.589 0 004.586 4.592z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={4.7}
        d="M129.558 78.638V59.99L140 63.373M129.558 68.4l10.318 3.475"
      />
    </svg>
  )
}
export default SvgSymbolicrest
