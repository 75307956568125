/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicbacktime = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 37 36" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M18.162 10.396c0 1.262.036.685.072 4.577.108 8.937-5.261 8.18-4.505 4.613.793-3.64 12.253-1.658 12.253-1.658"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M30.668 17.892c0 6.63-5.37 12-12 12s-12-5.333-12-11.964a12 12 0 016.75-10.795"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M20.11 6c2.846.324 5.549 1.694 7.603 4 .54.613.973 1.225 1.37 1.91"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M30.668 10.435c-.22 1.071-.878 3-.878 3s-1.976-.322-3.622-.965"
      />
    </svg>
  )
}
export default SvgSymbolicbacktime
