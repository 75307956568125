/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicpauseBgWhite = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <circle cx={18} cy={18} r={18} fill="#fff" />
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.02}
        d="M15.103 13.52v9.46m6.034-9.46v9.46"
      />
    </svg>
  )
}
export default SvgSymbolicpauseBgWhite
