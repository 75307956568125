/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicunexpected = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 160" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M52.861 63.048l-27.368 70.531c-.313.808.48 1.605 1.289 1.296l71.38-27.237M75.094 85.135l34.393-34.461m-43.108 7.518l2.975-2.977a8.26 8.26 0 000-11.696m18.527-5.273c.313 9.92-3.497 19.476-10.542 26.525l-6.107 6.109"
      />
      <path
        fill="currentColor"
        d="M86.994 29.37a2.34 2.34 0 01-2.349-2.35 2.34 2.34 0 012.35-2.35 2.34 2.34 0 012.348 2.35 2.34 2.34 0 01-2.349 2.35zm-27.191 9.608a2.34 2.34 0 01-2.349-2.35 2.34 2.34 0 012.349-2.35 2.34 2.34 0 012.348 2.35 2.34 2.34 0 01-2.348 2.35z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M124.831 44.303c4.871 0 8.82-3.95 8.82-8.824 0-4.873-3.949-8.824-8.82-8.824s-8.82 3.951-8.82 8.824c0 4.874 3.949 8.825 8.82 8.825zm-22.389 49.76l3.027-3.029a8.25 8.25 0 0111.691 0m5.271-18.534c-9.916-.314-19.467 3.498-26.513 10.546L89.03 89.94"
      />
      <path
        fill="currentColor"
        d="M133.652 75.736a2.34 2.34 0 01-2.348-2.35 2.34 2.34 0 012.348-2.349 2.34 2.34 0 012.349 2.35 2.34 2.34 0 01-2.349 2.35zm-5.794 23.079a2.34 2.34 0 01-2.348-2.35 2.34 2.34 0 012.348-2.349 2.34 2.34 0 012.349 2.35 2.34 2.34 0 01-2.349 2.35z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M100.844 105.961c5.91-5.913.392-21.021-12.326-33.745-12.718-12.723-27.82-18.244-33.73-12.331-5.91 5.913-.392 21.021 12.326 33.745 12.718 12.724 27.82 18.245 33.73 12.331zm-28.151 11.442c-5.74-2.402-12.056-6.683-17.901-12.584-5.323-5.325-9.29-11.017-11.795-16.342"
      />
    </svg>
  )
}
export default SvgSymbolicunexpected
