/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccertificate = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 29 31" {...props}>
      <g stroke="currentColor" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={2.62}>
        <path d="M15 17.92A7.96 7.96 0 1015 2a7.96 7.96 0 000 15.92z" />
        <path d="M14.84 18.03L10.6 28.47a.85.85 0 01-1.4.26l-2.15-2.31a.87.87 0 00-.63-.27l-3.57.06a.84.84 0 01-.79-1.16L7.1 13.52" />
        <path d="M22.58 13.98l4.78 11.32a.84.84 0 01-.85 1.14l-3.15-.23a.83.83 0 00-.64.23l-2.57 2.47a.84.84 0 01-1.37-.31l-4-10.58" />
      </g>
    </svg>
  )
}
export default SvgSymboliccertificate
