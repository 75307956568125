/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicmobilePhone = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 61 61" {...props}>
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={4.53}
        d="M24.608 54.724L9.554 44.958a3.412 3.412 0 01-1.005-4.718L30.23 6.827a3.412 3.412 0 014.718-1.005l15.054 9.766a3.412 3.412 0 011.005 4.717L29.326 53.72a3.413 3.413 0 01-4.718 1.005z"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={4.07}
        d="M46.218 15.248l-3.002 2.726a.205.205 0 01-.253.018l-6.846-4.441a.208.208 0 01-.086-.24l1.544-3.944"
      />
      <path stroke="currentColor" strokeMiterlimit={10} strokeWidth={4.53} d="M13.086 33.259L33.75 46.665" />
    </svg>
  )
}
export default SvgSymbolicmobilePhone
