import React from 'react'
import Icons from '../../icons'
import { capitalize, textToCamelCase } from '../../utilities/textUtilities'
import { bm } from '../../utilities/bliss'
import { IconBaseType } from '../../types/ComponentTypes'

export type IconPropsType = IconBaseType & {
  className?: string
  width?: number
  height?: number
}

const Icon: React.FC<IconPropsType> = ({
  className = '',
  color = null,
  icon = '',
  size = 'sm',
  svgType = 'symbolic',
  ...props
}) => {
  const nameCamelCase = `${capitalize(svgType || '')}${textToCamelCase(icon || '')}`

  if (!icon || !Icons || !Icons[nameCamelCase]) {
    return null
  }

  const Svg = Icons[nameCamelCase]

  const classes = className ? className.split(' ') : []

  if (svgType === 'symbolic' && color) {
    classes.push(`text-${color}`)
  }

  return <Svg {...props} className={bm('icon', size, classes.join(' '))} />
}

export default Icon
