/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsubscription = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 35" {...props}>
      <g fill="currentColor">
        <path d="M7.92 8.956c2.455 0 4.446-2.005 4.446-4.478S10.376 0 7.919 0C5.463 0 3.473 2.005 3.473 4.478s1.99 4.478 4.446 4.478zm12.798 0c2.456 0 4.447-2.005 4.447-4.478S23.174 0 20.718 0 16.27 2.005 16.27 4.478s1.991 4.478 4.447 4.478zM.817 16.745c1.723-1.294 3.961-.862 4.056-.837a.76.76 0 01.326.152l8.737 7.13c1.492-1.155 6.024-4.732 8.194-7.295.625-.735.882-1.471.787-2.232-.19-1.523-1.75-2.652-1.763-2.664a.76.76 0 00-.326-.152c-1.927-.318-4.016.05-5.915 1.42a1.677 1.677 0 01-1.954 0C7.695 8.474 1.115 12.23.003 17.164c-.027.127.149.203.23.101.177-.19.38-.367.584-.52z" />
        <path d="M23.908 11.976c.244.444.42.951.502 1.496.149 1.142-.217 2.259-1.1 3.286-2.726 3.197-8.655 7.688-8.913 7.878a.78.78 0 01-.475.152.765.765 0 01-.488-.177l-9.049-7.384c-.448-.05-1.696-.126-2.632.597C.898 18.47.464 19.65.464 21.3v.025c.407.875 1.031 1.738 1.9 2.55L13.08 34.658a1.28 1.28 0 001.723 0l10.718-10.783c4.41-4.123 2.36-9.49-1.614-11.9z" />
      </g>
    </svg>
  )
}
export default SvgSymbolicsubscription
