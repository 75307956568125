/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicdepression = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.6}
        d="M27.398 12.068c-.195 0-.384.013-.574.03a8.125 8.125 0 00-7.455-4.836c-4.24 0-7.72 3.208-8.087 7.3-2.216.03-4.002 1.81-4.002 4 0 2.192 1.817 4.004 4.059 4.004a4.08 4.08 0 002.835-1.141 8.154 8.154 0 005.193 1.853c1.87 0 3.593-.626 4.965-1.674a5.347 5.347 0 003.063.962c2.94 0 5.323-2.35 5.323-5.25s-2.383-5.25-5.323-5.25l.003.002zM16.83 25.693a.23.23 0 00-.406 0c-1.052 2.042-1.853 2.348-1.853 3.405 0 1.126.918 2.04 2.057 2.05 1.14-.013 2.058-.924 2.058-2.05 0-1.057-.801-1.363-1.853-3.405h-.002zm6.92 1.714a.23.23 0 00-.406 0c-1.052 2.042-1.853 2.348-1.853 3.405 0 1.126.919 2.04 2.057 2.05 1.14-.013 2.058-.924 2.058-2.05 0-1.057-.801-1.363-1.853-3.405h-.003z"
      />
    </svg>
  )
}
export default SvgSymbolicdepression
