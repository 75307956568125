/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicpaypal = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#symbolic_paypal_svg__symbolic_paypal_svg__clip0_8382_107834)">
        <mask
          id="symbolic_paypal_svg__a"
          style={{
            maskType: 'luminance',
          }}
          width={24}
          height={24}
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M0 0h24v24H0V0z" />
        </mask>
        <g mask="url(#symbolic_paypal_svg__a)">
          <path
            fill="#001C64"
            d="M18.448 11.175c-.357 2.032-2.014 3.567-4.189 3.567h-1.38c-.282 0-.565.26-.61.549l-.605 3.837c-.035.219-.137.291-.359.291h-2.22c-.225 0-.278-.075-.244-.296l.255-2.654-2.666-.138c-.224 0-.305-.122-.273-.346L7.975 4.454c.036-.219.172-.313.392-.313h4.612c2.22 0 3.625 1.495 3.797 3.433 1.321.892 1.939 2.08 1.672 3.601z"
          />
          <path
            fill="#0070E0"
            d="M9.772 12.172l-.676 4.297-.427 2.696a.366.366 0 00.36.422h2.345a.45.45 0 00.444-.38l.617-3.917a.45.45 0 01.444-.38h1.38a4.413 4.413 0 004.355-3.735c.24-1.525-.528-2.914-1.842-3.6a3.521 3.521 0 01-.042.486 4.413 4.413 0 01-4.355 3.732h-2.16a.45.45 0 00-.443.38z"
          />
          <path
            fill="#003087"
            d="M9.096 16.47H6.365a.368.368 0 01-.36-.423L7.844 4.38A.45.45 0 018.287 4h4.69c2.22 0 3.834 1.616 3.796 3.573a4.096 4.096 0 00-1.912-.456h-3.91a.45.45 0 00-.444.38l-.736 4.675-.676 4.297z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="symbolic_paypal_svg__symbolic_paypal_svg__clip0_8382_107834">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicpaypal
