/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolictrusted = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.92}
        d="M9.96 14.25l1.51 1.51c.11.11.3.1.4-.03L16.24 10"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.13}
        d="M13.1 24.2c6.13 0 11.1-4.97 11.1-11.1S19.23 2 13.1 2 2 6.97 2 13.1s4.97 11.1 11.1 11.1z"
      />
    </svg>
  )
}
export default SvgSymbolictrusted
