/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicquoteNoPadding = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 37 29" {...props}>
      <path
        fill="currentColor"
        d="M0 18.142C0 11.15 3.57 5.096 9.25.832l5.194 4.434c-2.597 1.365-6.005 5.031-6.816 8.1a7.608 7.608 0 011.866-.255c3.408 0 6.248 2.814 6.248 6.992 0 4.348-3.408 7.93-7.384 7.93C3.976 28.032 0 24.365 0 18.142zm20.524 0c0-6.992 3.57-13.046 9.251-17.31l5.193 4.434c-2.596 1.365-5.923 5.031-6.816 8.1a7.608 7.608 0 011.866-.255c3.49 0 6.249 2.814 6.249 6.992 0 4.348-3.408 7.93-7.385 7.93-4.381 0-8.358-3.667-8.358-9.891z"
      />
    </svg>
  )
}
export default SvgSymbolicquoteNoPadding
