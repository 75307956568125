/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicnavLeft = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 120 120" {...props}>
      <g clipPath="url(#symbolic_nav-left_svg__symbolic_nav-left_svg__clip0_16593_148099)">
        <path
          fill="currentcolor"
          d="M19.708 11.22s67.183-6.244 67.88.347c.696 6.591-1.045 76.665-3.481 77.012-2.785.347-68.576 5.898-71.71.347-3.132-5.204 7.31-77.706 7.31-77.706z"
        />
        <path
          stroke="currentcolor"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={3}
          d="M94.5 103s-4.127-14.767-12.482-16.154m18.146-65.568S88.63 11.22 84.801 11.22M13.5 86.5s4 11.5 6.61 20.944"
        />
        <path
          stroke="currentcolor"
          strokeLinecap="round"
          strokeWidth={3}
          d="M18.833 112.479c23.233-.424 47.714.65 70.157-6.567 2.95-.949 4.34-1.336 5.42-3.805 1.484-3.39 1.809-7.16 2.346-10.79 1.876-12.662 3.635-25.338 5.16-38.048 1.291-10.76 3.232-21.21 3.232-32.055 0-1.92.166-2.887.938-4.43"
        />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={4}
          d="M48 37c-5.17 2.356-11.487 9.49-14 12.764 1.436 3.6 4.846 11.487 7 14.236m-5-14c9.167-1 25.9-2.4 29.5 0"
        />
      </g>
      <defs>
        <clipPath id="symbolic_nav-left_svg__symbolic_nav-left_svg__clip0_16593_148099">
          <path fill="#fff" d="M0 0h110v105.11H0z" transform="matrix(-1 0 0 1 113 8.445)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicnavLeft
