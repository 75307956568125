/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolichomeRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M7 28.492V17.208c0-.745.296-1.457.855-1.931l8.451-7.658c.921-.847 2.302-.814 3.223.034l8.583 7.963c.526.474.822 1.185.822 1.897L29 29"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M14.746 17.003c6.624-.188 5.354 7.33 2.707 5.101-1.923-3.872 4.38-5.825 7.087-2.896C27.246 22.136 17.132 29 17.132 29s-4.452-3.054-5.734-6.234c-.64-1.512-1.068-5.636 3.348-5.762z"
      />
    </svg>
  )
}
export default SvgSymbolichomeRebrand
