/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsharescreen = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M20.32 17.82H3.68c-.928 0-1.68-.772-1.68-1.72V5.722C2 4.772 2.753 4 3.68 4h16.64c.927 0 1.68.772 1.68 1.722v10.377c0 .95-.753 1.721-1.68 1.721z"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="bevel"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M11.914 14.21V7.219m-2.803 3.038l2.803-3.087 2.86 2.933m-8.315 7.86v1.365c0 .724.567 1.306 1.274 1.306h8.43c.707 0 1.275-.582 1.275-1.306v-1.425"
      />
    </svg>
  )
}
export default SvgSymbolicsharescreen
