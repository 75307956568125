/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccoupleRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        stroke="#E47B53"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M20.9 31.36c-.91-1.234-2.139-2.69-3.367-4.409-1.229-1.72-2.457-2.866-1.32-5.247 1.365-2.867 6.142-2.514 7.006.661.865 3.175-2.001 2.999-1.956 1.5.046-1.368 4.413-4.94 7.234-1.544 2.912 3.528-3.276 6.614-5.323 8.202-2.047 1.587-1.91 1.367-2.275.838z"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M9.526 31.216c-.715-2.878-1.533-6.175-1.994-10.519-.46-4.344 4.142-6.228 4.142-6.228a4.47 4.47 0 01-3.477-3.925c-.256-2.46 1.432-4.658 3.835-4.92 2.403-.261 4.55 1.518 4.806 3.978a4.42 4.42 0 01-1.994 4.24s1.636-.629 4.55.627"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M17.093 14.783c2.608-1.884 4.295-1.622 4.295-1.622-1.482-.576-2.658-1.989-2.812-3.716-.255-2.46 1.483-4.658 3.886-4.92 2.403-.261 4.55 1.518 4.806 3.978.205 1.989-.869 3.82-2.607 4.605 0 0 3.016.42 4.601 2.67"
      />
    </svg>
  )
}
export default SvgSymboliccoupleRebrand
