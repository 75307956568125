/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicdenied = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={3.16}
        d="M13 24c6.075 0 11-4.925 11-11S19.075 2 13 2 2 6.925 2 13s4.925 11 11 11zM5.887 4.718l13.802 17.014"
      />
    </svg>
  )
}
export default SvgSymbolicdenied
