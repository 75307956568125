/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccircleCross = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12zM8.73 16.725l3.361-3.36 3.377 3.376a.889.889 0 001.256-1.257l-3.376-3.377 3.392-3.392a.889.889 0 00-1.257-1.256l-3.392 3.392-3.376-3.376A.889.889 0 107.458 8.73l3.376 3.376-3.36 3.361a.889.889 0 101.256 1.257z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymboliccircleCross
