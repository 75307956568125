/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicrelax = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 160" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={5}
        d="M122.33 69.558l-5.351 2.212M88.788 90.907H128m-95.955 0h39.212m8.765-54.99v18.395m17.525-5.684l-2.214 5.348m23.775-2.127l-13.194 13.185M62.498 48.628l2.214 5.348M37.669 73.397l5.35 2.213M40.89 51.849l13.195 13.185m53.986 25.874c0-15.49-12.594-28.077-28.095-28.077-15.5 0-28.094 12.587-28.094 28.077m62.377 13.788c-3.783 3.78-9.918 3.78-13.701 0-3.783-3.781-9.919-3.781-13.702 0-3.782 3.78-9.918 3.78-13.7 0-3.784-3.781-9.92-3.781-13.702 0-3.783 3.78-9.918 3.78-13.701 0m13.681 16.559c3.783 3.78 9.919 3.78 13.701 0 3.783-3.781 9.919-3.781 13.702 0 3.783 3.78 9.918 3.78 13.701 0"
      />
    </svg>
  )
}
export default SvgSymbolicrelax
