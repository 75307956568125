/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicinteractive = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 23" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={1.7}
        d="M12.275 13.669l-1.692-2.79a1.295 1.295 0 01.449-1.786 1.319 1.319 0 011.801.445l1.698 2.79"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={1.7}
        d="M13.505 10.668a1.294 1.294 0 01.448-1.786 1.319 1.319 0 011.802.445l.68 1.113"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={1.7}
        d="M16.774 10.985l-.34-.56a1.294 1.294 0 01.45-1.785 1.319 1.319 0 011.801.445l1.359 2.225c1.876 3.081.898 7.115-2.21 8.975-3.11 1.86-7.167.828-9.038-2.248l-2.038-3.344a1.294 1.294 0 01.45-1.785 1.319 1.319 0 011.801.445l2.377 3.902"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={1.7}
        d="M10.577 10.886l-2.74-4.497a1.319 1.319 0 00-1.801-.445 1.294 1.294 0 00-.45 1.786l4.099 6.733M.988 4.906l1.912.285M7.153 1l-.262 1.902M2.871 1.68l1.743 2.013"
      />
    </svg>
  )
}
export default SvgSymbolicinteractive
