/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccouple = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 38" {...props}>
      <g fill="currentColor" clipPath="url(#symbolic_couple_svg__symbolic_couple_svg__clip0)">
        <path d="M20.42 37.911H2.437A2.459 2.459 0 010 35.472v-1.285c0-6.297 5.138-11.44 11.429-11.44 6.29 0 11.428 5.143 11.428 11.44v1.285a2.43 2.43 0 01-2.436 2.44zM3.1 34.807h16.656v-.62a8.322 8.322 0 00-8.327-8.336A8.322 8.322 0 003.1 34.187v.62z" />
        <path d="M11.428 24.875c-4.341 0-7.885-3.547-7.885-7.893S7.087 9.09 11.428 9.09c4.34 0 7.885 3.547 7.885 7.893s-3.544 7.892-7.885 7.892zm0-12.681c-2.658 0-4.784 2.128-4.784 4.788s2.126 4.79 4.784 4.79 4.784-2.13 4.784-4.79-2.126-4.788-4.784-4.788zM37.564 38H22.503c-1.33 0-2.437-1.109-2.437-2.439v-.93c0-5.5 4.474-9.978 9.967-9.978S40 29.132 40 34.63v.931A2.43 2.43 0 0137.564 38zm-14.397-3.104h13.688v-.31c0-3.77-3.057-6.873-6.866-6.873a6.847 6.847 0 00-6.866 6.873v.31h.044z" />
        <path d="M30.033 26.693c-3.81 0-6.955-3.104-6.955-6.961s3.1-6.962 6.955-6.962 6.954 3.104 6.954 6.962-3.145 6.961-6.954 6.961zm0-10.775a3.859 3.859 0 000 7.716 3.858 3.858 0 003.853-3.858 3.83 3.83 0 00-3.853-3.858zm-7.575-2.35a2.244 2.244 0 01-1.461-.576l-.044-.044-4.297-4.612c-1.86-1.862-1.95-4.567-.177-6.562C17.852.177 20.288-.621 22.459.665 24.585-.62 27.02.177 28.393 1.774c1.728 1.995 1.64 4.655-.177 6.518l-4.341 4.655c-.399.4-.886.621-1.417.621zM20.155 3.104c-.753 0-1.285.576-1.373.665-.266.266-.975 1.286.089 2.35l.044.044 3.5 3.77 3.543-3.814c1.019-1.02.31-2.04.044-2.306-.398-.487-1.373-1.064-2.303-.354-.797.62-1.905.62-2.658 0a1.2 1.2 0 00-.886-.355z" />
      </g>
      <defs>
        <clipPath id="symbolic_couple_svg__symbolic_couple_svg__clip0">
          <path fill="#fff" d="M0 0h40v38H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymboliccouple
