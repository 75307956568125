/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicthumbsUp = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 98 96" {...props}>
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={6}
        d="M42.011 60.51h-8.368c-2.014 0-3.719-1.703-3.719-3.714v-18.26c0-2.01 1.705-3.713 3.72-3.713h8.367v25.686z"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={6}
        d="M39.377 60.51h28.667c2.015 0 3.72-1.393 4.03-3.404l3.098-21.973c.31-2.476-1.55-4.642-4.029-4.642H57.817V19.968c0-3.094-2.635-5.725-5.734-5.725-.93 0-1.704.62-2.17 1.393l-7.902 19.187"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={6}
        d="M94.045 48.317C93.656 70.847 76.67 90.14 53.59 92.728 28.694 95.448 6.133 77.45 3.28 52.59.687 29.155 16.376 7.79 39.068 3"
      />
    </svg>
  )
}
export default SvgSymbolicthumbsUp
