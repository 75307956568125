/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicheartSpaced = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 66 64" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={6}
        d="M20.828 49.69c5.263 4.768 10.096 8.08 11.42 8.954.215.145.501.218.752.218.286 0 .537-.073.752-.218 3.866-2.585 38.162-26.17 24.38-46.918-5.943-8.954-19.154-8.736-24.523.582l-.323.546h-.572l-.323-.546c-5.37-9.354-18.58-9.572-24.522-.582-6.194 9.318-2.685 19.255 3.4 27.554"
      />
    </svg>
  )
}
export default SvgSymbolicheartSpaced
