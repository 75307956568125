/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolichome = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M20.19 6.992a4.029 4.029 0 011.57 3.209v6.774A4.025 4.025 0 0117.736 21H6.786a4.025 4.025 0 01-4.025-4.025V10.2c0-1.256.586-2.44 1.584-3.2L9.82 2.824a4.025 4.025 0 014.882 0l3.033 2.311V2.483"
      />
      <path
        fill="currentColor"
        d="M10.107 10a1.346 1.346 0 100 2.692 1.346 1.346 0 000-2.692zm4.308 0a1.346 1.346 0 100 2.692 1.346 1.346 0 000-2.692zm-4.308 4.308a1.346 1.346 0 100 2.692 1.346 1.346 0 000-2.692zm4.308 0a1.346 1.346 0 100 2.692 1.346 1.346 0 000-2.692z"
      />
    </svg>
  )
}
export default SvgSymbolichome
