/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsoundOff = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" {...props}>
      <rect width={48} height={48} fill="currentcolor" rx={24} />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={2}
        d="M22 28h-4.937C16.478 28 16 27.417 16 26.692v-5.384c0-.72.474-1.308 1.063-1.308H22v8zm0 .05L31 32V16l-9 3.954v8.097z"
      />
      <path stroke="#fff" strokeLinecap="round" strokeWidth={2} d="M17.5 15.5l15 18.5" />
    </svg>
  )
}
export default SvgSymbolicsoundOff
