/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicmore = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" {...props}>
      <path
        fill="currentColor"
        d="M23.125 16.25a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zm-7.5 0a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zm-7.5 0a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75z"
      />
    </svg>
  )
}
export default SvgSymbolicmore
