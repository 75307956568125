/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccalendar = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.12}
        d="M19.17 22.34H4.77A2.77 2.77 0 012 19.57V6.51a2.77 2.77 0 012.77-2.77h14.4a2.77 2.77 0 012.77 2.77v13.06a2.77 2.77 0 01-2.77 2.77zM2 10.56h19.94M7.8 2v3.46M16.15 2v3.46"
      />
    </svg>
  )
}
export default SvgSymboliccalendar
