/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicdiplomacy = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 26" {...props}>
      <g fill="currentColor" clipPath="url(#symbolic_diplomacy_svg__symbolic_diplomacy_svg__clip0)">
        <path d="M21.47 16.96h-3.01v-2.13h3.01c.34 0 .62-.28.62-.62V2.75c0-.34-.28-.62-.62-.62H2.75c-.34 0-.62.28-.62.62v11.46c0 .34.28.62.62.62h7.64v2.13H2.75C1.23 16.96 0 15.73 0 14.21V2.75C0 1.23 1.23 0 2.75 0h18.72c1.52 0 2.75 1.23 2.75 2.75v11.46c0 1.52-1.23 2.75-2.75 2.75z" />
        <path d="M14.22 19.33c-2.82 0-5.11-2.29-5.11-5.11 0-2.82 2.29-5.11 5.11-5.11s5.11 2.29 5.11 5.11-2.29 5.11-5.11 5.11zm0-8.08a2.97 2.97 0 100 5.94 2.97 2.97 0 000-5.94z" />
        <path d="M11.99 25.47c-.41 0-.8-.17-1.09-.47l-.9-.96-1.52.03c-.47 0-.97-.23-1.26-.65-.29-.42-.34-.95-.15-1.41l2.16-5.32c.15-.37.43-.66.8-.81.37-.16.77-.16 1.14-.01l3.53 1.43c.37.15.66.44.81.8.16.37.16.77.01 1.14l-2.15 5.3c-.19.47-.61.81-1.11.91-.09.02-.18.02-.27.02zm-1.71-3.57c.41 0 .81.17 1.09.48l.41.44 1.53-3.78-2.35-.95-1.55 3.83.84-.01c.01-.01.02-.01.03-.01z" />
        <path d="M16.54 25.69c-.11 0-.22-.01-.33-.04a1.5 1.5 0 01-1.07-.93l-2.04-5.37a1.479 1.479 0 01.86-1.92l3.56-1.35a1.479 1.479 0 011.92.86l2.03 5.34c.18.48.11 1.01-.2 1.42-.31.41-.79.64-1.31.6l-1.31-.1-1.1 1.06c-.26.28-.63.43-1.01.43zm-1.21-6.49l1.47 3.86.61-.58c.31-.29.73-.44 1.14-.41l.6.04-1.45-3.81-2.37.9z" />
      </g>
      <defs>
        <clipPath id="symbolic_diplomacy_svg__symbolic_diplomacy_svg__clip0">
          <path fill="#fff" d="M0 0h24.22v25.69H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicdiplomacy
