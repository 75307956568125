/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicprzelewy = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 18" {...props}>
      <g clipPath="url(#symbolic_przelewy_svg__symbolic_przelewy_svg__clip0_8382_108219)">
        <path
          fill="#D13239"
          fillRule="evenodd"
          d="M10.362 10.237l3.956-.005-.144.9-3.309 3.16 2.778-.005-.162.972-4.09.002.171-1.004 3.173-3.038h-2.533l.16-.982zM6.033 8.696a.99.99 0 00-.346-.217 2.519 2.519 0 00-.46-.124 3.895 3.895 0 00-.527-.056c-.126-.005-.207-.005-.207-.005H1.475L.32 15.261H1.4l.425-2.58 2.09.01s.814.033 1.39-.273c.578-.306.732-1.002.732-1.002s.063-.258.116-.572a36.846 36.846 0 00.167-1.138s.016-.071.016-.182a1.245 1.245 0 00-.142-.61 1 1 0 00-.16-.218zm-.83 1.23c0 .013-.095.572-.222 1.234-.048.253-.243.39-.465.438-.37.079-.767.063-.767.063l-1.745-.005.384-2.314 1.581.005s.106-.003.256 0c.17.002.394.01.579.035.16.02.286.053.326.104a.429.429 0 01.081.227c.01.112-.007.205-.007.213zm14.481-1.632h1.037l-1.16 6.967-1.04-.002 1.163-6.965zm5.536 1.943l.993-.002.352 3.536 1.53-3.542 1.228.003.361 3.552 1.528-3.55h1.032l-2.19 5.022h-1.225l-.354-3.516-1.546 3.516-1.204.005-.506-5.024z"
          clipRule="evenodd"
        />
        <path
          fill="#D13239"
          d="M17.769 10.277c-.291-.093-.794-.113-1.222-.108-.412.005-.602.025-.756.058 0 0-.734.106-1.151.622-.418.516-.542 1.645-.542 1.645s-.248 1.247-.174 1.664c.073.415.202.802.673.982.47.182.87.172.87.172s.84.066 1.472-.084c.633-.149.967-.594.967-.594s.149-.193.255-.42c.106-.228.14-.387.144-.408l.066-.27-1.075.002s-.058.711-.64.777c-.58.066-.89.04-1.004.035-.112-.005-.734.023-.683-.498 0-.008 0-.018.002-.033.028-.592.094-.746.094-.746l3.377-.01.144-.835c.167-.946.048-1.667-.817-1.95zm-.225 1.832l-2.315-.002.091-.367s.081-.289.24-.41c.162-.121.367-.144.56-.162.192-.018.705-.056 1.123.03.139.028.27.107.306.223.08.273-.005.688-.005.688z"
        />
        <path
          fill="#D13239"
          d="M14.976 13.83l-.002.022.002-.023zm6.37.002c0 .007-.003.015-.003.023 0 .04.003-.026.003-.023z"
        />
        <path
          fill="#D13239"
          d="M24.142 10.28c-.291-.093-.795-.114-1.222-.109-.413.005-.602.026-.757.059 0 0-.733.106-1.15.622-.418.516-.542 1.644-.542 1.644s-.248 1.247-.175 1.665c.074.415.203.802.673.981.47.182.87.172.87.172s.84.066 1.473-.083c.632-.15.966-.595.966-.595s.15-.192.256-.42c.106-.227.139-.387.144-.407l.066-.27-1.075.002s-.059.71-.64.777c-.58.065-.891.04-1.005.037-.111-.005-.733.02-.683-.498 0-.007 0-.018.003-.033.027-.592.093-.746.093-.746l3.377-.01.145-.835c.167-.949.048-1.672-.817-1.953zm-.236 1.83l-2.314-.003.09-.367s.082-.289.24-.41c.16-.121.368-.144.56-.162.192-.018.706-.056 1.123.03.14.028.27.107.306.223.081.27-.005.688-.005.688z"
        />
        <path
          fill="#D13239"
          fillRule="evenodd"
          d="M33.438 10.237l.666 3.673 1.88-3.675 1.057.01-2.71 5.249s-.49.951-.794 1.191c-.303.24-.49.35-.739.375-.248.025-.349.043-.587 0l-.252-.046.156-.938s.42.078.668-.02c.25-.1.45-.524.45-.524l.127-.213-.979-5.085 1.057.003z"
          clipRule="evenodd"
        />
        <path
          fill="#B3B2B1"
          fillRule="evenodd"
          d="M37.425 10.735l1.09.003.066-.422s.117-.764.377-.911c.084-.048.218-.091.372-.12a4.94 4.94 0 01.944-.045c.453.016.625.02 1.085.074.46.053.344.498.344.498l-.091.665s-.04.299-.147.484c-.093.164-.351.275-.5.323-.36.117-1.59.43-1.59.43l-.966.279s-.594.172-.926.539a2.211 2.211 0 00-.51 1.004c-.046.217-.302 1.723-.302 1.723l5.217.002.174-1.042-4.126.005.074-.425s.048-.438.225-.582c.055-.045.083-.106.412-.223.197-.07.87-.25.87-.25l1.556-.425s.85-.218 1.184-.683c.334-.463.463-1.35.463-1.35s.091-.864.02-1.134c-.068-.27-.324-.595-.635-.734-.31-.139-.635-.22-1.573-.207-.939.012-1.404.055-1.88.232-.475.175-.751.494-.926.944-.19.428-.3 1.348-.3 1.348zm9.821 1.938l.73-4.381h-1.294l-4.033 4.33-.182 1.099h3.526l-.258 1.538 1.083.002.256-1.54.999.002.18-1.05h-1.007zm-1.078.003l-2.28-.003 2.776-2.957-.496 2.96zM8.32 8.868h3.322s.744-.602 1.272-.989c.53-.387 1.49-.996 1.49-.996l-1.877-.878s-1.586.981-2.259 1.444c-.652.428-1.948 1.42-1.948 1.42zm7.14-2.605l-1.541-1.037s1.394-.792 3.248-1.53c1.852-.74 2.84-1.048 2.84-1.048l.314 1.47s-1.783.597-2.805 1.08a17.88 17.88 0 00-2.057 1.065zm6-2.467l-.268-1.505s1.902-.506 3.643-.83c1.743-.324 4.052-.478 4.052-.478l-.764 2.325s-2.031-.278-3.938-.018c-1.485.177-2.725.506-2.725.506zm7.872-.291L30.619.922s2.815-.055 5.244.322c2.428.374 4.65.951 4.602.976l-6.158 3.22s-1.44-.91-3.225-1.47a31.71 31.71 0 00-1.748-.465zm6.078 2.636l1.353 1.027h11.123s-.023-.359-.319-.87c-.184-.319-.52-.658-.873-1.01a9.677 9.677 0 00-1.024-.776c-.982-.637-1.53-.883-2.55-1.346l-7.71 2.975z"
          clipRule="evenodd"
        />
        <path
          fill="#D13239"
          d="M9.041 10.232c-.417 0-.812.164-1.148.35l.058-.35H6.846l-.886 5.001h1.108l.491-2.77c.101-.561.521-1.257 1.34-1.257l.573-.003.172-.971H9.04z"
        />
      </g>
      <defs>
        <clipPath id="symbolic_przelewy_svg__symbolic_przelewy_svg__clip0_8382_108219">
          <path fill="#fff" d="M0 0h48.57v17H0z" transform="translate(0 .5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicprzelewy
