/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliclockAlt = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 26" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.12}
        d="M17.61 24.56H4.21A2.21 2.21 0 012 22.35V11.83c0-1.22.99-2.21 2.21-2.21h13.41c1.22 0 2.21.99 2.21 2.21v10.51c0 1.22-.99 2.22-2.22 2.22zM15.83 9.25V7.41A5.29 5.29 0 0010.68 2a5.29 5.29 0 00-5.15 5.41v1.84"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.91}
        d="M10.91 17.78a2.08 2.08 0 100-4.16 2.08 2.08 0 000 4.16zm0 0v2.78"
      />
    </svg>
  )
}
export default SvgSymboliclockAlt
