/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicarticlesRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M12.152 18.6C8.41 15.483 4.2 18.346 4.2 18.346V4.762s4.18-3.658 7.829.223c0 0 3.025 2.29 1.154 2.958-2.059.732-2.402-1.558-1.248-2.8"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M12.3 18.47c3.743-3.117 7.712-.124 7.712-.124V4.762s-4.18-3.658-7.828.223"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M2.6 5.822v13.159c0 .646.507 1.164 1.14 1.164h7.291m1.903 0h7.48c.634 0 1.141-.518 1.141-1.164V5.887"
      />
    </svg>
  )
}
export default SvgSymbolicarticlesRebrand
