/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicgenderMan = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={13.33}
        strokeWidth={1.79}
        d="M17.97 14.176c0 3.958-3.27 7.222-7.223 7.222-3.959 0-7.164-3.268-7.164-7.222a7.165 7.165 0 017.164-7.164c3.953 0 7.222 3.206 7.222 7.164zm1.719-11.351l-4.37 5.574m-.511-5.153l5.028-.604.605 5.028"
      />
    </svg>
  )
}
export default SvgSymbolicgenderMan
