/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicpayBack = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.67}
        d="M22.498 16.074a2.093 2.093 0 00-1.812-1.979 2.081 2.081 0 00-1.444.353c-.032.021-.064.048-.096.07l-3.22 2.342c-.368.267-.855.32-1.272.128l-.877-.743a.24.24 0 01.038-.396 2.075 2.075 0 001.074-2.075 2.088 2.088 0 00-1.737-1.807c-.006 0-.017 0-.022-.005a1.976 1.976 0 00-1.144.203l-1.915.968a3.169 3.169 0 01-1.582.337l-3.23-.022c-.123-.005-.246 0-.369.022-1.385.272-2.39 1.486-2.39 2.957 0 1.39.941 2.56 2.224 2.914.188.048.38.085.578.096l8.62 1.572c1.347.3 2.753.032 3.898-.738l3.154-2.043c.808-.545 1.567-1.155 1.524-2.154zm-4.283-6.46a3.807 3.807 0 100-7.614 3.807 3.807 0 000 7.614zm-4.294 6.299l-3.32 1.08"
      />
    </svg>
  )
}
export default SvgSymbolicpayBack
