/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsupportRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M5.662 14.948c.149.904.452 2.306.937 3.327.766 1.582 9.908.267 8.471-2.127-1.904-3.175-6.462 2.2-.958 3.842"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M3.509 15.536l1.702-.117c.275-.02.48-.297.458-.63l-.286-4.162c-.023-.332-.266-.597-.54-.56l-1.753.138c-.24.017-.431.258-.446.54-.047.83-.068 2.535.331 4.318.072.294.293.49.534.473zm16.924-1.34l-1.599.11c-.258.018-.482-.23-.505-.563l-.286-4.162c-.023-.333.164-.627.423-.627l1.636-.077c.224-.016.429.199.482.476.143.816.397 2.503.263 4.323.002.28-.19.505-.414.52z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M4.999 10.074s.054-6.997 6.294-7.462c5.964-.445 6.774 6.81 6.774 6.81"
      />
    </svg>
  )
}
export default SvgSymbolicsupportRebrand
