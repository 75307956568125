/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolictransfer = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#82555C"
        d="M13 20l10-8-10-8v5C7.477 9 3 13.477 3 19c0 .273.01.543.032.81a9.003 9.003 0 017.655-4.805L11 15h2v5zm2-7h-4.034l-.347.007c-1.285.043-2.524.31-3.676.766A7.984 7.984 0 0113 11h2V8.161L19.798 12 15 15.839V13z"
      />
    </svg>
  )
}
export default SvgSymbolictransfer
