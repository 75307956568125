/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicwarning = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <g fill="currentcolor" clipPath="url(#symbolic_warning_svg__symbolic_warning_svg__clip0_20_7545)">
        <path d="M12 22C6.491 22 2 17.509 2 12S6.491 2 12 2s10 4.491 10 10-4.474 10-10 10zm0-18.246c-4.544 0-8.246 3.702-8.246 8.246 0 4.544 3.702 8.246 8.246 8.246 4.544 0 8.246-3.702 8.246-8.246 0-4.544-3.685-8.246-8.246-8.246z" />
        <path d="M12 17.017a1.21 1.21 0 100-2.42 1.21 1.21 0 000 2.42zm0-3.263c-.544 0-.982-.456-1-1.052l-.158-4.597c-.018-.614.439-1.123 1-1.123h.316c.561 0 1.017.51 1 1.123l-.14 4.597c-.036.579-.474 1.052-1.018 1.052z" />
      </g>
      <defs>
        <clipPath id="symbolic_warning_svg__symbolic_warning_svg__clip0_20_7545">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicwarning
