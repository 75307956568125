/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsavingsRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 37" {...props}>
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M11.8 18.026H3"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M32.596 10.788s-4.182-4.412-7.463-4.412c-7.657 0-12.844 5.43-12.844 5.43"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M23.398 12.71s-20.021-.68-20.203.491c-.322 2.068-.193 14.476.064 15.235.257.758 16.343 1.31 18.208-.07 1.802-1.309 2.06-5.583-2.187-4.273-3.796 1.172-1.416 6.549 4.118 6.273"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M24.759 12.82c-.968-.386-3.874 1.929-7.151 2.778-3.278.849-8.194 3.55-6.109 5.248 2.086 1.698 2.16-3.087 11.472 1.235 6.332 1.544 10.429-3.473 10.429-3.473"
      />
    </svg>
  )
}
export default SvgSymbolicsavingsRebrand
