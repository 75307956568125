/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicphoneAlt = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 25" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.12}
        d="M20.22 16.47c-1.24-.07-4.76-.9-5.23-.56-.44.32-1.7 1.03-1.7 1.03a.56.56 0 01-.56.01 12.88 12.88 0 01-4.98-5.63.54.54 0 01.07-.56L9 9.26c.46-.4.06-4.07.13-5.32a1.62 1.62 0 00-1.51-1.7L3.86 2a1.62 1.62 0 00-1.71 1.51C.8 14.53 8.63 23.51 19.8 23.43c.89.05 1.65-.62 1.7-1.5l.24-3.76a1.62 1.62 0 00-1.52-1.7z"
      />
    </svg>
  )
}
export default SvgSymbolicphoneAlt
