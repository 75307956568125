/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicstarClient = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#symbolic_star-client_svg__symbolic_star-client_svg__clip0_9062_6214)">
        <path
          fill="currentColor"
          d="M11.003 14.062c.547-.069.997.386.997.938 0 .552-.45.992-.995 1.083a6 6 0 00-4.922 4.922C5.992 21.549 5.553 22 5 22c-.552 0-1.006-.45-.938-.997a8 8 0 016.94-6.94zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6 10.5l-2.939 1.545.561-3.272-2.377-2.318 3.286-.478L18 14l1.47 2.977 3.285.478-2.377 2.318.56 3.272L18 21.5z"
        />
      </g>
      <defs>
        <clipPath id="symbolic_star-client_svg__symbolic_star-client_svg__clip0_9062_6214">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicstarClient
