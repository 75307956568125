/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliclogoBlank = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="hedepy"
      data-name="Layer 1"
      viewBox="0 0 211.08 70.93"
      {...props}
    >
      <path
        fill="currentcolor"
        d="M7.21 0v14.66c2-3 5.45-4.85 9.91-4.85C24.56 9.81 28 15.37 28 22.54v9c0 1.93.55 3.25 2.31 3.25a6.28 6.28 0 003-1l.39 4.57a12 12 0 01-5.57 1.33c-3.91 0-5.89-2.15-5.89-6.89v-9.38c0-4.9-2.37-8.32-7.11-8.32-4.08 0-6.94 3.09-7.93 6.23v17.79H1.47V0zm57.63 36a18.74 18.74 0 01-12 3.92c-10.86 0-15.6-6.73-15.6-15.1S43.13 9.53 52.38 9.53c8.77 0 12.84 5.4 12.84 12a21.41 21.41 0 01-.6 5.07H43.18c.55 5 3.58 8.43 9.86 8.43a16.18 16.18 0 009.54-3.2zm-5.13-15.39c0-3.31-2.26-6.28-7.44-6.28a8.55 8.55 0 00-9 7.82H59.6a12.54 12.54 0 00.11-1.54zM99.76 0v31.57c0 2 .5 3.2 2.32 3.2a6.08 6.08 0 003-1l.45 4.57a12.6 12.6 0 01-5.63 1.33c-3 0-4.84-1.22-5.56-4a12.16 12.16 0 01-9.53 4c-8.22 0-13.78-6.29-13.78-15S76.56 9.81 84.78 9.81A13.28 13.28 0 0194 13.23V0zM86.37 34.66A11.11 11.11 0 0094 31.41V18.19a11.15 11.15 0 00-7.66-3.37c-5.78 0-9.53 4-9.53 9.87s3.78 9.97 9.56 9.97zM137.12 36a18.74 18.74 0 01-12 3.92c-10.86 0-15.6-6.73-15.6-15.1s5.84-15.27 15.1-15.27c8.76 0 12.84 5.4 12.84 12a20.85 20.85 0 01-.61 5.07h-21.39c.55 5 3.58 8.43 9.86 8.43a16.18 16.18 0 009.54-3.2zM132 20.61c0-3.31-2.26-6.28-7.44-6.28a8.55 8.55 0 00-9 7.82h16.31a12.54 12.54 0 00.13-1.54zm79 12.52v-22.8h-5.74v17.8c-1 3.14-3.85 6.23-7.93 6.23-4.74 0-7.11-3.42-7.11-8.32v-9.37c0-4.74-2-6.89-5.89-6.89a12 12 0 00-5.57 1.33l.39 4.57a6.28 6.28 0 013-1c1.76 0 2.31 1.32 2.31 3.25v9c0 7.16 3.42 12.73 10.86 12.73 4.44 0 7.84-1.81 9.88-4.81 0 3.45-.56 12.45-6.38 18.78-4.33 4.73-10.82 7.12-19.29 7.12s-15.07-2.42-19.53-7.18c-5.22-5.52-6.51-13-6.82-17.11v-.09a13.36 13.36 0 009.15 3.31c8.26 0 13.83-6.23 13.83-14.88s-5.57-15-13.83-15a12.27 12.27 0 00-9.48 3.91c-.72-2.7-2.65-4-5.62-4a12 12 0 00-5.57 1.33l.34 4.66a5.38 5.38 0 013.09-1c1.81 0 2.31 1.27 2.31 3.25v15.23c0 .59-.37 15.17 8.29 24.34 5.66 6 13.68 9 23.89 9s18.13-3 23.61-9c8.52-9.31 7.97-22.42 7.81-24.39zm-57.86-14.94a10.72 10.72 0 017.66-3.37c5.79 0 9.53 4.08 9.53 10s-3.8 9.86-9.53 9.86a11.28 11.28 0 01-7.66-3.2z"
      />
    </svg>
  )
}
export default SvgSymboliclogoBlank
