/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicdaytimeAll = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M6.91 9.033c0-1.706.644-3.265 1.706-4.45-3.364.849-5.855 3.862-5.855 7.45 0 4.246 3.49 7.688 7.795 7.688 3.526 0 6.505-2.31 7.468-5.479a6.83 6.83 0 01-4.3 1.512c-3.764 0-6.815-3.01-6.815-6.721zm3.646-4.688c4.305 0 7.795 3.442 7.795 7.688m0 0c0-4.246-3.49-7.688-7.795-7.688"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M8.62 4.583a6.645 6.645 0 00-1.706 4.45c0 3.712 3.05 6.716 6.81 6.716a6.83 6.83 0 004.3-1.511m2.27-2.116h1.466M10.623 2.467V1m0 22v-1.467M17.36 5.4l1.108-1.14M17.36 18.6l1.291 1.351"
      />
    </svg>
  )
}
export default SvgSymbolicdaytimeAll
