/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccalendarLight = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M1.76 10.56H21.7M7.56 2v3.46M15.91 2v3.46m3.02 16.88H4.53a2.77 2.77 0 01-2.77-2.77V6.51a2.77 2.77 0 012.77-2.77h14.4a2.77 2.77 0 012.77 2.77v13.06a2.77 2.77 0 01-2.77 2.77z"
      />
    </svg>
  )
}
export default SvgSymboliccalendarLight
