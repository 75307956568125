/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicbulb = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" {...props}>
      <path
        fill="#FDF7F2"
        d="M16.712 9.92c-2.55.728-4.098 5.16-4.554 7.285-1.366 1.822 1.822 9.563 2.277 10.474.364.729 5.01 4.857 7.286 6.83l.91 1.367-.454.91 1.366 3.643 2.732.911 2.732 2.277c.152-.152.729-.364 1.822 0s3.187-.455 4.098-.91l1.822-2.278 1.821-.91v-3.643h.91l.912-2.277L38.57 29.5l-1.822-.91-1.366-11.385-1.366-3.187-3.187-3.643c-1.063-.456-3.552-1.549-5.01-2.277-1.821-.91-5.92.91-9.107 1.821z"
      />
      <path
        fill="#FF8F67"
        d="M16.643 21.969c-.293 1.388.573 3.389 1.042 4.216.503.899 1.714 2.861 2.54 3.523 1.032.827 5.756 1.773 8.43.536 2.675-1.238 3.64-3.643 4.533-5.361.893-1.719-.13-4.77-.776-6.167-.647-1.397-1.672-2.49-4.478-4.062-2.806-1.573-4.597.17-7.264 1.143-2.667.974-3.662 4.436-4.027 6.172z"
      />
      <path
        stroke="#490811"
        strokeMiterlimit={10}
        strokeWidth={0.91}
        d="M23.097 36.702l-.766-1.656s-6.089-4.569-7.763-7.736c-1.673-3.167-3.333-8.324-2.3-10.864 1.032-2.54 2.567-6.462 7.77-7.851 5.2-1.39 8.682.575 9.94 1.272 1.257.697 4.89 4.001 5.558 7.686.666 3.686.996 11.469.996 11.469l.747 1.56"
      />
      <path
        stroke="#490811"
        strokeMiterlimit={10}
        strokeWidth={0.91}
        d="M22.283 35.669s-.519 1.18-.098 1.246c.42.067 6.774.101 13.907-5.366 4.791-3.68.633-2.225.633-2.225"
      />
      <path
        stroke="#490811"
        strokeMiterlimit={10}
        strokeWidth={0.91}
        d="M22.03 36.752s1.328 3.823 1.863 3.968c1.634.444 3.506.335 4.744-.134 1.238-.468 9.89-4.055 10.675-4.941.785-.886 1.874-2.068-.863-6.466"
      />
      <path
        stroke="#490811"
        strokeMiterlimit={10}
        strokeWidth={0.91}
        d="M26.078 41.065s2.039 2.945 2.979 2.563c.94-.383 9.8-3.858 9.78-4.293.003-.444.044-2.656-.12-3.18"
      />
      <path
        stroke="#490811"
        strokeMiterlimit={10}
        strokeWidth={0.91}
        d="M29.855 43.39s.597.56 1.411.523c.814-.038 4.344-.55 6.338-3.874m-9.848-4.212s-5.262-12.548-7.99-11.65c-2.718.918.73 3.316 3.325 1.906 2.573-1.4 2.05-4.604.707-4.478-1.345.127-.015 3.505 2.506 2.834 2.521-.67 3.438-3.236 2.609-3.4-.83-.164-2.584 2.5.14 2.885 2.723.384 2.249-2.999 1.381-2.18-.878.798-1.502 3.097.206 6.562 1.707 3.465 2.414 5.33 2.414 5.33"
      />
      <path
        stroke="#490811"
        strokeMiterlimit={10}
        strokeWidth={0.91}
        d="M32.346 19.081c1.901 4.106.149 8.963-3.895 10.835-4.043 1.871-8.88.064-10.78-4.042-1.901-4.106.331-8.768 4.375-10.64 4.043-1.871 8.4-.26 10.3 3.847z"
      />
      <path
        stroke="#FF8F67"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.91}
        d="M8.424 26.946s1.583-2.194 2.438-2.59M6.695 14.172s1.627 1.1 2.67 1.166m6.052-10.945s.587 2.39.973 2.551m11.922.614s.927-1.421 1.048-2.339m7.823 10.895s.634-.372 1.613-.669"
      />
    </svg>
  )
}
export default SvgSymbolicbulb
