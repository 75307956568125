/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliclike = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M7.167 24.667H4.052a2.695 2.695 0 01-2.719-2.334v-8.166a2.695 2.695 0 012.719-2.334h3.115M15.333 9.5V4.833a3.5 3.5 0 00-3.5-3.5l-4.666 10.5v12.834h13.16a2.334 2.334 0 002.333-1.984l1.61-10.5A2.334 2.334 0 0021.937 9.5h-6.604z"
      />
    </svg>
  )
}
export default SvgSymboliclike
