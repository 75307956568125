/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolictalk = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M15.176 14.59s2.459 3.153 2.501 2.396c.043-.756.128-2.48.128-2.48s2.289.21 2.416-.042c.17-.252.424-8.322-.381-8.742-.721-.379-11.482-.21-11.651.042-.128.168-.043 3.53.042 5.002 0 .546.424 1.008.933 1.092.339.042 4.149-.084 3.004-1.891-1.441-2.27-3.62 3.07.493 3.364"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M6.212 7.803s-.27-.022-1.881.273c-1.702 0-1.625 8.742-.594 8.742 1.823.21 2.332.084 2.332.084s-.933 2.564-.382 2.438c.594-.126 2.629-2.354 2.629-2.354s2.628.463 3.264-.336"
      />
    </svg>
  )
}
export default SvgSymbolictalk
