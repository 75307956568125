/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccheckbox = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 27" {...props}>
      <rect width={17} height={17} x={1} y={5} stroke="#490811" strokeWidth={2} rx={3} />
    </svg>
  )
}
export default SvgSymboliccheckbox
