/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolictrash = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 29 31" {...props}>
      <g stroke="currentColor" strokeMiterlimit={10} clipPath="url(#symbolic_trash_svg__symbolic_trash_svg__clip0)">
        <path strokeLinecap="round" strokeWidth={2.74} d="M1.4 6h25.4" />
        <path
          strokeWidth={2.74}
          d="M14.3 1.4c-2.5 0-4.6 2.1-4.6 4.6h9.2c0-2.6-2.1-4.6-4.6-4.6zm5.5 27.3H8.9c-1.6 0-2.9-1.2-3-2.8L4.6 6h19.6l-1.3 19.9c-.2 1.6-1.5 2.8-3.1 2.8z"
        />
        <path strokeLinecap="round" strokeWidth={2.31} d="M11.5 12v9.2m5.2-9.2v9.2" />
      </g>
      <defs>
        <clipPath id="symbolic_trash_svg__symbolic_trash_svg__clip0">
          <path fill="#fff" d="M0 0h28.1v30.1H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolictrash
