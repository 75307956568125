/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicdiscount = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 23" {...props}>
      <path
        fill="currentColor"
        d="M6.855 9.248c0-1.08.798-1.95 2.046-1.95 1.248 0 2.046.87 2.046 1.95 0 1.078-.797 1.914-2.046 1.914-1.283 0-2.046-.836-2.046-1.914zm1.734 5.115l4.265-6.716a.479.479 0 01.382-.209c.38 0 .589.418.38.73l-4.264 6.717c-.07.14-.243.209-.381.209a.465.465 0 01-.382-.73zm.971-5.115c0-.522-.312-.731-.659-.731-.346 0-.624.209-.624.73 0 .488.278.697.624.697.347 0 .66-.21.66-.696zm1.7 4.071c0-1.079.797-1.949 2.045-1.949 1.248 0 2.046.87 2.046 1.95 0 1.078-.798 1.913-2.046 1.913-1.248 0-2.046-.87-2.046-1.914zm2.704 0c0-.522-.312-.73-.66-.73-.38 0-.658.208-.658.73s.277.696.659.696c.381 0 .659-.209.659-.696z"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M11.78 1.313l1.526 1.323c.243.243.59.348.936.313l2.011-.174c.625-.07 1.18.348 1.353.974l.45 1.949c.07.348.278.626.59.8l1.734 1.044c.555.313.763 1.01.52 1.566l-.798 1.845a1.193 1.193 0 000 .974l.798 1.844c.243.592.035 1.253-.52 1.566l-1.734 1.044c-.277.174-.52.453-.59.8l-.45 1.95a1.229 1.229 0 01-1.353.974l-2.01-.174a1.15 1.15 0 00-.937.313l-1.525 1.322a1.262 1.262 0 01-1.665 0l-1.49-1.287a1.15 1.15 0 00-.937-.313l-2.011.174c-.624.07-1.179-.348-1.352-.975l-.416-1.948a1.159 1.159 0 00-.59-.8l-1.734-1.045c-.52-.313-.728-1.01-.485-1.566l.797-1.879a1.193 1.193 0 000-.974l-.797-1.845c-.243-.591-.035-1.253.52-1.566l1.734-1.044c.277-.174.52-.452.59-.8l.45-1.949a1.229 1.229 0 011.352-.974l2.011.174c.347.035.694-.07.937-.313l1.456-1.323c.45-.417 1.144-.417 1.63 0z"
      />
    </svg>
  )
}
export default SvgSymbolicdiscount
