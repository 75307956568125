/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliceye = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 10" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M11.184 2c.461.787.816 1.803.816 2.795 0 1.27-.386 2.413-1 3.205M4.85 2C4.37 2.791 4 3.82 4 4.822 4 6.077 4.385 7.206 5 8"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M1 5.001C1.617 5.783 4.375 9 7.996 9 11.742 9 14.41 5.78 15 5m0 0c-.604-.766-3.37-4-7.004-4C4.264 1 1.603 4.193 1 4.985"
      />
      <path stroke="currentColor" strokeLinecap="round" strokeMiterlimit={10} d="M8 6a1 1 0 100-2 1 1 0 000 2z" />
    </svg>
  )
}
export default SvgSymboliceye
