/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicburnout = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 41 40" {...props}>
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2.6}
        d="M23.038 16.358c-.26.627 0 1.373.604 1.642.173.09.317.119.49.119a1.2 1.2 0 001.094-.716c.029-.03.144-.329.317-.836a1.218 1.218 0 011.957-.537 10.387 10.387 0 013.397 7.73c0 5.73-4.548 10.357-10.105 10.238-5.297-.12-9.587-4.627-9.645-10.118a10.467 10.467 0 011.008-4.657c.317-.656 1.238-.686 1.612-.03.202.388.461.747.749 1.075.26.298.604.418.95.418.374 0 .749-.15 1.008-.478.431-.537.374-1.313-.058-1.85 0 0-3.08-4.477.95-7.014 2.476-1.582 3.282-2.985 3.225-4.895a.6.6 0 01.979-.508c.834.687 1.9 1.85 2.072 3.522.346 2.806-.604 6.895-.604 6.895z"
      />
    </svg>
  )
}
export default SvgSymbolicburnout
