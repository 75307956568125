/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolichandshake2 = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 37" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M9.316 9.925c-2.219-.096-3.94-.235-5.664-.711M16.707 27.64c-4.764 2.173-9.232-5.364-11.28-6.956-.61-.474-1.485-.929-2.427-1.334m11.452 5.562l3.216 3.456c.502.5 1.254.5 1.797.045.585-.546.627-1.5.083-2.137l-3.383-3.637"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M19.799 26.686a1.494 1.494 0 002.089.045c.668-.637.752-1.773.125-2.5"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M33 19.366c-6.389 0-9.783-4.965-5.217-5.617 4.565-.653 1.165 6.071-.882 7.435-2.047 1.41-2.465 1.5-2.465 1.5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M19.256 21.23l3.216 3.455c.502.5 1.254.5 1.797.046.585-.546.626-1.5.083-2.137l-3.3-3.774s-4.22-4.591-5.306-4.182c-1.086.409-3.676 2.273-4.762 1.455-1.086-.819-1.045-2.32-.042-3.592 1.003-1.273 3.008-3.137 5.139-2.91 2.13.228 5.598 2.319 6.85 1.682 1.254-.636 6.1-3.273 6.1-3.273"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M21.052 18.82s-4.22-4.591-5.306-4.182c-1.086.409-3.676 2.273-4.762 1.455-1.086-.819-1.045-2.32-.042-3.592 1.003-1.273 3.008-3.137 5.139-2.91 2.13.228 5.598 2.319 6.85 1.682 1.254-.636 6.1-3.273 6.1-3.273"
      />
    </svg>
  )
}
export default SvgSymbolichandshake2
