/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicpercentageBold = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 61 60" {...props}>
      <path
        fill="currentColor"
        d="M18.875 23.737c.265-3.145 2.803-5.484 6.441-5.178 3.639.306 5.75 3.038 5.485 6.182-.265 3.145-2.795 5.383-6.433 5.077-3.74-.315-5.758-2.937-5.493-6.081zm3.797 15.336l14.08-18.53c.338-.38.76-.548 1.163-.514 1.112.094 1.616 1.362.933 2.224L24.768 40.78c-.237.39-.76.55-1.164.515-1.111-.093-1.615-1.362-.932-2.223zM26.758 24.4c.128-1.522-.73-2.207-1.74-2.292-1.011-.085-1.871.455-2 1.977-.119 1.42.638 2.097 1.649 2.182 1.01.085 1.971-.447 2.091-1.867zm3.953 12.284c.264-3.144 2.803-5.484 6.44-5.177 3.64.306 5.75 3.038 5.485 6.182-.265 3.144-2.794 5.383-6.433 5.076-3.638-.306-5.749-3.038-5.493-6.08zm7.883.664c.128-1.521-.73-2.206-1.741-2.291-1.112-.094-1.972.447-2.1 1.968-.128 1.521.638 2.097 1.75 2.19 1.111.094 1.971-.447 2.09-1.867z"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={4.17}
        d="M32.451 5.785l3.815 3.306c.606.609 1.473.87 2.34.783l5.028-.435c1.56-.174 2.947.87 3.38 2.436l1.127 4.872c.174.87.694 1.566 1.474 2l4.334 2.61c1.387.784 1.907 2.524 1.3 3.916l-1.993 4.61a2.982 2.982 0 000 2.436l1.994 4.611c.606 1.48.086 3.132-1.3 3.915l-4.335 2.61c-.693.435-1.3 1.131-1.474 2.001l-1.127 4.872c-.346 1.566-1.733 2.61-3.38 2.436l-5.028-.435c-.867-.087-1.734.174-2.34.783l-3.815 3.306a3.156 3.156 0 01-4.16 0l-3.728-3.219c-.607-.609-1.474-.87-2.34-.783l-5.028.435c-1.56.174-2.948-.87-3.381-2.436l-1.04-4.872c-.174-.87-.694-1.566-1.474-2l-4.334-2.61c-1.3-.784-1.82-2.524-1.214-3.916l1.994-4.698a2.982 2.982 0 000-2.436l-1.994-4.61c-.607-1.48-.086-3.132 1.3-3.915l4.335-2.61c.693-.435 1.3-1.131 1.473-2.001l1.127-4.872c.347-1.566 1.734-2.61 3.381-2.436l5.028.435c.867.087 1.734-.174 2.34-.783l3.641-3.306c1.127-1.044 2.86-1.044 4.074 0z"
      />
    </svg>
  )
}
export default SvgSymbolicpercentageBold
