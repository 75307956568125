/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicmoney = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M4.5 19.296c6.136-2.455 5.523 1.322 3.624 2.229-.697.333-2.582-1.091-2.295-3.005.286-1.915 1.569-3.09 3.56-4.03 5.705-2.67 12.27-1.696 12.27-1.696s.843-1.93 2.463-1.998c1.62-.067 2.515-.386 2.515.772 0 1.159-.574 2.57.388 3.862.962 1.293 1.3 2.317 1.3 2.317s2.852-.52 3.037.32c.186.839.71 6.296-2.902 6.614-3.612.32-4.388 2.704-4.59 4.248l-4.203-.067s-2.582-5.087-4.59.252l-4.456-.134s.777-1.931-1.94-3.543"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M14.316 18.069s3.007-1.473 7.364-.355m-6.75-8.236a1.84 1.84 0 100-3.682 1.84 1.84 0 000 3.682z"
      />
    </svg>
  )
}
export default SvgSymbolicmoney
