/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicplusSimple = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
      <path
        fill="currentColor"
        d="M3 13.07c0-.485.393-.877.877-.877h16.246a.877.877 0 010 1.754H3.877A.877.877 0 013 13.07zm9.23-9.23c.484 0 .877.393.877.877v16.246a.877.877 0 11-1.754 0V4.717c0-.484.392-.877.877-.877z"
      />
    </svg>
  )
}
export default SvgSymbolicplusSimple
