/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicchange = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentcolor"
        stroke="currentcolor"
        strokeWidth={0.19}
        d="M20.871 11.609a.653.653 0 00-.408-.687l-.035.089.035-.089a.653.653 0 00-.767.222l.076.056-.076-.056a7.821 7.821 0 01-6.289 3.194c-.432 0-.866-.036-1.296-.11v-.877c0-.316-.063-.59-.194-.807h0a.953.953 0 00-.827-.478c-.335 0-.658.164-.96.465h0l-3.162 3.163h0a1.879 1.879 0 000 2.654l3.163 3.163.067-.067-.067.067c.301.301.624.465.96.465a.94.94 0 00.706-.319c.192-.216.314-.537.314-.966v-.78c4.496-.309 8.206-3.774 8.76-8.302zm0 0l-.094-.012.094.012s0 0 0 0zm-3.28 3.018a8.99 8.99 0 001.34-.854m-1.34.854l-.045-.084m.044.084s0 0 0 0l-.044-.084m.044.084a9.112 9.112 0 01-6.283.772h0a.653.653 0 01-.502-.636v-1.058m8.127.068l-1.386.77m1.386-.77a8.19 8.19 0 01-7.474 4.853.653.653 0 00-.653.653v1.058l-2.913-2.913-.068.067.068-.067a.571.571 0 010-.806m9.654-2.075a9.017 9.017 0 01-6.217.763.558.558 0 01-.43-.543V13.61l-.094.095m0 0l-2.913 2.913 2.913-3.047v.134zm1.21.604v-.958c0-.304-.06-.559-.18-.758l.18 1.716z"
      />
      <path
        fill="currentcolor"
        stroke="currentcolor"
        strokeWidth={0.19}
        d="M3.91 12.271a.653.653 0 00.408.687l.035-.088-.035.088a.651.651 0 00.767-.221l-.076-.056.076.056a7.822 7.822 0 016.288-3.195c.433 0 .867.037 1.297.11v.877c0 .316.063.59.194.807h0a.953.953 0 00.827.478c.335 0 .658-.164.96-.465h0l3.162-3.163h0a1.879 1.879 0 000-2.653L14.65 2.369l-.067.068.067-.068c-.301-.3-.624-.464-.96-.464a.94.94 0 00-.706.318c-.193.216-.314.538-.314.967v.78c-4.496.308-8.206 3.773-8.76 8.301zm0 0l.094.012-.094-.011s0 0 0 0zm3.28-3.018c-.47.244-.919.531-1.34.854m1.34-.854l.045.085m-.044-.085s0 0 0 0l.044.085m-.044-.085a9.112 9.112 0 016.282-.771h0c.295.07.503.333.503.636v1.057m-8.127-.068l1.386-.77m-1.386.77a8.19 8.19 0 017.474-4.853c.36 0 .653-.292.653-.653V3.544l2.913 2.912.068-.067-.068.067a.57.57 0 010 .806M7.235 9.338a9.017 9.017 0 016.217-.763.56.56 0 01.43.543v1.152l.094-.095m0 0l2.913-2.913-2.913 3.048v-.135zm-1.21-.604v.958c0 .304.06.56.18.758l-.18-1.716z"
      />
    </svg>
  )
}
export default SvgSymbolicchange
