/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicfake = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...props}>
      <path d="M22.16 3.18A6.5 6.5 0 0017.22.92c-1.43 0-2.75.47-3.9 1.41-.35.28-.68.6-.98.96-.31-.35-.64-.67-.98-.96A6.06 6.06 0 007.46.92c-1.9 0-3.66.8-4.94 2.26A8.25 8.25 0 00.58 8.65c0 2.15.74 4.1 2.35 6.14 1.38 1.75 3.32 3.5 5.58 5.52.76.68 1.62 1.45 2.51 2.27a1.93 1.93 0 002.63 0c.9-.82 1.75-1.6 2.52-2.28 2.25-2.01 4.2-3.76 5.57-5.51 1.61-2.04 2.36-4 2.36-6.14 0-2.1-.69-4.04-1.94-5.47zm-7.2 1.4a3.5 3.5 0 012.26-.83c1.15 0 2.2.48 2.96 1.35.8.9 1.23 2.17 1.23 3.55 0 1.47-.54 2.81-1.74 4.33-1.23 1.56-3.09 3.23-5.25 5.17l-2.08 1.88-2.08-1.88C8.1 16.2 6.24 14.55 5 12.98c-1.2-1.52-1.74-2.86-1.74-4.33 0-1.39.44-2.64 1.23-3.55a3.89 3.89 0 012.96-1.35c.82 0 1.58.28 2.26.83.62.5 1.06 1.16 1.32 1.61.27.49.76.78 1.3.78s1.03-.29 1.3-.78c.26-.46.7-1.11 1.32-1.61z" />
    </svg>
  )
}
export default SvgSymbolicfake
