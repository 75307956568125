/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccode = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 34 34" {...props}>
      <path
        stroke="currentcolor"
        strokeMiterlimit={13.33}
        strokeWidth={2}
        d="M17 32.998a5.095 5.095 0 01-3.401-1.3 5.687 5.687 0 00-4.286-1.43 5.159 5.159 0 01-5.581-5.581A5.714 5.714 0 002.3 20.401a5.095 5.095 0 010-6.804 5.72 5.72 0 001.43-4.286A5.158 5.158 0 019.313 3.73a5.691 5.691 0 004.285-1.43 5.095 5.095 0 016.804 0 5.679 5.679 0 004.286 1.43 5.158 5.158 0 015.581 5.581 5.715 5.715 0 001.431 4.286 5.094 5.094 0 010 6.804 5.72 5.72 0 00-1.43 4.286 5.159 5.159 0 01-5.581 5.581 5.691 5.691 0 00-4.285 1.43 5.095 5.095 0 01-3.402 1.3z"
      />
      <path
        stroke="currentcolor"
        strokeMiterlimit={13.33}
        strokeWidth={2}
        d="M16.29 23.457a.87.87 0 001.561 0l1.336-2.708a.871.871 0 01.655-.477l2.988-.434a.87.87 0 00.481-1.484c-.766-.745-1.683-1.64-2.162-2.107a.87.87 0 01-.25-.77l.51-2.976a.87.87 0 00-1.262-.917l-2.672 1.405a.868.868 0 01-.81 0c-.591-.312-1.726-.908-2.672-1.405a.87.87 0 00-1.262.917l.51 2.975a.87.87 0 01-.25.77c-.478.468-1.397 1.363-2.161 2.108a.87.87 0 00.48 1.484l2.988.434c.284.041.529.22.655.477l1.336 2.708h.002z"
      />
    </svg>
  )
}
export default SvgSymboliccode
