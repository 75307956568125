/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicnext = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 12" {...props}>
      <path
        fill="currentColor"
        d="M6.495 6L1.19 9.912c-.336.24-.48.648-.48.936 0 .624.528 1.152 1.152 1.152.264 0 .48-.072.672-.216l6.36-4.824c.264-.216.528-.504.528-.96 0-.456-.264-.744-.528-.96L2.535.216A1.073 1.073 0 001.863 0C1.239 0 .71.528.71 1.152c0 .288.144.696.48.936L6.495 6z"
      />
    </svg>
  )
}
export default SvgSymbolicnext
