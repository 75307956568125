/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicdashboard = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 25" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.12}
        d="M10.9 3.2h2.29c.72 0 1.31.59 1.31 1.31v10.7c0 .72-.59 1.31-1.31 1.31H3.31c-.72 0-1.31-.59-1.31-1.31V4.51c0-.72.59-1.31 1.31-1.31h2.65"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.12}
        d="M14.49 10.38h4.98c.92 0 1.67.75 1.67 1.67v9.98c0 .92-.75 1.67-1.67 1.67h-9.15c-.92 0-1.67-.75-1.67-1.67V16.7"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.91}
        d="M8.25 5.16a2.08 2.08 0 100-4.16 2.08 2.08 0 000 4.16z"
      />
    </svg>
  )
}
export default SvgSymbolicdashboard
