/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicupDown = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...props}>
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M18.467 15.452V6.369a.76.76 0 00-.747-.773.76.76 0 00-.747.773v9.082l-2.996-2.996a.746.746 0 10-1.055 1.055l4.27 4.27a.742.742 0 00.727.192.745.745 0 00.334-.197l4.268-4.268a.746.746 0 10-1.055-1.052l-3 2.997zM7.012 8.144v9.082a.76.76 0 00.746.774.76.76 0 00.747-.774V8.145l2.997 2.996a.746.746 0 101.055-1.055l-4.27-4.271a.743.743 0 00-1.056 0l-.002.002-4.271 4.271a.746.746 0 101.055 1.053l2.999-2.997z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicupDown
