/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicradioOff = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" {...props}>
      <circle cx={13} cy={13} r={12} stroke="currentcolor" strokeWidth={2} />
    </svg>
  )
}
export default SvgSymbolicradioOff
