/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliclove = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 21" {...props}>
      <path
        fill="currentColor"
        d="M27.19 1.92a6.33 6.33 0 00-9.16.1A6 6 0 0013.47 0c-1.78 0-3.41.68-4.6 1.92a6.63 6.63 0 00-1.82 4.64c0 .88.17 1.72.51 2.56A4.27 4.27 0 004.54 7.9 4.47 4.47 0 000 12.52c0 1.28.49 2.44 1.53 3.65.88 1.01 2.1 2.02 3.53 3.18l1.58 1.3a1.51 1.51 0 001.94 0l1.58-1.3c1.42-1.16 2.65-2.17 3.53-3.18l.17-.2.6.49c.7.57 1.51 1.23 2.34 1.93a1.92 1.92 0 002.46 0l2.34-1.94c2.1-1.71 3.92-3.2 5.2-4.68 1.5-1.73 2.2-3.39 2.2-5.21 0-1.78-.64-3.43-1.81-4.64zm-16 8.32c-.1-.1-.16-.2-.24-.29.55.06 1.06.29 1.43.68.37.39.6.9.68 1.47a20.4 20.4 0 01-1.88-1.86zm-2.58.98l.09-.13c.17.22.35.45.55.68.81.94 1.83 1.87 3 2.87l-.2.23c-.75.88-1.91 1.83-3.27 2.93l-1.17.96-1.17-.96a28.14 28.14 0 01-3.28-2.93c-.73-.84-1.04-1.54-1.04-2.35 0-.74.26-1.4.72-1.89.43-.45 1.04-.7 1.7-.7.47 0 .9.14 1.3.42.36.27.62.63.77.87.22.34.59.55 1 .55s.79-.2 1-.55zm5.33-1.96A4.49 4.49 0 009.81 8a4.35 4.35 0 01-.24-1.43c0-1.17.4-2.24 1.14-3a3.79 3.79 0 012.76-1.15c.77 0 1.48.23 2.11.7.58.43 1 .98 1.23 1.37.26.41.72.66 1.22.66s.96-.25 1.22-.66c.24-.39.65-.94 1.23-1.37.62-.47 1.34-.7 2.1-.7 1.07 0 2.06.4 2.77 1.14a4.3 4.3 0 011.14 3.01c0 1.25-.5 2.39-1.62 3.68-1.14 1.32-2.88 2.74-4.9 4.39l-1.94 1.6c-.69-.58-1.35-1.12-1.95-1.6l-1.03-.85a4.7 4.7 0 00-1.1-4.52z"
      />
    </svg>
  )
}
export default SvgSymboliclove
