/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsound = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M2.453 7.7h3.573l5.315-4.069c.219-.165.49-.254.769-.254.676 0 1.233.528 1.233 1.183v13.88c0 .65-.55 1.183-1.233 1.183a1.28 1.28 0 01-.769-.254l-5.315-4.063H2.453c0-.013.034-7.643 0-7.605zM19.3 5.271c3.6 3.453 3.6 9.043 0 12.495m-3.041-9.34a4.303 4.303 0 010 6.263"
      />
    </svg>
  )
}
export default SvgSymbolicsound
