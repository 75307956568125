/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliclifebuoy = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 49" {...props}>
      <g clipPath="url(#symbolic_lifebuoy_svg__symbolic_lifebuoy_svg__clip0_17264_185308)">
        <path
          fill="currentColor"
          d="M24 4.5c11.046 0 20 8.954 20 20s-8.954 20-20 20-20-8.954-20-20 8.954-20 20-20zm0 30a9.974 9.974 0 01-3.656-.69l-4.472 4.474A15.926 15.926 0 0024 40.5c2.86.004 5.667-.761 8.128-2.216l-4.472-4.474A9.974 9.974 0 0124 34.5zm-16-10c0 2.968.808 5.746 2.216 8.128l4.474-4.472A9.974 9.974 0 0114 24.5c0-1.29.244-2.522.69-3.656l-4.474-4.472A15.926 15.926 0 008 24.5zm29.784-8.128l-4.474 4.472A9.962 9.962 0 0134 24.5c0 1.29-.244 2.522-.69 3.656l4.474 4.472A15.926 15.926 0 0040 24.5a15.926 15.926 0 00-2.216-8.128zM24 18.5a6 6 0 100 12 6 6 0 000-12zm0-10a15.926 15.926 0 00-8.128 2.216l4.472 4.474A9.974 9.974 0 0124 14.5c1.29 0 2.522.244 3.656.69l4.472-4.474A15.926 15.926 0 0024 8.5z"
        />
      </g>
      <defs>
        <clipPath id="symbolic_lifebuoy_svg__symbolic_lifebuoy_svg__clip0_17264_185308">
          <rect width={48} height={48} y={0.5} fill="#fff" rx={12} />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymboliclifebuoy
