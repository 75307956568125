/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicwebinar = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 37 36" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.98}
        d="M33.666 15.104v6.816a3.43 3.43 0 01-3.436 3.423H7.102a3.43 3.43 0 01-3.436-3.423V7.923A3.43 3.43 0 017.102 4.5h15.232"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.98}
        d="M18.589 16.589a4.066 4.066 0 004.073-4.058 4.066 4.066 0 00-4.073-4.058 4.066 4.066 0 00-4.073 4.058 4.066 4.066 0 004.073 4.058zm-7.843 8.277c0-4.357 3.546-7.89 7.92-7.89 4.374 0 7.92 3.533 7.92 7.89m-11.363.652v4.923m6.882-4.923v4.923m-10.292.538h13.705"
      />
      <path
        fill="currentcolor"
        d="M28.815 5.392l3.387 2.157c.848.54.82 1.77-.053 2.273l-3.386 1.95c-.906.52-2.044-.121-2.044-1.153V6.513c0-1.058 1.193-1.696 2.096-1.12v-.001z"
      />
    </svg>
  )
}
export default SvgSymbolicwebinar
