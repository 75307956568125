/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicchaptersLines = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M3.5 7h18m-18 5h12m-12 5h8"
      />
    </svg>
  )
}
export default SvgSymbolicchaptersLines
