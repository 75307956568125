/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicpencil = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 34" {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={3}
        d="M23.347 1.963l1.814.898c1.654.783 2.322 2.773 1.539 4.426l-8.553 17.547c-.024.065-.08.118-.115.161l-7.194 5.936a.47.47 0 01-.767-.378l.214-9.332c.002-.055.015-.142.05-.185L18.888 3.49c.805-1.664 2.795-2.33 4.459-1.526zM25.79 9.13l-7.81-3.79m.37 19.05l-7.79-3.81M1.85 32h20.33"
      />
    </svg>
  )
}
export default SvgSymbolicpencil
