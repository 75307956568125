/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicangerRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.4}
        d="M12.162 30.927C8.512 30.688 6 28.953 6 25.424c0-4.367 5.932-8.535 10-5.424 4.068 3.11-.547 7.278-3 3.45-2.453-3.83 2.093-12.623 9.93-13.4 7.836-.778 11.127 7.896 11.127 7.896s6.4-.539 6.88 5.503c.657 8.555-8.576 7.359-11.937 7.478"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.4}
        d="M23.155 24s-11.675 9.754-8.657 9.822c3.018.068 4.527-.273 3.257 1.5-1.271 1.774-2.679 5.763-.932 4.399 1.748-1.296 9.51-7.945 7.841-7.945-1.668 0-3.812 0-3.018-.75L28 25.568"
      />
    </svg>
  )
}
export default SvgSymbolicangerRebrand
