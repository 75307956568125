/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicflash = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 28" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.079 1.087c.526-.498 1.246-.494 1.738-.248l.006.003 7.642 3.926c1.11.545 1.112 2.038.148 2.66m-.002 0l-4.541 2.93 4.344 2.23c1.19.58 1.031 2.084.155 2.668l-.029.02L2.978 27.458l-.099.04c-1.577.619-2.862-1.261-1.844-2.516l8.034-10.697-4.101-2.108-.012-.007c-.935-.503-1.066-1.735-.358-2.443l.021-.022 9.46-8.618m1.226 2.468l-7.688 7.004 3.95 2.03.025.015c.365.208.612.557.702.941a1.44 1.44 0 01-.288 1.242L6.265 22.43l14.169-8.395-4.29-2.201a1.531 1.531 0 01-.834-1.288 1.525 1.525 0 01.703-1.37l4.537-2.928-5.245-2.694zm6.924 11.402a1.38 1.38 0 01-.024-.012l.024.013z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicflash
