/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicappointment = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M16.122 10.5v4.655a.854.854 0 01-.838.838H3.838A.854.854 0 013 15.155V4.838C3 4.387 3.387 4 3.838 4h11.51a.76.76 0 01.742.613c.193 1.128 1.01 5.05-1.196 3.887-2.207-1.163 1.067-3.5 2.711-2.952 1.645.548 3.998 10.027 3.901 10.478-.129.419-.096.548-1.805 1.193-1.548.58-8.125 2.966-10.092 3.16a.606.606 0 01-.644-.388L8.394 18.5"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M12.395 8.42c-.376.69-.922 1.675-1.571.394-.649-1.28-1.093.099-1.57.64-.547.64-1.47-1.92-2.152-1.378-.683.541-1.025 2.708-1.707 1.625m5 2.114c-.598-1.28-1.007.098-1.447.64-.503.64-1.352-1.92-1.981-1.38-.63.543-.944 2.71-1.572 1.626"
      />
    </svg>
  )
}
export default SvgSymbolicappointment
