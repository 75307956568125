/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolictouch = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 37 36" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M15.346 33.206s-3.568-1.486-1.52-7.484c2.573-7.485 9.999-3.504 5.395-.915-2.762.915-2.296-3.653-3.934-8.056-1.637-4.403-2.456-6.879 1.58-7.65 4.035-.77 6.083 10.127 6.083 10.127s4.562.44 6.2 2.091c1.637 1.651 1.052 10.677 1.052 10.677"
      />
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.8}
        d="M6.652 17.246S1.68 10.532 8.172 6.68m2.497 7.925c-1.287-2.587-.936-4.348.526-5.668m9.649-1.982c2.749 1.21 3.392 2.697 3.158 4.568m-.459-7.848s7.538 1.2 5.323 9.547"
      />
    </svg>
  )
}
export default SvgSymbolictouch
