/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicbookOpen = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M7.92 12.777s.109-9.302.08-9.302c-.063-.746-.71-1.33-1.5-1.33H1.708a.53.53 0 00-.537.518v8.945a.53.53 0 00.537.518h4.865c.237 0 .456.1.607.278l.277.323c.075.089.184.14.306.14h.479c.115 0 .23-.051.306-.14l.277-.323a.804.804 0 01.606-.278h4.865a.53.53 0 00.537-.518v-8.95a.53.53 0 00-.537-.519h-4.79c-.791 0-1.438.585-1.501 1.33"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M6.448 6.108L4.422 8.814 3.129 7.45m7.203-1.403l2.274 2.193m-2.274-.005l2.274-2.193"
      />
    </svg>
  )
}
export default SvgSymbolicbookOpen
