/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccopy = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 18" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M9.891 4.587l2.689-2.4c1.255-1.112 3.194-1.03 4.333.185a2.956 2.956 0 01-.19 4.233l-4.46 3.976a3.096 3.096 0 01-4.29-.185l-.043-.042c-.042-.04-.073-.082-.116-.123"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M8.753 13.722l-2.32 2.07c-1.255 1.113-3.194 1.03-4.333-.185a2.956 2.956 0 01.19-4.233l4.217-3.77c1.212-1.081 3.11-.999 4.217.196l.105.113"
      />
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M3.207 2.413L5.642 5.01M6.94 1.177l-.117 2.41m-5.271 2.4l2.467.114m11.839 9.486l-2.435-2.606m-1.286 3.842l.105-2.41m5.282-2.4l-2.467-.114"
      />
    </svg>
  )
}
export default SvgSymboliccopy
