/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicdaytimeMorning = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={22.93}
        strokeWidth={1.6}
        d="M1.57 14.184c9.154 0 10.889 3.539 17.275 4.607m3.915-4.607c-5.177 0-7.98 1.132-10.595 2.31"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={22.93}
        strokeWidth={1.6}
        d="M9.916 9.271a5.168 5.168 0 017.36 5.43m-10.224-.017a5.165 5.165 0 01.438-2.965m4.675-7.444v-.861M7.182 6.088l-.554-.66M4.53 10.681l-.848-.15m16.118.15l.848-.15m-3.5-4.442l.554-.66"
      />
    </svg>
  )
}
export default SvgSymbolicdaytimeMorning
