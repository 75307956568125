/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicfamily = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 45 34" {...props}>
      <g fill="currentColor" clipPath="url(#symbolic_family_svg__symbolic_family_svg__clip0)">
        <path d="M11.482 14.89c-3.74 0-6.818-3.042-6.906-6.9C4.4 3.994 7.39.68 11.218.544c1.847-.046 3.607.635 4.97 1.906 1.364 1.317 2.112 3.087 2.2 4.994.132 3.949-2.86 7.263-6.686 7.399-.088 0-.176.045-.22.045zm0-11.168h-.132c-2.111.091-3.783 1.907-3.74 4.131.089 2.179 1.848 3.904 4.004 3.859 2.111-.091 3.783-1.907 3.739-4.131a4.11 4.11 0 00-1.232-2.77c-.748-.68-1.671-1.089-2.64-1.089zM32.464 14.3c-3.695 0-6.818-3.041-6.95-6.9a7.492 7.492 0 011.847-5.13C28.637.863 30.352.092 32.2 0a6.996 6.996 0 014.97 1.907c1.364 1.316 2.112 3.086 2.2 4.993a7.492 7.492 0 01-1.847 5.13c-1.276 1.406-2.992 2.178-4.84 2.269h-.22zm0-11.121h-.132a3.873 3.873 0 00-2.683 1.27c-.704.772-1.056 1.77-1.056 2.86.088 2.18 1.847 3.904 4.003 3.859a3.876 3.876 0 002.683-1.271c.704-.772 1.056-1.77 1.056-2.86a4.11 4.11 0 00-1.232-2.769c-.704-.726-1.671-1.09-2.64-1.09zM22.346 24.513a5.223 5.223 0 01-3.651-1.498 5.568 5.568 0 01-1.716-3.859 5.726 5.726 0 011.452-3.994 5.252 5.252 0 013.739-1.77c2.947-.092 5.454 2.314 5.542 5.356.088 3.041-2.243 5.629-5.19 5.72-.044.045-.132.045-.176.045zm0-7.899h-.088a2 2 0 00-1.584.772c-.44.454-.66 1.09-.616 1.68 0 .635.264 1.225.748 1.634.44.454 1.012.68 1.628.635 1.275-.045 2.243-1.135 2.2-2.451-.045-1.271-1.057-2.27-2.288-2.27z" />
        <path d="M15.528 32.23c-.836 0-1.496-.681-1.54-1.544-.176-4.857 3.563-8.942 8.27-9.124h.22c4.707-.182 8.666 3.677 8.842 8.534.044.863-.616 1.634-1.496 1.634-.88.046-1.583-.635-1.583-1.543-.088-3.132-2.64-5.538-5.63-5.447h-.22c-3.036.09-5.367 2.723-5.28 5.81.045.863-.615 1.634-1.495 1.634-.044.046-.088.046-.088.046z" />
        <path d="M22.742 34c-7.522 0-15.088-1.316-19.09-3.677C.484 28.462-.088 26.283 0 24.83c.308-5.81 3.564-10.485 8.402-12.21 4.267-1.544 8.754-.318 11.745 3.132.572.635.528 1.68-.132 2.224-.616.59-1.627.545-2.155-.136-2.2-2.497-5.323-3.36-8.402-2.224-2.903.998-6.07 4.04-6.378 9.396-.044 1.044 1.1 1.952 2.067 2.542 7.082 4.176 27.713 4.676 34.883-.318 2.111-1.452 1.935-2.814 1.891-3.268-.792-5.992-4.663-8.761-8.006-9.351-2.99-.545-5.63.59-6.774 2.86-.396.771-1.32 1.09-2.067.68a1.61 1.61 0 01-.66-2.133c1.803-3.495 5.718-5.266 10.073-4.494 4.223.772 9.502 4.403 10.513 12.075.22 1.68-.176 4.176-3.21 6.31C37.653 32.729 30.175 34 22.741 34z" />
      </g>
      <defs>
        <clipPath id="symbolic_family_svg__symbolic_family_svg__clip0">
          <path fill="#fff" d="M0 0h45v34H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicfamily
