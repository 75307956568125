/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccancel = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M32 16a16 16 0 11-32 0 16 16 0 0132 0zm-20.36 6.3l4.48-4.48 4.5 4.5a1.18 1.18 0 101.68-1.67l-4.5-4.5 4.52-4.53a1.18 1.18 0 10-1.68-1.67l-4.52 4.52-4.5-4.5a1.18 1.18 0 10-1.68 1.67l4.5 4.5-4.48 4.48a1.18 1.18 0 101.68 1.68z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymboliccancel
