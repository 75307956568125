/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolichat = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 43 38" {...props}>
      <g
        fill="currentColor"
        fillRule="evenodd"
        clipPath="url(#symbolic_hat_svg__symbolic_hat_svg__clip0)"
        clipRule="evenodd"
      >
        <path d="M14.09 26.318a3.812 3.812 0 100 7.624 3.812 3.812 0 000-7.624zM7.122 30.13A6.968 6.968 0 0119.5 25.737l.808-.33a3.154 3.154 0 012.356 0l.004.002.811.332a6.968 6.968 0 11-1.384 2.844l-.609-.25-.6.246a6.968 6.968 0 11-13.763 1.55zm21.768-3.812a3.812 3.812 0 100 7.624 3.812 3.812 0 000-7.624zM12.548 11.273l2.062-6.825c.28-.923.92-1.29 1.4-1.29h1.9c.27 0 .575.108.855.378l.003.003c1.488 1.427 3.665 1.68 5.403.543l.003-.002 1.105-.716.003-.002a1.14 1.14 0 01.638-.194h1.25c.483 0 1.132.378 1.406 1.308l2.01 6.854c-5.934-2.091-12.1-2.11-18.038-.056zM8.765 12.88l2.825-9.348c.6-1.976 2.28-3.53 4.42-3.53h1.9c1.15 0 2.224.471 3.043 1.26.471.45 1.032.48 1.493.178l.005-.004 1.107-.718h.002a4.294 4.294 0 012.36-.706h1.25c2.157 0 3.848 1.582 4.434 3.572v.002l2.746 9.368a32.8 32.8 0 012.361 1.335l5.51 3.41a1.578 1.578 0 01-.83 2.919H1.58a1.578 1.578 0 01-.831-2.92l5.509-3.41a33.141 33.141 0 012.506-1.408zm-.844 4.091c8.751-5.424 18.377-5.424 27.128 0l.793.491H7.128l.793-.49z" />
      </g>
      <defs>
        <clipPath id="symbolic_hat_svg__symbolic_hat_svg__clip0">
          <path fill="#fff" d="M0 0h42.97v37.1H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolichat
