/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicsadRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" {...props}>
      <path
        stroke="#E47B53"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.4}
        d="M16.826 32s2.031 4.712.75 7.034c-1.361 2.399-4.805-.155-3.123-2.4 1.281-1.78 3.043-2.708 3.043-2.708M24.852 28s2 4.403.72 6.943c-1.36 2.625-4.8-.17-3.12-2.625 1.28-1.947 3.04-2.455 3.04-2.455"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.4}
        d="M12.162 30.927C8.512 30.688 6 28.953 6 25.424c0-4.367 5.932-8.535 10-5.424 4.068 3.11-.547 7.278-3 3.45-2.453-3.83 2.093-12.623 9.93-13.4 7.836-.778 11.127 7.896 11.127 7.896s6.4-.539 6.88 5.503c.657 8.555-6.576 7.359-9.937 7.478"
      />
    </svg>
  )
}
export default SvgSymbolicsadRebrand
