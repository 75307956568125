/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicstar = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#symbolic_star_svg__symbolic_star_svg__clip0_9131_5113)">
        <path
          fill="currentcolor"
          d="M8 11.514L12.12 14l-1.093-4.687 3.64-3.153-4.793-.407-1.873-4.42-1.874 4.42-4.793.407 3.64 3.153L3.881 14 8 11.514z"
        />
      </g>
      <defs>
        <clipPath id="symbolic_star_svg__symbolic_star_svg__clip0_9131_5113">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicstar
