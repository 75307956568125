/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicdislike = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        stroke="currentcolor"
        strokeMiterlimit={10}
        d="M5.117 1.855H3.048c-.655 0-1.188.522-1.188 1.162v4.495c0 .641.533 1.163 1.188 1.163h2.069v-6.82z"
      />
      <path
        stroke="currentcolor"
        strokeMiterlimit={10}
        d="M4.393 1.855h7.805a1.08 1.08 0 011.077.913l.852 5.833c.093.64-.417 1.219-1.078 1.219H9.422v2.794c0 .845-.701 1.53-1.564 1.53a.62.62 0 01-.574-.373L5.117 8.686"
      />
    </svg>
  )
}
export default SvgSymbolicdislike
