/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicfacebook = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22" {...props}>
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M13.454 2.003s-2.862-.201-3.596 2.73c0 0-.266.77-.23 2.665L7 7.406v2.48l2.629-.009V20h3.35V9.885h2.657V7.398H12.98v-1.35c0-.877.452-1.714 1.773-1.714H16V2.003h-2.546z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default SvgSymbolicfacebook
