/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicvisa = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 34 12" {...props}>
      <g clipPath="url(#symbolic_visa_svg__symbolic_visa_svg__clip0_8382_107959)">
        <path
          fill="#1434CB"
          d="M21.908.624c-2.364 0-4.478 1.221-4.478 3.478 0 2.587 3.748 2.766 3.748 4.066 0 .548-.63 1.038-1.705 1.038-1.526 0-2.666-.685-2.666-.685l-.488 2.277s1.314.578 3.058.578c2.585 0 4.62-1.281 4.62-3.576 0-2.735-3.764-2.908-3.764-4.115 0-.428.517-.898 1.59-.898 1.21 0 2.196.498 2.196.498l.478-2.2S23.423.624 21.908.624zM.273.79l-.057.332s.995.181 1.89.543c1.154.415 1.237.657 1.43 1.407l2.118 8.133h2.838L12.865.79h-2.832l-2.81 7.08-1.145-6C5.973 1.183 5.44.79 4.788.79H.274zm13.73 0l-2.22 10.415h2.7L16.696.79h-2.692zm15.061 0c-.65 0-.996.347-1.249.954l-3.956 9.461h2.832l.548-1.577h3.45l.333 1.577h2.498L31.34.79h-2.276zm.369 2.814l.84 3.908h-2.25l1.41-3.908z"
        />
      </g>
      <defs>
        <clipPath id="symbolic_visa_svg__symbolic_visa_svg__clip0_8382_107959">
          <path fill="#fff" d="M0 0h33.34v10.79H0z" transform="translate(.2 .607)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicvisa
