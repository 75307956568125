/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolictiktok = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M15.01 4.012a4.034 4.034 0 01-4.04-4H8.362V7.14l-.004 3.904A2.36 2.36 0 015.86 13.4a2.351 2.351 0 01-1.08-.331 2.36 2.36 0 01-1.154-1.99 2.363 2.363 0 013.11-2.276V6.155a5.133 5.133 0 00-.752-.056c-1.443 0-2.793.6-3.758 1.681a4.893 4.893 0 00-1.234 2.952 4.887 4.887 0 001.455 3.807 5.002 5.002 0 003.537 1.448 4.985 4.985 0 003.537-1.448 4.884 4.884 0 001.465-3.475l-.013-5.83a6.593 6.593 0 004.044 1.374V4.012h-.007z"
      />
    </svg>
  )
}
export default SvgSymbolictiktok
