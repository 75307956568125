/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicblik = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135.64 64.18" {...props}>
      <linearGradient
        id="symbolic_blik_svg__a"
        x1={67.82}
        x2={67.82}
        y1={63.05}
        y2={1.13}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5a5a5a" />
        <stop offset={0.15} stopColor="#484848" />
        <stop offset={0.52} stopColor="#212121" />
        <stop offset={0.82} stopColor="#080808" />
        <stop offset={1} />
      </linearGradient>
      <linearGradient id="symbolic_blik_svg__b" x1={39.67} x2={49.7} y1={19.9} y2={9.87} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#e52f08" />
        <stop offset={1} stopColor="#e94f96" />
      </linearGradient>
      <filter id="symbolic_blik_svg__c" width={99.4} height={50.16} x={21.71} y={10.07} filterUnits="userSpaceOnUse">
        <feOffset dx={2.38} dy={2.97} />
        <feGaussianBlur result="blur" stdDeviation={0.74} />
        <feFlood floodOpacity={0.95} />
        <feComposite in2="blur" operator="in" result="result1" />
        <feComposite in="SourceGraphic" in2="result1" />
      </filter>
      <path
        fill="url(#symbolic_blik_svg__a)"
        d="M127.725.827H7.915A7.083 7.083 0 00.828 7.906v48.368a7.082 7.082 0 007.087 7.078h119.81a7.082 7.082 0 007.086-7.078V7.906a7.083 7.083 0 00-7.086-7.079z"
      />
      <path
        fill="url(#symbolic_blik_svg__b)"
        d="M51.769 14.884a7.088 7.088 0 01-7.088 7.088 7.088 7.088 0 01-7.088-7.088 7.088 7.088 0 017.088-7.088 7.088 7.088 0 017.088 7.088z"
      />
      <path
        fill="#fff"
        d="M106.28 55.03h10.206l-12.262-15.837 11.119-13.608h-9.257L95.167 39.278v-29.2h-7.925V55.03h7.925l-.006-15.714zM72.294 25.58h7.923v29.445h-7.923zM57.34 10.069h7.923v44.956H57.34zM36.741 25.286a14.968 14.968 0 00-7.108 1.784v-17h-7.924v30.242a15.03 15.03 0 1015.032-15.026zm0 22.26a7.233 7.233 0 117.233-7.234 7.231 7.231 0 01-7.233 7.234z"
        filter="url(#symbolic_blik_svg__c)"
      />
    </svg>
  )
}
export default SvgSymbolicblik
