/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolichappy = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 19" {...props}>
      <path
        fill="currentColor"
        d="M19.45 19c-6.24 0-12.34-1.71-17.65-4.95a1.77 1.77 0 111.84-3.02 30.39 30.39 0 0031.62 0 1.77 1.77 0 111.84 3.02 33.9 33.9 0 01-17.65 4.94zM11.18 5.2a2.6 2.6 0 100-5.2 2.6 2.6 0 000 5.2zm16.54 0a2.6 2.6 0 100-5.2 2.6 2.6 0 000 5.2z"
      />
    </svg>
  )
}
export default SvgSymbolichappy
