/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicheartFull = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18" {...props}>
      <path
        fill="currentcolor"
        d="M.968 8.614c.909-.665 2.09-.443 2.14-.43a.405.405 0 01.171.078l4.607 3.667c.787-.593 3.177-2.434 4.321-3.752.33-.378.465-.757.415-1.148-.1-.784-.922-1.364-.93-1.37a.405.405 0 00-.171-.08 4.185 4.185 0 00-3.12.732.902.902 0 01-1.03 0C4.595 4.359 1.126 6.29.54 8.83c-.014.065.079.104.122.052.093-.098.2-.19.307-.268z"
      />
      <path
        fill="currentcolor"
        d="M13.145 6.16c.128.229.221.49.264.77.079.588-.114 1.162-.58 1.69-1.437 1.645-4.564 3.956-4.7 4.053a.418.418 0 01-.507-.013L2.85 8.862c-.236-.026-.894-.065-1.388.307-.45.333-.68.94-.68 1.788v.013c.215.45.544.894 1.002 1.312l5.652 5.547c.25.228.658.228.908 0l5.652-5.547C16.32 10.16 15.24 7.4 13.145 6.16zM4.713 4.607c1.296 0 2.345-1.031 2.345-2.303C7.058 1.03 6.008 0 4.713 0 3.418 0 2.37 1.031 2.37 2.304c0 1.272 1.05 2.303 2.344 2.303zm6.75 0c1.295 0 2.344-1.031 2.344-2.303C13.807 1.03 12.757 0 11.462 0c-1.294 0-2.344 1.031-2.344 2.304 0 1.272 1.05 2.303 2.345 2.303z"
      />
    </svg>
  )
}
export default SvgSymbolicheartFull
