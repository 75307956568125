/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicweb = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 44 21" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.7 3.58c-.62 0-1.12.5-1.12 1.12v11.05c0 .62.5 1.12 1.12 1.12h34.99c.62 0 1.12-.5 1.12-1.12V4.7c0-.62-.5-1.12-1.12-1.12H4.7zM.42 4.7A4.28 4.28 0 014.7.42h34.99a4.28 4.28 0 014.28 4.28v11.05a4.28 4.28 0 01-4.28 4.28H4.7a4.28 4.28 0 01-4.28-4.28V4.7z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M11.3 7.53c.07-.22.09-.24.32-.23h.72c.24-.01.26.02.32.24l1.08 3.54 1.03-3.54.03-.07c.04-.15.09-.18.29-.17h.98c.22 0 .29.04.29.17l-.04.18-1.68 5.26c-.06.23-.09.24-.32.24h-1.06c-.23.01-.25-.02-.32-.24l-1-3.14-1.04 3.15c-.06.22-.09.24-.32.23H9.53c-.24.01-.26-.02-.32-.24L7.64 7.64c-.03-.1-.03-.13-.03-.17 0-.12.07-.17.29-.17h.98c.24-.01.26.02.32.24l.95 3.53 1.15-3.54zm10.04 0c.07-.22.09-.24.32-.23h.72c.24-.01.26.02.32.24l1.08 3.54 1.03-3.54.03-.07c.04-.15.09-.18.29-.17h.98c.22 0 .29.04.29.17l-.04.18-1.68 5.26c-.06.23-.09.24-.32.24H23.3c-.23.01-.25-.02-.32-.24l-1-3.14-1.04 3.15c-.06.22-.09.24-.32.23h-1.05c-.24.01-.26-.02-.32-.24l-1.57-5.27-.04-.17c0-.12.07-.17.29-.17h.98c.24-.01.26.02.32.24l.95 3.53 1.16-3.54zm10.03 0c.07-.22.09-.24.32-.23h.72c.24-.01.26.02.32.24l1.08 3.54 1.03-3.54.03-.07c.04-.15.09-.18.29-.17h.98c.22 0 .29.04.29.17l-.04.18-1.68 5.26c-.06.23-.09.24-.32.24h-1.06c-.23.01-.25-.02-.32-.24l-1-3.14-1.04 3.15c-.06.22-.09.24-.32.23H29.6c-.24.01-.26-.02-.32-.24l-1.57-5.27c-.03-.1-.03-.13-.03-.17 0-.12.07-.17.29-.17h.98c.24-.01.26.02.32.24l.95 3.53 1.15-3.54z"
      />
    </svg>
  )
}
export default SvgSymbolicweb
