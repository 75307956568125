/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccheckRebrand = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21" {...props}>
      <path
        stroke="#0C4752"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M10.073 6.784c2.247-.946 6.148-3.113 1.685-5.25-1.98-.945-3.96-.518-5.645.367C2.684 3.671.91 7.241.999 11.209c.118 4.181 3.37 7.294 6.385 8.18 2.748.824 6.059.366 8.217-1.557 3.074-2.686 3.635-5.34 3.221-7.477"
      />
      <path
        stroke="#F18C69"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M7.177 10.904s1.36 3.235 2.364 3.144C11.433 13.926 19 2.542 19 2.542"
      />
    </svg>
  )
}
export default SvgSymboliccheckRebrand
