/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicbook = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 31 22" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.13}
        d="M17.78 20.507h11.44c.25 0 .46-.21.46-.46V4.817c0-.25-.21-.46-.46-.46h-3.57"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.13}
        d="M6.63 2.477v15.29c0 .21.14.39.35.44l8.65 2.16c.07.02.15.02.22 0l8.96-2.17c.21-.05.35-.23.35-.45V2.457c0-.3-.29-.52-.58-.44l-8.51 2.3c-.08.02-.16.02-.24 0l-8.62-2.3a.482.482 0 00-.58.46zm9.32 1.88v15.94"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.13}
        d="M17.7 20.507H2.46c-.25 0-.46-.21-.46-.46V4.817c0-.25.21-.46.46-.46h4.06"
      />
    </svg>
  )
}
export default SvgSymbolicbook
