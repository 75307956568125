/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicmobileApp = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.64}
        d="M2 7.386V4.644A2.644 2.644 0 014.644 2h14.712A2.644 2.644 0 0122 4.644v2.742H2zm20 0v14.963m-20 0V7.386m7.98-2.694h4.04"
      />
    </svg>
  )
}
export default SvgSymbolicmobileApp
