/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicgpay = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 38 16" {...props}>
      <g clipPath="url(#symbolic_gpay_svg__symbolic_gpay_svg__clip0_8382_107963)">
        <path
          fill="#5F6368"
          d="M17.93 7.831v4.312h-1.377V1.496h3.65a3.318 3.318 0 012.363.921 2.963 2.963 0 01.987 2.247 2.935 2.935 0 01-.987 2.26c-.638.605-1.426.907-2.363.907h-2.274zm0-5.025v3.717h2.307a1.828 1.828 0 001.361-.55 1.814 1.814 0 00.553-1.301 1.805 1.805 0 00-.553-1.301 1.795 1.795 0 00-1.361-.565h-2.308zm8.794 1.814c1.017 0 1.82.27 2.408.81.589.54.883 1.282.882 2.223v4.49h-1.316v-1.011h-.06c-.57.832-1.327 1.249-2.273 1.249-.808 0-1.483-.238-2.027-.714a2.28 2.28 0 01-.815-1.784c0-.754.287-1.353.86-1.798.573-.446 1.338-.669 2.295-.67.817 0 1.49.149 2.02.446v-.313a1.549 1.549 0 00-.57-1.21 1.955 1.955 0 00-1.327-.499c-.769 0-1.377.322-1.825.967l-1.212-.759c.667-.951 1.653-1.427 2.96-1.427zm-1.78 5.293a1.087 1.087 0 00.456.892c.304.238.682.364 1.069.357a2.2 2.2 0 001.548-.64c.456-.426.684-.926.684-1.5-.43-.34-1.028-.51-1.795-.51-.56 0-1.025.134-1.398.402-.377.272-.564.602-.564.999zM37.57 4.858l-4.595 10.496h-1.42l1.704-3.673-3.021-6.823h1.496l2.183 5.233h.03l2.124-5.233h1.498z"
        />
        <path
          fill="#4285F4"
          d="M12.369 6.902c0-.417-.035-.833-.106-1.244H6.46v2.356h3.324a2.822 2.822 0 01-1.23 1.859v1.529h1.984c1.162-1.064 1.831-2.639 1.831-4.5z"
        />
        <path
          fill="#34A853"
          d="M6.46 12.88c1.66 0 3.059-.543 4.078-1.477l-1.983-1.53c-.552.373-1.263.585-2.095.585-1.605 0-2.967-1.075-3.454-2.525H.962V9.51a6.13 6.13 0 002.269 2.46c.97.595 2.088.91 3.23.91z"
        />
        <path fill="#FBBC04" d="M3.006 7.933a3.644 3.644 0 010-2.341V4.016H.962a6.087 6.087 0 000 5.493l2.044-1.576z" />
        <path
          fill="#EA4335"
          d="M6.46 3.067a3.352 3.352 0 012.36.918l1.757-1.746A5.932 5.932 0 006.46.646c-1.14 0-2.259.315-3.23.91a6.13 6.13 0 00-2.268 2.46l2.044 1.576c.487-1.45 1.85-2.525 3.454-2.525z"
        />
      </g>
      <defs>
        <clipPath id="symbolic_gpay_svg__symbolic_gpay_svg__clip0_8382_107963">
          <path fill="#fff" d="M0 0h37.26v14.71H0z" transform="translate(.307 .646)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicgpay
