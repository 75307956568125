/* eslint-disable max-len */
import * as React from 'react'
const SvgSymboliccancelOutline = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.076 12.744L9.079 15.74a.464.464 0 01-.655 0 .463.463 0 010-.655l2.997-2.997-3.01-3.01a.463.463 0 11.655-.654l3.01 3.01 3.023-3.023a.463.463 0 11.654.654L12.73 12.09l3.01 3.01a.463.463 0 01-.654.654l-3.01-3.01z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.9}
        d="M12.1 22c5.468 0 9.9-4.432 9.9-9.9s-4.432-9.9-9.9-9.9c-5.467 0-9.9 4.432-9.9 9.9S6.633 22 12.1 22z"
      />
      <path
        fill="currentColor"
        d="M8.425 15.74a.463.463 0 010-.654l2.997-2.996-3.01-3.01a.463.463 0 11.654-.655l3.01 3.01L15.1 8.412a.463.463 0 01.655.654L12.73 12.09l3.01 3.01a.463.463 0 01-.655.654l-3.01-3.01L9.08 15.74a.463.463 0 01-.654 0z"
      />
      <path
        fill="currentColor"
        d="M9.079 15.74a.463.463 0 01-.655-.654l2.997-2.997-3.01-3.01a.463.463 0 11.655-.654l3.01 3.01 3.023-3.023a.463.463 0 11.654.654L12.73 12.09l3.01 3.01a.463.463 0 01-.654.655l-3.01-3.01-2.997 2.997zm2.997-2.603l-2.8 2.8a.74.74 0 11-1.048-1.047l2.8-2.8-2.813-2.814a.74.74 0 111.047-1.047l2.814 2.813 2.826-2.826a.74.74 0 111.048 1.047l-2.827 2.826 2.814 2.814a.74.74 0 11-1.048 1.047l-2.813-2.813z"
      />
      <path
        stroke="currentColor"
        d="M9.079 15.74a.463.463 0 01-.655-.654l2.997-2.997-3.01-3.01a.463.463 0 11.655-.654l3.01 3.01 3.023-3.023a.463.463 0 11.654.654L12.73 12.09l3.01 3.01a.463.463 0 01-.654.655l-3.01-3.01-2.997 2.997zm2.997-2.603l-2.8 2.8a.74.74 0 11-1.048-1.047l2.8-2.8-2.813-2.814a.74.74 0 111.047-1.047l2.814 2.813 2.826-2.826a.74.74 0 111.048 1.047l-2.827 2.826 2.814 2.814a.74.74 0 11-1.048 1.047l-2.813-2.813z"
      />
    </svg>
  )
}
export default SvgSymboliccancelOutline
