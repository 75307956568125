/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicslack = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20" {...props}>
      <g
        fill="currentColor"
        clipPath="url(#symbolic_slack_svg__symbolic_slack_svg__symbolic_slack_svg__clip0_23167_102282)"
      >
        <path d="M4.783 12.598c0 1.15-.929 2.079-2.078 2.079-1.15 0-2.079-.93-2.079-2.079 0-1.15.93-2.079 2.079-2.079h2.078v2.08zm1.04 0c0-1.15.929-2.079 2.079-2.079 1.15 0 2.078.93 2.078 2.08v5.196a2.078 2.078 0 11-4.158 0v-5.197zm2.079-8.346a2.078 2.078 0 110-4.157c1.15 0 2.078.929 2.078 2.078v2.08H7.902zm0 1.055c1.15 0 2.078.93 2.078 2.08a2.076 2.076 0 01-2.078 2.078H2.689a2.078 2.078 0 11.001-4.158h5.212zm8.331 2.079a2.078 2.078 0 114.157.001c0 1.149-.93 2.078-2.079 2.078h-2.078V7.386zm-1.04 0c0 1.15-.929 2.079-2.079 2.079-1.15 0-2.078-.93-2.078-2.079V2.173c0-1.15.929-2.078 2.078-2.078 1.15 0 2.08.929 2.08 2.078v5.213zm-2.079 8.33a2.078 2.078 0 110 4.158c-1.15 0-2.078-.93-2.078-2.079v-2.079h2.078zm0-1.039c-1.15 0-2.078-.93-2.078-2.079 0-1.15.929-2.079 2.078-2.079h5.213c1.15 0 2.079.93 2.079 2.08 0 1.149-.93 2.078-2.079 2.078h-5.213z" />
      </g>
      <defs>
        <clipPath id="symbolic_slack_svg__symbolic_slack_svg__symbolic_slack_svg__clip0_23167_102282">
          <path fill="currentColor" d="M0 0h20v20H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgSymbolicslack
