/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicclockSquare = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" {...props}>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.67}
        d="M23.999 14.666v9.333l6.222 6.223m0 12.444H17.776c-6.872 0-12.444-5.572-12.444-12.444V17.777c0-6.873 5.572-12.444 12.444-12.444h12.445c6.873 0 12.444 5.571 12.444 12.444v12.445c0 6.872-5.571 12.444-12.444 12.444z"
      />
    </svg>
  )
}
export default SvgSymbolicclockSquare
