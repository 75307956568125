/* eslint-disable max-len */
import * as React from 'react'
const SvgSymbolicpercentage = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 65 64" {...props}>
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2.6}
        d="M36.496 4.16l.846 1.305a4.738 4.738 0 005.897 1.74l1.426-.628c3.118-1.402 6.647.87 6.67 4.302v1.547a4.75 4.75 0 004.037 4.664l1.522.242c3.384.508 5.124 4.326 3.287 7.226l-.821 1.305a4.73 4.73 0 00.87 6.09l1.16 1.016c2.562 2.248 1.982 6.405-1.136 7.855l-1.402.652a4.75 4.75 0 00-2.562 5.607l.435 1.499c.943 3.287-1.812 6.453-5.196 5.994l-1.547-.218c-2.32-.314-4.52 1.088-5.172 3.335l-.435 1.475c-.99 3.287-5.003 4.47-7.613 2.247L35.578 60.4a4.73 4.73 0 00-6.163 0l-1.184 1.015c-2.61 2.224-6.623 1.04-7.614-2.247l-.435-1.475c-.676-2.223-2.876-3.649-5.172-3.335l-1.546.218c-3.384.459-6.14-2.707-5.197-5.994l.435-1.499a4.725 4.725 0 00-2.562-5.607l-1.401-.652c-3.094-1.45-3.698-5.607-1.136-7.855l1.16-1.015a4.71 4.71 0 00.87-6.09l-.822-1.306c-1.837-2.9-.097-6.718 3.287-7.226l1.523-.242c2.296-.338 4.012-2.32 4.036-4.664v-1.547c.072-3.456 3.601-5.728 6.719-4.326l1.426.628a4.702 4.702 0 005.897-1.74l.846-1.305c1.885-2.852 6.066-2.852 7.951.024z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.6}
        d="M21.125 45.658l24.87-24.846"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2.6}
        d="M26.322 29.102a5.462 5.462 0 100-10.924 5.462 5.462 0 000 10.924zm14.162 18.779a5.462 5.462 0 100-10.924 5.462 5.462 0 000 10.924z"
      />
    </svg>
  )
}
export default SvgSymbolicpercentage
